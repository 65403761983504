import React,{useEffect, useState} from "react";
import { Form, Container, Row, Col } from 'react-bootstrap';
import { Stack, Paper, Divider, Typography, RadioGroup, Button, Radio } from "@mui/material";
import {motion} from 'framer-motion'

import PurchaseOrderCard from "../../../Components/Card/TransactionCards/PurchaseOrder/PurchaseOrderCard";
// import PurchaseOrderTable from "../../../Components/Card/TransactionCards/PurchaseOrder/PurchaseOrderTable";
import PurchaseOrderTable2 from "../../../Components/Card/TransactionCards/PurchaseOrder/PurchaseOrderTable2";
import PurchaseOrderTable from "../../../Components/Card/TransactionCards/PurchaseOrder/PurchaseOrderTable";

 const PurchaseOrder = () =>{
    const [openPopup, setOpenPopup] = useState(true);
    const [editData, setEditData] = useState({})
    const [isChanged, setIsChanged] = useState(false)

    const handlePopup = ()=>{
        setOpenPopup(!openPopup)
    }

    const handleEdit = (data)=>{
        // console.log(data)
        //setIsCreate(true);
        // setFormData(data[0]);
        // setIsEdit(true);
        // const temp = {
        //     ...data,
        //     BillToAddress: data.billtoaddress,
        //     ShipToAddress: data.shiptoaddress
        // }
        setEditData(data);
    }

    return(
        <>
        <Stack
            direction='column'
            justifyContent='center'
            alignItems='center'
        >
            <Paper
                sx={{
                    width: '100%',
                    margin: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: 3,
                    pt:0,
                    pb:5
                }}
                elevation={0}
            >
                {/* {openPopup && 
                    <motion.h4 style={{ textAlign: 'center', fontWeight: 'bolder', fontSize: '24px' }} 
                        initial={{opacity:0, y:-20}}
                        animate={{opacity:1, y:0}}
                        transition={{duration:0.1}}
                    >
                        Purchase Order 
                    </motion.h4>
                } */}
                <Stack direction='row' justifyContent='space-between' width='100%'>
                    {!openPopup && (<Button 
                    variant="contained" 
                    size="large" 
                    sx={{
                        justifySelf:'flex-start'
                    }}
                    onClick={handlePopup}
                    >
                        Add Purchase Order
                    </Button>)}
                </Stack>
                
                <Container>
                    {/* <PurchaseOrderCard title={'Credit'}/> */}
                    {openPopup && (<PurchaseOrderCard tle={'Credit'} handlePopup={handlePopup} editData={editData} isChanged={isChanged} setIsChanged={setIsChanged}/>)}
                </Container>

                <Stack direction='column'justifyContent='flex-start' width='100%' my={2}>
                    {/* <Typography fontSize={15} variant="body" fontWeight='bold'>HSN List</Typography> */}
                    <PurchaseOrderTable handleEditData={handleEdit} isChanged={isChanged}/>
                </Stack>
                
            </Paper>
        </Stack>
        </>
    )
 }
 export default PurchaseOrder;