
import React, { useState } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import { Box, Stack, Paper } from "@mui/material";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import AccessMasterCard from "./AccessMasterCard";

//FUNCTION
const AccessMaster = () => {
    //#region code
    const open = useSelector((state) => state.sideExpand.open)
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        setOpenDialog(!openDialog);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            //marginLeft: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            //paddingLeft: 3,
                            pb: 5
                        }}
                        elevation={0}
                    >
                        <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'ACCESS MASTER'} handlePopup={handleClickOpen} create={openDialog} />
                        
                        <AccessMasterCard handleClose={handleClose} open={openDialog} />
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}

export default AccessMaster;