import React, { useEffect, useState } from 'react'

import DashboardCard from '../../Components/Card/DashboardCard'
import MiniDrawer from '../../Components/sidebar/SideBar2'
import Header2 from '../../Components/header/Header2/Header2'
import Footer from '../../Components/Footer/Footer'
import http from '../../services/http'
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin'

import { useSelector } from 'react-redux'

import { Box, Stack, Typography } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import configServ from '../../services/config'
import Cookies from "universal-cookie/cjs/Cookies";
// icons
import PeopleIcon from '@mui/icons-material/People';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';
import { useNavigate } from 'react-router-dom'

function Dashboard2() {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const navigate = useNavigate();
    const cookie = new Cookies();
    const open = useSelector((state) => state.sideExpand.open)

    const [count, setCount] = useState({})

    const fetchDashboardCount = async () => {
        try {
            const userId = user_id;
            if (userId === null) {
                //cookie.remove('dcrm_token');
                navigate('/');
            }
            const result = await configServ.getDashboardCount({ "user_id": user_id });
            setCount(result);
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchDashboardCount()
    }, []);

    const cardData = [
        {
            title: 'CUSTOMERS',
            number: count.subscription,
            icon: <PeopleIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'INVOICES',
            number: count.invoice,
            icon: <DescriptionIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'PAID',
            number: count.paid,
            icon: <AttachMoneyIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'UNPAID',
            number: count.unpaid,
            icon: <MoneyOffIcon sx={{ color: '#fb6340' }} />
        },
    ]
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                // marginTop:{
                //     xs:7,
                //     sm:8
                // },
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}
                component={motion.div}
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    duration: 0.2
                }}
            >

                <Box sx={{
                    backgroundColor: '#6270e4',
                    width: "100%",
                    // height:'200vh'
                }}>
                    <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={{
                            xs: 0,
                            sm: 0,
                            md: 4
                        }}
                        gap={{
                            xs: 2,
                            sm: 2,
                            md: 0
                        }}
                        py={5}
                        sx={{
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'row'
                            },
                        }}
                    >
                        {cardData.map((item, index) => {
                            return (
                                <DashboardCard key={index} data={item} />
                            )
                        })}
                    </Stack>
                </Box>

            </Box>
            <Footer />
        </>
    )
}

export default Dashboard2