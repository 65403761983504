import React, { useState, useEffect } from 'react';
import { RadioGroup, Radio, FormLabel, Button, ButtonGroup, Grid, IconButton, TextField, Tooltip, FormControlLabel, Checkbox, Box, FormControl, InputLabel, Select, MenuItem, Autocomplete, Typography, Stack } from '@mui/material';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import configServ from '../../../../services/config';
import Calculation from '../../../../utility/Calculations';
import AddPurchaseOrderItems from '../PurchaseOrder/AddPurchaseOrderItems';
import CardHeadMenu from '../../CardHeadMenu/CardHeadMenu';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import JoyModal from '../../../Dialog/JoyModal';
import CheckAndReturn from '../../../../utility/CheckAndReturn';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

function PurchaseOrderCard({ handlePopup, editData, isChanged, setIsChanged }) {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const [suppliers, setSuppliers] = useState([]);
    const [items, setItems] = useState([]);
    const [approval, setApproval] = useState(false);
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [joyOpen, setJoyOpen] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const itemListInitial = [
        {
            id: 1,
            itemFullname: '',
            itemid: '',
            purchaseoder_id: '',
            hsn_code: '',
            Taxrate: '',
            quantity: '',
            rate: '',
            amount: ''
        }
    ];
    const [itemList, setItemList] = useState(itemListInitial);
    const itemListErrorInitial = [{
        itemFullname: '',
        quantity: '',
        rate: '',
    }];
    const [itemListError, setItemListError] = useState(itemListErrorInitial);
    const purchaseOrderInitial = {
        user_id: user_id,
        company_id: company_id,
        _token: '',
        createinv: '',
        partyname: '',
        supplier_id: null,
        id: '',
        BillToAddress: '',
        ShipToAddress: '',
        purchase_order_no: '',
        delivery_date: '',
        salesperson: '',
        pay_terms: '',
        remarks: '',
        order_type: '',
        approval_is: '',
        total_amount: '',
        custId: '',
        item: [
            {
                itemFullname: '',
                itemid: '',
                purchaseoder_id: '',
                hsn_code: '',
                Taxrate: '',
                quantity: '',
                rate: '',
                amount: ''
            }
        ],
    };
    const [purchaseOrder, setPurchaseOrder] = useState(purchaseOrderInitial);
    const purchaseOrderErrorInitial = {
        partyname: '',
        BillToAddress: '',
        ShipToAddress: '',
        purchase_order_no: '',
        delivery_date: '',
        salesperson: '',
    }
    const [purchaseOrderError, setPurchaseOrderError] = useState(purchaseOrderErrorInitial);
    const shipToAddressesInitial = [
        {
            id: '',
            address: ''
        }
    ];
    const [shipToAddresses, setShipToAddresses] = useState(shipToAddressesInitial);

    useEffect(() => {
        fetchSupplierList();
        fetchItemList();
    }, []);

    useEffect(() => {
        calculateTotalAmount();
    }, [itemList]);

    const fetchSupplierList = async () => {
        try {
            const result = await configServ.getSuppliers(
                {
                    "user_id": user_id,
                    "company_id": company_id
                }
            );
            if (result.success === true) {
                setSuppliers(result.data);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const fetchItemList = async () => {
        try {
            const result = await configServ.getItemList({ "company_id": company_id });
            setItems(result)
        } catch (error) {
            console.log(error)
        }
    }

    //Purchase Order code
    // Handle supplier change
    const handleSupplierAutocompleteChange = (event) => {
        try {
            const value = event.target.innerText;
            const selectedSupplier = suppliers.find((item) => item.fname.toLowerCase() === value.toLowerCase());
            const id = selectedSupplier.id;

            if (value !== null && value !== undefined) {
                if (value.length > 0) {
                    handleSupplierInputChange({ target: { name: 'partyname', value } });
                }
            }
            if (id !== null && id !== undefined) {
                if (id !== null) {
                    handleSupplierInputChange({ target: { name: 'supplier_id', id } });
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    };

    // Handle customer input
    const handleSupplierInputChange = (event) => {
        try {
            const SupplierName = event.target.value;
            const selectedSupplier = suppliers.find((item) => item.fname.toLowerCase() === SupplierName.toLowerCase());

            if (selectedSupplier !== null && selectedSupplier !== undefined) {
                setPurchaseOrder((prev) => ({
                    ...prev, // Copy existing fields
                    custId: CheckAndReturn.checkUndefinedReturnNull(selectedSupplier.id), // Update the specific field with the new value
                    partyname: selectedSupplier.fname,
                    supplier_id: selectedSupplier.id,
                    BillToAddress: selectedSupplier.Address,
                    ShipToAddress: selectedSupplier.Address,
                }));
                //fetchShipToAddress(selectedSupplier.id); 
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // Handle Purchase Order Customer
    const handlePurchaseOrderCustomer = (event) => {
        try {
            const { name, value } = event.target;
            setPurchaseOrder((prev) => ({
                ...prev,  //copy existing field values
                [name]: value //update field value
            }))
        }
        catch (error) {
            console.log(error);
        }
    }

    //Fetch Ship To Address
    // const fetchShipToAddress = async (cust_id) => {
    //   try{
    //     const result = await configServ.getShipToAddress({"cust_id": cust_id})
    //     setShipToAddresses(result)
    //   }
    //   catch(error){
    //     console.log(error);
    //   }
    // }

    const handleApprovalChange = (event) => {
        const isApproved = event.target.checked;
        if (isApproved === true) {
            setPurchaseOrder((prev) => ({
                ...prev,
                approval_is: 'on'
            }));
        }
        else {
            setPurchaseOrder((prev) => ({
                ...prev,
                approval_is: ''
            }));
        }
        setApproval(isApproved);
    };

    const calculateTotalAmount = () => {
        let totalAmount = 0;
        itemList.forEach(item => {
            // Parse the amount string to a float value
            const amount = parseFloat(item.amount);
            // Check if the amount is a valid number
            if (!isNaN(amount)) {
                // Add the amount to the total
                totalAmount += amount;
            }
        });
        setTotalAmount(totalAmount.toFixed(2));
    };

    // Itemlist code
    // Handle the Autocomplete's onChange event
    const handleAutocompleteChange = (event, id) => {
        const value = event.target.innerText;
        if (value !== null && value !== undefined) {
            if (value.length > 0) {
                handleInputChange({ target: { name: 'itemFullname', value } }, id);
            }
        }
    };

    // Handle Input Changes
    const handleInputChange = (event, id) => {
        try {
            const { name, value } = event.target;
            let updatedItem;
            if (value !== null && value !== undefined) {
                if (value.length > 0) {
                    if (name === 'itemFullname') {
                        // Find the selected item from the options
                        const selectedItem = items.find((item) => item.item_name.toLowerCase() === value.toLowerCase());
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                itemid: selectedItem.id.toString(),
                                itemFullname: selectedItem.item_name,
                                itemcode: selectedItem.item_code,
                                packagingunit: selectedItem.packagingunit,
                                hsn_code: selectedItem.hsncode,
                                quantity: '',
                                rate: selectedItem.rate_unit,
                                amount: '0.00'
                            };
                        } else {
                            // If the selected item is not found, reset the properties
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                itemid: '',
                                itemFullname: value,
                                itemcode: '',
                                packagingunit: '',
                                hsn_code: '',
                                discount: '',
                                rate: '',
                                Taxrate: '',
                            };
                        }
                    }
                    else {
                        // For other fields, just update the value
                        updatedItem = {
                            ...itemList.find((item) => item.id === id),
                            [name]: value,
                        };
                    }
                    // Update the itemList state with the updated item
                    const updatedItemList = itemList.map((item) =>
                        item.id === id ? updatedItem : item
                    );
                    setItemList(updatedItemList);
                }
            }
        }
        catch (error) {
            console.log(error)
        }
    };

    // Function to add a new item to the list
    const handleAddItem = () => {
        setItemList([
            ...itemList,
            { id: itemList.length + 1, name: '', quantity: '' },
        ]);
    };

    // Function to delete an item from the list based on its ID
    const handleDeleteItem = (id) => {
        const updatedItemList = itemList.filter((item) => item.id !== id);
        setItemList(updatedItemList);
    };

    // Handle Quantity Change
    const handleQuantityChange = (event, id) => {
        try {
            const { name, value } = event.target;
            let updatedItem;
            if (value !== null && value !== undefined) {
                // Find the selected item from the options
                const selectedItem = itemList.find((item) => item.id === id);
                if (value.length > 0) {
                    if (name === 'quantity') {
                        // If a valid item is selected, update the item properties accordingly
                        if (selectedItem) {
                            const totalAmount = Calculation.GetOrderItemAmount(value, selectedItem.rate);
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                quantity: value,
                                amount: totalAmount.toString()
                            };
                        } else {
                            // If the selected item is not found, reset the properties
                            updatedItem = {
                                ...itemList.find((item) => item.id === id),
                                quantity: '',
                                amount: '0.00'
                            };
                        }
                    }
                    else {
                        // For other fields, just update the value
                        updatedItem = {
                            ...itemList.find((item) => item.id === id),
                            [name]: value,
                        };
                    }
                    // Update the itemList state with the updated item
                    const updatedItemList = itemList.map((item) =>
                        item.id === id ? updatedItem : item
                    );
                    setItemList(updatedItemList);
                }
                else {
                    updatedItem = {
                        ...itemList.find((item) => item.id === id),
                        quantity: '',
                        amount: '0.00'
                    };
                    const updatedItemList = itemList.map((item) =>
                        item.id === id ? updatedItem : item
                    );
                    setItemList(updatedItemList);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    // Reset
    const handleReset = () => {
        setPurchaseOrder(purchaseOrderInitial);
        setPurchaseOrderError(purchaseOrderErrorInitial);
        setItemList(itemListInitial);
        setItemListError(itemListErrorInitial);
        setIsEdit(false)
    }

    // Save
    const handleSave = async () => {
        try {
            const purchaseOrderErrors = validatePurchaseOrder();
            const itemListErrors = validateItemList();
            if (Object.keys(purchaseOrderErrors).length > 0 || Object.keys(itemListErrors).length > 0) {
                // Handle the errors as needed (e.g., show error messages, prevent saving, etc.)
                return; // Return early if there are errors
            }

            purchaseOrder.total_amount = totalAmount;
            purchaseOrder.item = await handlePurchaseOrderItemList();
            // console.log(JSON.stringify(purchaseOrder));
            if (!isEdit) {
                console.log('purchaseOrder', JSON.stringify(purchaseOrder));
                const res = await configServ.createPurchaseOrder(purchaseOrder);
                if (res.success === true) {
                    setDialogTitle('Message')
                    setDialogMessage(res.message);
                    setJoyOpen(true);
                    handleReset()
                    setIsChanged(!isChanged)
                }
                else if (res.success === false) {
                    setDialogTitle('Message');
                    setDialogMessage(res.message);
                    setJoyOpen(true);
                }
                else {
                    setDialogTitle('Message');
                    setDialogMessage('Purchase order creation failed. Please try again.');
                    setJoyOpen(true);
                }
            } else {
                const res = await configServ.editPurchaseOrder(purchaseOrder);
                if (res.success === true) {
                    setDialogTitle('Message')
                    setDialogMessage(res.message);
                    setJoyOpen(true);
                    handleReset()
                    setIsChanged(!isChanged)
                }
                else if (res.success === false) {
                    setDialogTitle('Message');
                    setDialogMessage(res.message);
                    setJoyOpen(true);
                }
                else {
                    setDialogTitle('Message');
                    setDialogMessage('Purchase order update failed. Please try again.');
                    setJoyOpen(true);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handlePurchaseOrderItemList = async () => {
        try {
            const purchaseOrderItems = itemList.map((item) => ({
                itemFullname: item.itemFullname,
                itemid: item.itemid.toString(),
                itemcode: item.itemcode,
                packagingunit: item.packagingunit,
                hsn_code: item.hsn_code,
                Taxrate: item.Taxrate || '',
                quantity: item.quantity,
                rate: item.rate,
                amount: item.amount
            }));
            return purchaseOrderItems;
        }
        catch (error) {
            console.log(error);
        }
    }

    // Validate the Purchase Order fields
    const validatePurchaseOrder = () => {
        const errors = {};

        // Check if the required fields are empty
        if (!purchaseOrder.partyname) {
            errors.partyname = 'Party Name is required.';
        }
        if (!purchaseOrder.BillToAddress) {
            errors.BillToAddress = 'Bill To Address is required.';
        }
        if (!purchaseOrder.ShipToAddress) {
            errors.ShipToAddress = 'ShipTo Address is required.';
        }
        if (!purchaseOrder.delivery_date) {
            errors.delivery_date = 'Delivery Date is required.';
        }
        if (!purchaseOrder.purchase_order_no) {
            errors.purchase_order_no = 'Purchase Order No is required.';
        }
        if (!purchaseOrder.salesperson) {
            errors.salesperson = 'Salesperson is required.';
        }

        setPurchaseOrderError(errors);

        return errors;
    };

    // Validate ItemList
    const validateItemList = () => {
        const errors = itemList.map(item => ({
            itemFullname: !item.itemFullname ? 'Item Name is required.' : '',
            quantity: !item.quantity ? 'Quantity is required.' : '',
            rate: !item.rate ? 'Rate is required.' : '',
            // Add additional validations for other item fields as needed...
        }));

        setItemListError(errors);
        const hasErrors = errors.some(error => error.itemFullname || error.quantity || error.rate);
        return hasErrors ? errors : {};
    };

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setPurchaseOrder([editData]);
            //setItemList(editData.item);
            setIsEdit(true);
            setIsCreate(true);
            // console.log(editData)
        }
        // fetchShipToAddress(editData.customer?.id)
    }, [editData])

    console.log('purchaseOrder', purchaseOrder);
    const handleCreatePopup = () => {
        try {
            //setValidationErrors({})
            setIsCreate(!isCreate);
        }
        catch (error) {
            console.log(error);
        }
    }
    //#endregion

    return (
        <div >
            <JoyModal title={dialogTitle} message={dialogMessage} joyOpen={joyOpen} handleJoyClose={() => setJoyOpen(false)} />

            <CardHeadMenu
                prefix={"Na"}
                suffix={"Na"}
                title={"Purchase Order"}
                handlePopup={handleCreatePopup}
                isEdit={isEdit}
                create={isCreate}
            />

            {isCreate && <>
                <motion.div
                    style={{
                        width: '100%',
                        backgroundColor: '#e3f3fc',
                        //backgroundImage: 'linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%)',
                        padding: 20,
                        borderRadius: '10px',
                        border: 'none',
                        margin: '10px',
                    }}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    {/* <Button onClick={abc}>abc</Button> */}
                    <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10 }} spacing={1} >
                        <Grid item xs={12} sm={3} md={3}>
                            <Link to='/suppliers'>
                                <Tooltip title={'Add Supplier'}>
                                    <PersonAddIcon sx={{ cursor: 'pointer', color: 'white', backgroundColor: 'grey', margin: '5px' }} justifyContent="flex-end" />
                                </Tooltip>
                            </Link>
                            <Autocomplete
                                id="grouped-demo"
                                size="small"
                                sx={{ backgroundColor: 'white' }}
                                value={purchaseOrder.partyname}
                                options={suppliers.map((option) => option.fname)}
                                onChange={handleSupplierAutocompleteChange}
                                renderInput={(params) => <TextField {...params}
                                    value={purchaseOrder.partyname}
                                    label="Supplier Name"
                                    fullWidth
                                    onChange={handleSupplierInputChange}
                                    error={!!purchaseOrderError.partyname}
                                />}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <span>{''}</span>
                            <TextField
                                label="Bill To Address"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='BillToAddress'
                                value={purchaseOrder.BillToAddress}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                error={!!purchaseOrderError.BillToAddress}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl fullWidth size='small' >
                                <InputLabel id="demo-simple-select-label">Ship To Address</InputLabel>
                                <Select
                                    sx={{ backgroundColor: 'white' }}
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    label="Ship To Address"
                                    name='ShipToAddress'
                                    value={purchaseOrder.ShipToAddress}
                                    onChange={handlePurchaseOrderCustomer}
                                    error={!!purchaseOrderError.ShipToAddress}
                                >
                                    <MenuItem value={purchaseOrder.ShipToAddress}>{purchaseOrder.ShipToAddress}</MenuItem>
                                    {/* {shipToAddresses.map((item)=>(
                    <MenuItem value={item.id}>{item.address}</MenuItem>
                ))} */}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                label="Purchase Order No"
                                placeholder='PON0001 (Sample)'
                                sx={{ backgroundColor: 'white' }}
                                name='purchase_order_no'
                                value={purchaseOrder.purchase_order_no}
                                onChange={handlePurchaseOrderCustomer}
                                error={!!purchaseOrderError.purchase_order_no}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10 }} spacing={1}>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Delivery Date"
                                variant="outlined"
                                type="date"
                                fullWidth
                                size='small'
                                name='delivery_date'
                                value={purchaseOrder.delivery_date}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                InputLabelProps={{ shrink: true, }}
                                error={!!purchaseOrderError.delivery_date}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Salesperson Name"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='salesperson'
                                value={purchaseOrder.salesperson}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                                error={!!purchaseOrderError.salesperson}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Pay Terms"
                                variant="outlined"
                                fullWidth
                                size='small'
                                name='pay_terms'
                                value={purchaseOrder.pay_terms}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <TextField
                                label="Remarks"
                                variant="outlined"
                                placeholder="Leave a comment here"
                                multiline
                                rows={2}
                                fullWidth
                                size='small'
                                name='remarks'
                                value={purchaseOrder.remarks}
                                onChange={handlePurchaseOrderCustomer}
                                sx={{ backgroundColor: 'white' }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="flex-start" style={{ padding: 10 }} spacing={1}>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend" sx={{ color: 'black' }}>Order Type</FormLabel>
                                <RadioGroup name="use-radio-group" row value={purchaseOrder.order_type} >
                                    <FormControlLabel value="open" label="open" control={<Radio size="small"
                                        onClick={() => setPurchaseOrder((prev) => ({
                                            ...prev,
                                            'order_type': 'open'
                                        }))}
                                    />} />
                                    <FormControlLabel value="close" label="close" control={<Radio size="small"
                                        onClick={() => setPurchaseOrder((prev) => ({
                                            ...prev,
                                            'order_type': 'close'
                                        }))}
                                    />} />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <FormControlLabel
                                control={<Checkbox />}
                                label="Approval Required"
                                checked={approval}
                                onChange={handleApprovalChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3}>
                            <Typography variant="body1" >Net Amount(₹ {totalAmount})</Typography>
                        </Grid>
                    </Grid>
                    <Stack
                        direction='row'
                        justifyContent='flex-end'
                        spacing={3}
                        px={3}
                    >
                        <Button
                            variant="contained"
                            color={isEdit ? 'error' : 'secondary'}
                            onClick={handleReset}
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '7rem'
                                },
                                marginRight: '10px'
                            }}
                        >
                            {isEdit ? <CloseIcon sx={{ marginRight: '5px' }} /> : <RotateLeftIcon sx={{ marginRight: '5px' }} />}
                            {isEdit ? 'Close' : 'Reset'}
                        </Button>
                        <Button
                            variant="contained"
                            color="success"
                            onClick={handleSave}
                            sx={{
                                width: {
                                    xs: '100%',
                                    sm: '7rem'
                                }
                            }}
                        >
                            {isEdit ? <UpdateIcon sx={{ marginRight: '10px' }} /> : <SaveIcon sx={{ marginRight: '10px' }} />}
                            {isEdit ? 'Update' : 'Save'}
                        </Button>
                    </Stack>
                </motion.div>

                <AddPurchaseOrderItems
                    itemList={itemList}
                    handleAutocompleteChange={handleAutocompleteChange}
                    handleInputChange={handleInputChange}
                    handleAddItem={handleAddItem}
                    handleDeleteItem={handleDeleteItem}
                    items={items}
                    handleQuantityChange={handleQuantityChange}
                    errors={itemListError}
                />
            </>}
        </div>
    );
}

export default PurchaseOrderCard;
