import React, { useState, useEffect } from 'react'
import Header2 from '../../Components/header/Header2/Header2'
import Footer from '../../Components/Footer/Footer'
import MiniDrawer from '../../Components/sidebar/SideBar2'
import {
    Box,
    Paper,
    Typography,
    Card,
    Stack,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
    IconButton,
    FormControlLabel,
    Checkbox
} from '@mui/material'
import { useSelector } from 'react-redux'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import StateList from '../../Components/List/StateList'
import ProfilePicture from '../../Components/Assets/images/profilePicture.jpg'
import configServ from '../../services/config'
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin'
// import CatalogTable from '../Catalog/CatalogTable'
import SuperCategoryTable from './SuperCategoryTable'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import CardHeadMenu from '../../Components/Card/CardHeadMenu/CardHeadMenu'

function SuperCategory() {
    //#region code
    const { user_id, company_id, noRoomService } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [formData, setFormData] = useState({
        status: true,
    });
    const [itemList, setItemList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
    const [isCreate, setIsCreate] = useState(false);

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        const dataToSend = {
            ...formData,
            "service_name": noRoomService,
            "created_by": user_id
        }
        if (!formData.name) {
            handleOpenDialog('Enter a name')
            return
        }
        try {
            if (!isEdit) {
                const result = await configServ.createSuperCategory(dataToSend);
                if (result.success) {
                    handleOpenDialog(result.message)
                    clear()
                    setIsChanged(!isChanged)
                }
            } else {
                const result = await configServ.editSuperCategory(dataToSend);
                if (result.success) {
                    handleOpenDialog(result.message)
                    clear()
                    setIsChanged(!isChanged)
                }
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchItemList = async () => {
        try {
            const result = await configServ.getItemList({ "company_id": company_id });
            setItemList(result)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchItemList()
    }, []);

    const clear = () => {
        setFormData({
            status: true,
            service_name: noRoomService
        });
        setIsEdit(false)
    }

    const handleEditData = (data) => {
        setIsEdit(true);
        setIsCreate(true);
        setFormData(data[0]);
    }

    const handleDeleteData = async (params) => {
        try {
            const data = {
                id: params.id
            };
            const res = await configServ.deleteSuperCategory(data);
            if (res.success === true) {
                handleOpenDialog(res.message);
                setIsChanged(!isChanged)
            }
            else if (res.success === false) {
                handleOpenDialog(res.message);
            }
            else {
                handleOpenDialog('Some error occurred.');
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
        //setIsEdit(false);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 30 }),
                transition: '200ms',
                overflowX: 'hidden',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{
                        paddingLeft: 4,
                        paddingRight: 4
                    }}
                    spacing={5}
                >
                    <CardHeadMenu
                        prefix={"na"}
                        suffix={"na"}
                        title={"Super Category"}
                        handlePopup={handlePopup}
                        isEdit={isEdit}
                        create={isCreate}
                    />
                    {isCreate &&
                        <Paper
                            elevation={0}
                            sx={{
                                width: {
                                    md: '70%',
                                    sm: '90%',
                                    xs: '100%'
                                },
                                padding: 4,
                                margin: 0,
                                border: '1px solid #55555522'
                            }}
                        >
                            {/* <Typography color='#333' variant='body' fontSize='large'>Super Category</Typography>
                            <br /> */}
                            {/* <Divider
                                sx={{
                                    background: '#55555533'
                                }}
                            />
                            <br /> */}
                            <Stack
                                direction='row'
                                spacing={2}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Name'
                                    name='name'
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.name || ''}
                                    onChange={changeHandler}
                                />
                                <FormControlLabel
                                    control={<Checkbox />}
                                    name='status'
                                    checked={Boolean(formData.status)}
                                    onChange={handleChangeCheckbox}
                                    label='Status'
                                />
                            </Stack>
                            {/* <Divider sx={{ my: 3, background: '#55555533' }} /> */}
                            <Stack
                                direction='row'
                                justifyContent='flex-end'
                                alignItems='center'
                                padding={1}
                                mt={2}
                            >
                                <Stack
                                    direction={{
                                        xs: 'column',
                                        sm: 'row'
                                    }}
                                    width='100%'
                                    justifyContent={{
                                        xs: 'center',
                                        sm: 'flex-end'
                                    }}
                                >
                                    <Button
                                        variant="contained"
                                        color={'secondary'}
                                        onClick={clear}
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '7rem'
                                            },
                                            marginRight: '10px'
                                        }}
                                    >
                                        {<RotateLeftIcon sx={{ marginRight: '5px' }} />}
                                        {'Reset'}
                                    </Button>
                                    <Button
                                        variant='contained'
                                        sx={{
                                            width: {
                                                xs: '100%',
                                                sm: '7rem'
                                            },
                                        }}
                                        onClick={handleSubmit}
                                    >
                                        {isEdit ? 'Update' : 'Submit'}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Paper>
                    }
                    <SuperCategoryTable handleEditData={handleEditData} isChanged={isChanged} handleDeleteData={handleDeleteData} />
                </Stack>
            </Box>
            <Footer />

        </>
    )
}

export default SuperCategory