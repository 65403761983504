import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Paper, Stack, Button, Tooltip, IconButton } from '@mui/material'
import configServ from '../../../services/config'
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';

const DispatchDetailsCard = ({ editData, clear, setIsChanged, isChanged, invData }) => {
//#region code
    const {company_id} = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({})
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
  
    const handleOpenDialog = (content) => {
      setDialogContent(content);
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
      setDialogContent('');
    };
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        // console.log(formData)
        const dataToSend = {
            ...formData,
            company_id: company_id
        }
        try {
            if (!isEdit) {
                const res = await configServ.createDispatch(dataToSend);
                console.log(res)
                setIsChanged(!isChanged)
                handleOpenDialog(res.message);
                reset()
            } else {
                const res = await configServ.editDispatch(dataToSend);
                console.log(res)
                setIsChanged(!isChanged)
                handleOpenDialog(res.message);
                reset()
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData(editData)
            setIsEdit(true)
            // console.log(editData)
        }
    }, [editData])

    useEffect(() => {
        if (invData) {
            assignFormData(invData)
            console.log(invData)
        }
    }, [invData])

    const assignFormData = (data) => {
        let qty = 0;
        if (data.items) {
            data.items.map((item) => (
                qty = qty + parseInt(item.qty)
            ))
        }
        const dataToAssign = {
            invoice_no: data.invoice_number,
            amount: data.total_amount,
            qnty: qty,
            bill_to: data.buyeraddress,
            ConsigneeAddress1: data.customer?.address,
            ConsigneePlace: data.customer?.address,
            ConsigneePinCode: data.customer?.Pin,
        }
        setFormData(dataToAssign)
    }

    const reset = () => {
        setFormData({})
        setIsEdit(false)
        clear()
    }

    const abc = () => {
        console.log(formData)
        console.log(editData)
    }
//#endregion

    return (
        <>
                   <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
            <h4 style={{ textAlign: 'center' }} >Dispatch Details </h4>
            <br />
            {/* <Button onClick={abc}>abc</Button> */}
            <div style={{ width: "100%", backgroundColor: "#DBDFEA99", padding: 20, borderRadius: '10px', border: 'none' }}>
                <Row>
                    <Col sm={2}>
                        <Form.Label>Invoice No.</Form.Label>
                        <Form.Control
                            placeholder='Invoice No.'
                            name='invoice_no'
                            onChange={handleChange}
                            value={formData.invoice_no || ""}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Sub Type</Form.Label>
                        <Form.Control
                            placeholder='Ex-supply'
                            name='SubType'
                            onChange={handleChange}
                            value={formData.SubType || ""}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Transport Mode</Form.Label>
                        <Form.Control
                            placeholder='Ex-Road'
                            name='TransportMode'
                            onChange={handleChange}
                            value={formData.TransportMode || ""}
                        />
                    </Col>

                    <Col sm={3}>
                        <Form.Label>Transporter Name</Form.Label>
                        <Form.Control
                            placeholder='Traspoter Name'
                            name='transporter_name'
                            onChange={handleChange}
                            value={formData.transporter_name || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Mobile no'
                            name='TransporterMobileNo'
                            onChange={handleChange}
                            value={formData.TransporterMobileNo || ""}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm={2}>
                        <Form.Label>Vehicle Number</Form.Label>
                        <Form.Control placeholder='Vehical Number'
                            name='vehicle_num'
                            onChange={handleChange}
                            value={formData.vehicle_num || ""}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Quantity</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Quantity'
                            name='qnty'
                            onChange={handleChange}
                            value={formData.qnty || ""}
                        />
                    </Col>
                    <Col sm={2}>
                        <Form.Label>Amount</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Amount'
                            name='amount'
                            onChange={handleChange}
                            value={formData.amount || ""}
                        />
                    </Col>

                    <Col sm={3}>
                        <Form.Label>Transportation Cost</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Ex-100 Rs'
                            name='transportationCost'
                            onChange={handleChange}
                            value={formData.transportationCost || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Bill From</Form.Label>
                        <Form.Control
                            placeholder='Bill From'
                            name='bill_from'
                            onChange={handleChange}
                            value={formData.bill_from || ""}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <Col sm={3}>
                        <Form.Label>Bill To</Form.Label>
                        <Form.Control
                            placeholder='Bill To'
                            name='bill_to'
                            onChange={handleChange}
                            value={formData.bill_to || ""}
                        />
                    </Col>
                </Row>

                <Row>
                    <h5>Transporter Details</h5>
                    <Col sm={3}>
                        <Form.Label>Distance(KM)</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Ex-100km'
                            name='Distance'
                            onChange={handleChange}
                            value={formData.Distance || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Transporter ID</Form.Label>
                        <Form.Control
                            placeholder='Transporter ID'
                            name='TransporterId'
                            onChange={handleChange}
                            value={formData.TransporterId || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Vehicle Type</Form.Label>
                        <Form.Control
                            placeholder='Ex-Reguler'
                            name='VehicleType'
                            onChange={handleChange}
                            value={formData.VehicleType || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Date</Form.Label>
                        <Form.Control type="Date"
                            name='TransporterDate'
                            onChange={handleChange}
                            value={formData.TransporterDate || ""}
                        />
                    </Col>
                </Row>
                <br />
                <Row>
                    <h5>Consignor Details(Form)/Dispatch From</h5>
                    <Col sm={6}>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            placeholder='Address'
                            name='ConsignorAddress1'
                            onChange={handleChange}
                            value={formData.ConsignorAddress1 || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Place</Form.Label>
                        <Form.Control
                            placeholder='Place'
                            name='ConsignorPlace'
                            onChange={handleChange}
                            value={formData.ConsignorPlace || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Pin Code</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Pin Code'
                            name='ConsignorPinCode'
                            onChange={handleChange}
                            value={formData.ConsignorPinCode || ""}
                        />
                    </Col>

                </Row>
                <br />
                <h5>Consignee Details(To)/Ship To</h5>
                <br />
                <Row>
                    <Col sm={6}>
                        <Form.Label>Address</Form.Label>
                        <Form.Control
                            placeholder='Address'
                            name='ConsigneeAddress1'
                            onChange={handleChange}
                            value={formData.ConsigneeAddress1 || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Place</Form.Label>
                        <Form.Control
                            placeholder='Place'
                            name='ConsigneePlace'
                            onChange={handleChange}
                            value={formData.ConsigneePlace || ""}
                        />
                    </Col>
                    <Col sm={3}>
                        <Form.Label>Pin Code</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='Pin Code'
                            inline
                            name='ConsigneePinCode'
                            onChange={handleChange}
                            value={formData.ConsigneePinCode || ""}
                        />
                    </Col>


                </Row>
                <br />
                <Row>
                    <Col sm={3}>
                        <Button
                            variant="contained"
                            sx={{
                                minWidth: 100
                            }}
                            onClick={handleSubmit}
                        >
                            {isEdit ? 'Update' : 'Save'}
                        </Button>
                        <Tooltip title='Reset'>
                            <IconButton onClick={reset} >
                                <RotateLeftIcon />
                            </IconButton>
                        </Tooltip>
                    </Col>
                </Row>
            </div>

        </>
    )
}

export default DispatchDetailsCard