import React, { useState, useEffect } from 'react'
import Header2 from '../../Components/header/Header2/Header2'
import Footer from '../../Components/Footer/Footer'
import MiniDrawer from '../../Components/sidebar/SideBar2'
import {
    Box,
    Paper,
    Typography,
    Card,
    Stack,
    Divider,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Button,
    Tooltip,
    IconButton,
    FormControlLabel,
    Checkbox,
    Autocomplete
} from '@mui/material'
import { useSelector } from 'react-redux'

import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddIcon from '@mui/icons-material/Add';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';

import StateList from '../../Components/List/StateList'
import ProfilePicture from '../../Components/Assets/images/profilePicture.jpg'
import configServ from '../../services/config'
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin'
import CatalogTable from './CatalogTable'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";

function Catalog() {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [formData, setFormData] = useState({})
    const [catalogItem, setCatalogItem] = useState([{
        id: 1,
        item_id: '',
    }])
    const [itemList, setItemList] = useState([])
    const [applicationList, setApplicationList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const changeHandler = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }


    const handleChangeCatalogItem = (id, event) => {
        const { name, value } = event.target;
        // console.log(name,' ',value )
        setCatalogItem((state) =>
            state.map((item) =>
                item.id === id ? { ...item, [name]: value } : item
            )
        );
        if (name === 'item_id') {
            const selectedItem = itemList.filter((item) => (value === item.id))
            setCatalogItem((state) =>
                state.map((item) =>
                    item.id === id ? { ...item, item_name: selectedItem[0].item_name } : item
                )
            );
        }
    };

    const handleAutoOnchangeForItem = (e, value) => {
        // console.log(value)
        if (value) {
            const { name, id } = value
            const selectedItem = itemList.filter((item) => (id === item.id))
            setCatalogItem((state) =>
                state.map((item) =>
                    item.id === id ? { ...item, item_name: selectedItem[0].item_name } : item
                )
            );
        }
    }

    const handleAutoOnchange = (e, value) => {
        // console.log(value)
        if (value) {
            const { name, label } = value
            setFormData((state) => ({
                ...state,
                [name]: label
            }))
        }
    }


    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }


    const handleSubmit = async (e) => {
        if (!catalogItem[0].item_name) {
            handleOpenDialog('please select item')
            return
        }
        const dataToSend = {
            ...formData,
            "items": catalogItem,
            "company_id": company_id,
            "user_id": user_id
        }
        console.log(dataToSend)
        try {
            if (!isEdit) {
                const result = await configServ.createCatalog(dataToSend)
                // console.log(result)
                if (result.success) {
                    handleOpenDialog(result.message)
                    clear()
                    setIsChanged(!isChanged)
                }
            } else {
                const result = await configServ.editCatalog(dataToSend)
                // console.log(result)
                if (result.success) {
                    handleOpenDialog(result.message)
                    clear()
                    setIsChanged(!isChanged)
                }
            }
        } catch (err) {
            console.log(err)
        }
    }

    const fetchItemList = async () => {
        try {
            const result = await configServ.getItemList({ "company_id": company_id });
            setItemList(result)
            // setItemToList(result)

        } catch (err) {
            console.log(err)
        }
    }
    const setItemToList = (data) => {
        if (data.length === 0) {
            return;
        }
        data.map((item) => {
            setItemList((state) => ([
                ...state,
                {
                    // ...item,
                    label: item.item_name,
                    name: 'item_id',
                }
            ]))
        })
    }


    const fetchApplication = async () => {
        try {
            const result = await configServ.getApplication()
            // console.log(result)
            // setApplicationList(result.data)
            setApplicationToList(result.data)

        } catch (err) {
            console.log(err)
        }
    }
    const setApplicationToList = (data) => {
        if (data.length === 0) {
            return;
        }
        data.map((item) => {
            setApplicationList((state) => ([
                ...state,
                {
                    // ...item,
                    label: item.name,
                    name: 'application_name',
                }
            ]))
        })
    }

    useEffect(() => {
        fetchItemList()
        fetchApplication()
    }, [])

    const addCatalogItem = () => {
        setCatalogItem((state) => ([
            ...state,
            {
                id: catalogItem[catalogItem.length - 1].id + 1,
                item_id: '',
            }
        ]))
    }

    const delAssociatedItem = (id) => {
        const updatedItemList = catalogItem.filter((item) => item.id !== id);
        setCatalogItem(updatedItemList);
        // console.log(updatedItemList)
    };

    const clear = () => {
        setFormData({})
        setCatalogItem([{
            id: 1,
            item_id: '',
        }])
        setIsEdit(false)
    }

    const handleEditData = (data) => {
        // console.log(data[0])
        setIsEdit(true)
        setFormData(data[0])
        setCatalogItem(data[0].catalog_items)
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 30 }),
                transition: '200ms',
                overflowX: 'hidden',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='space-around'
                    alignItems='center'
                    sx={{
                        padding: 2,
                    }}
                    spacing={5}
                >
                    <Paper
                        elevation={0}
                        sx={{
                            width: {
                                md: '70%',
                                sm: '90%',
                                xs: '100%'
                            },
                            padding: 4,
                            border: '1px solid #55555522'
                        }}
                    >
                        {/* <Button onClick={abc}>abc</Button> */}
                        <Typography color='#333' variant='body' fontSize='large'>Catalog</Typography>
                        <br />
                        <Divider
                            sx={{
                                background: '#55555533'
                            }}
                        />
                        <br />
                        <Stack
                            direction={'column'}
                            spacing={1}
                        >
                            <Stack
                                direction={{
                                    xs: 'column',
                                    md: 'row'
                                }}
                                spacing={2}
                            >
                                <TextField
                                    fullWidth
                                    size='small'
                                    label='Catalog Name'
                                    name='catalog_name'
                                    sx={{
                                        width: {

                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    value={formData.catalog_name || ''}
                                    onChange={changeHandler}
                                />
                                <Autocomplete
                                    fullWidth
                                    options={applicationList}
                                    size="small"
                                    value={formData.application_name || ''}
                                    onChange={handleAutoOnchange}
                                    // sx={{ backgroundColor: 'white' }}
                                    sx={{
                                        width: {
                                            md: '50%',
                                            sm: '100%'
                                        }
                                    }}
                                    renderInput={(params) => <TextField {...params} label="Application Name (optional)" />}
                                />

                            </Stack>
                            <Stack
                                direction={'row'}
                                justifyContent={'flex-end'}
                            >
                                <FormControlLabel
                                    control={<Checkbox />}
                                    name='currentlyActive'
                                    checked={Boolean(formData.currentlyActive)}
                                    onChange={handleChangeCheckbox}
                                    label='Currently Active'
                                />
                            </Stack>
                        </Stack>
                        <Divider sx={{ my: 3, background: '#55555533' }} />
                        <Box
                            sx={{
                                borderRadius: 2,
                                border: '1px solid #33333322',
                                // marginTop: 5
                            }}
                        >

                            {
                                catalogItem.map((item, index) => (
                                    <Stack
                                        key={item.id}
                                        direction={{
                                            xs: 'column',
                                            sm: 'row'
                                        }}
                                        width='100%'
                                        justifyContent='space-between'
                                        spacing={2}
                                        padding={2}
                                    >
                                        <FormControl size='small' sx={{ width: { xs: '100%', sm: '80%' } }} >
                                            <InputLabel>Item</InputLabel>
                                            <Select
                                                label='Item '
                                                sx={{
                                                    width: '100%',
                                                    backgroundColor: 'white'
                                                }}
                                                name="item_id"
                                                onChange={(e) => { handleChangeCatalogItem(item.id, e) }}
                                                value={item.item_id}
                                            >
                                                {
                                                    itemList.map((item, index) => (
                                                        <MenuItem key={index} value={item.id}>{item.item_name}</MenuItem>
                                                    ))
                                                }
                                            </Select>
                                        </FormControl>
                                        {/* <Autocomplete
                                            fullWidth
                                            options={itemList}
                                            size="small"
                                            value={item.item_id || ''}
                                            onChange={handleAutoOnchangeForItem}
                                            renderInput={(params) => <TextField {...params} label="Item" />}
                                        /> */}
                                        <Stack direction='row' spacing={2}>
                                            {catalogItem.length > 1 && (
                                                <Tooltip title='Remove' arrow>
                                                    <Button
                                                        color="error"
                                                        size="sm"
                                                        variant="outlined"
                                                        onClick={() => { delAssociatedItem(item.id) }}
                                                        sx={{
                                                            flex: 1
                                                        }}
                                                    >
                                                        <RemoveCircleOutlineIcon />
                                                    </Button>
                                                </Tooltip>
                                            )}
                                            <Tooltip title='Add' arrow>
                                                <Button
                                                    color="info"
                                                    onClick={addCatalogItem}
                                                    size="sm"
                                                    variant="outlined"
                                                    sx={{
                                                        flex: 1
                                                    }}
                                                >
                                                    <AddIcon />
                                                </Button>
                                            </Tooltip>
                                        </Stack>
                                    </Stack>
                                ))
                            }
                        </Box>
                        <Stack
                            direction='row'
                            justifyContent='flex-end'
                            alignItems='center'
                            padding={1}
                            mt={2}
                        >
                            <Stack
                                direction={{
                                    xs: 'column',
                                    sm: 'row'
                                }}
                                width='100%'
                                justifyContent={{
                                    xs: 'center',
                                    sm: 'flex-end'
                                }}
                            >
                                <Tooltip title='Reset'>
                                    <IconButton onClick={clear} >
                                        <RotateLeftIcon />
                                    </IconButton>
                                </Tooltip>
                                <Button
                                    variant='contained'
                                    sx={{
                                        width: {
                                            xs: '100%',
                                            sm: '200px'
                                        },
                                    }}
                                    onClick={handleSubmit}
                                >
                                    {isEdit ? 'Update' : 'Add Catalog'}
                                </Button>
                            </Stack>
                        </Stack>
                    </Paper>
                    <Divider
                        variant='middle'
                        sx={{
                            width: '80%',
                        }}
                    />
                    <CatalogTable handleEditData={handleEditData} isChanged={isChanged} />
                </Stack>
            </Box>
            <Footer />

        </>
    )
}

export default Catalog