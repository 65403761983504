
import { useJwt } from 'react-jwt'
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Cookies from 'universal-cookie';

export const SessionVerification = () => {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const token = localStorage.getItem("token");
    const { isExpired } = useJwt(token);
    const navigate = useNavigate()
    const cookie = new Cookies()

    if (isExpired) {
        navigate('/');
    }
    if (cookie.get('dcrm_token') === undefined) {
        navigate('/');
    }
    if (token === undefined) {
        navigate('/');
    }
    if (user_id === undefined) {
        navigate('/');
    }
}
