import React, { useState } from "react";
import BrandTable from "../Card/MaterEntryCards/BrandTable";
import BrandCard from "../Card/MaterEntryCards/BrandCard";
import { Stack, Paper } from "@mui/material";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";

//FUNCTION
const Brand = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const handleEdit = (data) => {
        setEditData(data)
        setIsCreate(true);
        setIsEdit(true);
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    //#endregion

    return (
        <>

            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
            >

                <Paper
                    elevation={0}
                    sx={{
                        width: '100%',
                        mt: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        px: 3,
                        pb:1
                    }}
                >
                    <CardHeadMenu
                        prefix={"Na"}
                        suffix={"Na"}
                        title={"BRAND"}
                        handlePopup={handlePopup}
                        isEdit={isEdit}
                        create={isCreate}
                    />
                    {/* <MasterEnteryCard title='Brand' /> */}
                    {isCreate &&
                        <BrandCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} />
                    }
                </Paper>

                <BrandTable isChanged={isChanged} handleEdit={handleEdit} />
            </Stack>

        </>
    )
}
export default Brand