import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Checkbox, Box, InputAdornment, Grid, Typography, FormControl, InputLabel, NativeSelect, TextField, CircularProgress } from '@mui/material';
import { IconButton } from '@material-ui/core';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import configServ from '../../../../services/config';
import CheckAndReturn from '../../../../utility/CheckAndReturn';
import { useSelector } from 'react-redux';

export default function PurchaseOrderTable({ handleEditData, isChanged }) {
  //#region code
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [purchaseOrderList, setPurchaseOrderList] = useState([]);
  const [filterPurchaseOrderList, setFilterPurchaseOrderList] = useState([]);
  const [searchBy, setSearchBy] = useState('supplierName');
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchPurchaseOrderList();
  }, [isChanged]);

  const fetchPurchaseOrderList = async () => {
    try {
      setLoading(true);
      const res = await configServ.purchaseOrderList(
        {
          "user_id": user_id,
          "company_id": company_id
        }
      );
      // const data = res.data.map(item=>({
      //   supplierName: item.partyname,
      //   purchaseOrderNo: item.purchase_order_no,
      //   address: item.billtoaddress,
      //   deliveryDate: CheckAndReturn.transformToDate2(item.delivery_date),
      //   totalAmount: item.total_amount,
      // }));
      setPurchaseOrderList(res.data);
      setFilterPurchaseOrderList(res.data);
      setCount(res.data.length);
      setLoading(false);
    }
    catch (error) {
      setLoading(false);
      console.log(error);
    }
  }

  const headers = ['SUPPLIER NAME', 'PURCHASE ORDER NO', 'ADDRESS', 'TOTAL AMOUNT', 'ACTION'];

  const headerCellStyle = {
    fontSize: 14, // Adjust the font size as needed
    fontWeight: 'bold',
    backgroundColor: 'black', // Set the header background color to blue
    color: 'white',
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleSearchBy = (event) => {
    try {
      setSearchBy(event.target.value);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleSearch = (event) => {
    try {
      const search = event.target.value;
      let data = [];
      if (search === '') {
        data = purchaseOrderList;
      }
      else if (searchBy === 'supplierName') {
        data = purchaseOrderList.filter(x => x.partyname.toLowerCase().includes(search.toLowerCase()))
      }
      else if (searchBy === 'purchaseOrderNo') {
        data = purchaseOrderList.filter(x => x.purchase_order_no.toLowerCase().includes(search.toLowerCase()))
      }
      setFilterPurchaseOrderList(data);
      setCount(data.length);
    }
    catch (error) {
      console.log(error);
    }
  }

  const handleEdit = (params) => {
    const data = filterPurchaseOrderList.filter((x) => x.id === params);
    handleEditData(data);
  };

  const evenRowStyle = {
    background: '#f5f5f5',
  };

  const oddRowStyle = {
    background: 'white',
  };

  const rowStyle = {
    '&:hover': {
      background: '#f1f9fe',
    },
  };
  //#endregion

  if (loading) {
    return (
      <Box
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <CircularProgress />
      </Box>
    )
  }
  else {
    return (
      <Paper sx={{ width: '100%' }}>

        <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
          <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
            <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Purchase Orders:{count}</Typography>
          </Grid>
          <Grid item xs={4} md={2}>
            <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
              <FormControl
                fullWidth
              >
                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                  Search By
                </InputLabel>
                <NativeSelect
                  defaultValue={'contact_name'}
                  onChange={handleSearchBy}
                  inputProps={{
                    name: 'searchBy',
                    id: 'uncontrolled-native',
                  }}
                >
                  <option value={'supplierName'}>Supplier Name</option>
                  <option value={'purchaseOrderNo'}>Purchase Order No</option>
                </NativeSelect>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
            <TextField
              id="outlined-basic"
              size="small"
              fullWidth
              placeholder="search"
              variant="outlined"
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon style={{ fill: 'grey' }} />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <TableContainer sx={{ maxHeight: 440 }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                {headers.map((header, index) => (
                  <TableCell key={index} style={headerCellStyle}>
                    {header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filterPurchaseOrderList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow key={row.id}
                      sx={{
                        ...rowStyle,
                        ...(index % 2 === 0 ? evenRowStyle : oddRowStyle),
                      }}
                    >
                      <TableCell >{index + 1}</TableCell>
                      <TableCell >{row.partyname}</TableCell>
                      <TableCell >{row.purchase_order_no}</TableCell>
                      <TableCell >{row.billtoaddress}</TableCell>
                      <TableCell >{row.total_amount}</TableCell>
                      <TableCell >
                        <Box sx={{
                          borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer',
                          padding: 0.5, ":hover": { backgroundColor: '#99999955' }
                        }} onClick={() => { handleEdit(row.id); }}
                        >
                          <EditIcon color='primary' />
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    );
  }
}