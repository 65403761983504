import React, { useState,useEffect } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import DispatchDetailsCard from "../../Components/Card/DispatchDetailsCards/DispatchDetailsCard";
import DispatchDetailTable from "../../Components/Card/DispatchDetailsCards/DispatchDetailTable";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from 'react-redux'
import { useLocation } from "react-router-dom";

const DispatchDetails = () => {

    const open = useSelector((state) => state.sideExpand.open)
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})
    const [invData, setInvData] = useState({})
    const location = useLocation()

    const handleEditData = (data) => {
        // console.log(data[0])
        setEditData(data[0])
    }

    useEffect(() => {
        if (location.state) {
            const data = location.state.data;
            // data.data.items = data.itemData
            setInvData(data.data)
            console.log(data.data)
        }
    }, [location.state])

    const clear = () => {
        setEditData({})
    }

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        elevation={0}
                        sx={{
                            width: '100%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb: 5
                        }}
                    >
                        {/* <ConfigurationCard  /> */}
                        <DispatchDetailsCard editData={editData} clear={clear} setIsChanged={setIsChanged} isChanged={isChanged} invData={invData} />
                        <Divider />
                        <br />
                        <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                            <Typography fontSize={15} variant="body" fontWeight='bold'>Dispatch Detail List</Typography>
                            <Divider />
                        </Stack>
                        <DispatchDetailTable handleEditData={handleEditData} isChanged={isChanged} />
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}
export default DispatchDetails