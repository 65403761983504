import React from "react";
import { Box, Container, Paper, CssBaseline, Avatar, Typography, Grid, TextField, Button } from "@mui/material";
import VpnKeyOffIcon from '@mui/icons-material/VpnKeyOff';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OuterHeader from "../../Components/header/Header2/OuterHeader";
import OuterHeader1 from "../../Components/header/Header2/OuterHeader1";
const theme = createTheme();
const ForgetPassword = () => {
    return (
        <>
            <OuterHeader1 />
            <br />
            <ThemeProvider theme={theme}>
                <Container component="main" maxWidth="sm">
                    <Paper elevation={2} sx={{ padding: 5, marginTop: 8, }}>
                        <CssBaseline />
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <VpnKeyOffIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5">
                                Reset Password
                            </Typography>
                            <Box component="form" noValidate sx={{ mt: 3 }}>
                                <Grid container >
                                    <Grid item xs={12}>
                                        <TextField
                                            required
                                            fullWidth
                                            id="email"
                                            label="E-Mail Address"
                                            name="email"
                                            autoComplete="email"
                                        //   onChange={handleChange}
                                        //   helperText={errorData.email?.text || ''}
                                        //   error={errorData.email?.err }
                                        />
                                    </Grid>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        variant="contained"
                                        sx={{ mt: 3, mb: 2, minWidth: '20%' }}
                                    >
                                        Send Link
                                    </Button>
                                </Grid>
                            </Box>
                        </Box>
                    </Paper>
                </Container>
            </ThemeProvider>
        </>
    )
}
export default ForgetPassword;