import React,{useState} from 'react'

import { BsPlusSquareFill, BsFillCalendar3EventFill, BsFillFileFill, BsXCircle } from "react-icons/bs";

import { Form, Container, Row, Col, FloatingLabel,Button } from 'react-bootstrap';
import { Stack, Paper, Divider, Typography, RadioGroup, Radio } from "@mui/material";
import MyFormControlLabel from '@mui/material/FormControlLabel';


function ReportCard() {

    const [notes, setNotes] = useState([])

    const addNote = ()=>{
        setNotes((state)=>([
            ...state,
            {}
        ]))
        console.log(notes)
    }
    const deleteNote = (id)=>{
        let data = [...notes]
        data.splice(id)
        setNotes(data)
        console.log(notes)
    }


  return (
    <>
    


        <div style={{ width: "100%", backgroundColor: "#DBDFEA99", padding: 20, borderRadius:'10px', border:'none' }}>
       
                <Row>
                    <Col sm={4}>

                        <Form.Control type="date" placeholder='Form Date' />
                    </Col>
                    <Col sm={4}>

                        <Form.Control type="date" placeholder='To Date' />
                    </Col>
                    <Col sm={2}>
                    <Button variant="primary"style={{width:"100%", fontSize:'0.9rem'}}>Filter</Button>
                    </Col>
                    <Col sm={2}>
                        <Button variant="dark"style={{width:'100%', fontSize:'0.9rem'}}>Refresh</Button>
                    </Col>
                </Row>

        </div>
        <br/>
    </>
  )
}

export default ReportCard