import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import configServ from '../../../services/config';
import ActionButtons from '../../Buttons/ActionButtons ';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../DataGridTable/DataGridTable';
import { useSelector } from 'react-redux';
// import axios from 'axios';
// import { appServiceName } from '../../../services/http';
// import { useSelector, useDispatch } from 'react-redux';
// import { triggerLoader } from '../../../redux/reducers/LoaderTrigger';
// import InvoiceOrientation from '../../Dialog/InvoiceOrientation';

const DispatchTable = ({ handleEditData, isChanged }) => {
    //#region code
    // const isLoaderActive = useSelector((state) => state.loader.isLoaderActive)
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [dipatchDataList, setDispatchDataList] = useState([]);
    const [dispatchList, setDispatchList] = useState([]);
    const [loading, setLoading] = useState(true);
    // const dispatch = useDispatch()
    // const [open, setOpen] = useState(false);
    // const [printData, setPrintData] = useState({});
    const columns = [
        { field: 'sn', headerName: 'S.NO.', flex: 1 },
        { field: 'invoice_no', headerName: 'INVOICE NO.', flex: 1 },
        { field: 'transporter_name', headerName: 'TRANSPORTER NAME', flex: 1 },
        { field: 'vehicle_num', headerName: 'VEHICLE NO.', flex: 1 },
        { field: 'bill_from', headerName: 'BILL FROM', flex: 1 },
        { field: 'bill_to', headerName: 'BILL TO', flex: 1 },
        { field: 'TransporterDate', headerName: 'DATE', type: 'date', flex: 1 },
        { field: 'ConsignorAddress1', headerName: 'DISPATCH FROM', flex: 1 },
        { field: 'ConsigneeAddress1', headerName: 'SHIP TO', flex: 1 },
        { field: 'TransporterMobileNo', headerName: 'MOBILE NO.', flex: 1 },
        { field: 'qnty', headerName: 'QUANTITY', flex: 1 },
        { field: 'amount', headerName: 'AMOUNT', flex: 1 },
        {
            field: 'action',
            headerName: 'Action',
            flex: 1,
            renderCell: (params) => (
                <ActionButtons
                    handlePrint={handlePrint}
                    handleEdit={handleEdit}
                    id={params.id}
                    noPrint
                />
            ),
        },
    ];

    useEffect(() => {
        fetchDispatchList();
    }, [isChanged]);

    const fetchDispatchList = async () => {
        const dataToSend = {
            "company_id": company_id
        }
        try {
            const res = await configServ.getDispatch(dataToSend);
            // console.log(res)
            const result = res.data.map((item, index) => ({
                sn: index + 1,
                id: item.id,
                invoice_no: item.invoice_no,
                transporter_name: item.transporter_name,
                vehicle_num: item.vehicle_num,
                bill_from: item.bill_from,
                bill_to: item.bill_to,
                TransporterDate: CheckAndReturn.transformToDate(item.TransporterDate),
                ConsignorAddress1: item.ConsignorAddress1,
                ConsigneeAddress1: item.ConsigneeAddress1,
                TransporterMobileNo: item.TransporterMobileNo,
                qnty: Number(item.qnty),
                amount: Number(item.amount),
            }));
            // console.log(result)
            setDispatchDataList(res.data || []);
            setDispatchList(result || []);
            setLoading(false);
        }
        catch (error) {
            // console.log(error);
            setDispatchDataList([]);
            setDispatchList([]);
            setLoading(false);
        }
    }


    // const printInvoice = async (inv) => {
    //     const data = {
    //         "id": inv.id,
    //         "invoice_no": inv.invoice_number,
    //         "user_id": user_id,
    //         "company_id": company_id,
    //     }
    //     setOpen(true);
    //     setPrintData(data)
    // }


    const handlePrint = (params) => {
        const data = dipatchDataList.filter(x => x.id === params);
        // console.log('data', data)
        // alert(JSON.stringify(data));
        // printInvoice(data[0])
    }

    const handleEdit = (params) => {
        // alert(JSON.stringify(params));
        scrollToTop()
        const data = dipatchDataList.filter(x => x.id === params);
        handleEditData(data)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
                <DataGridTable rows={dispatchList} columns={columns} width={'100%'} />
            </>
        );
    }
};

export default DispatchTable;
