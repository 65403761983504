import React, { useEffect, useState } from 'react';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import SaveIcon from '@mui/icons-material/Save';
import {
    Button,
    TextField,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    FormControlLabel,
    Checkbox,
    Typography,
    Grid
} from '@mui/material';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';

//FUNCTION
function SupplierCard({ isChanged, setIsChanged, editData, setEditData }) {
    //#region code
    const dispatch = useDispatch();
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const initialFormData = {
        openingamount: 0,
        status: true,
    };
    const [formData, setFormData] = useState(initialFormData);
    const [validationErrors, setValidationErrors] = useState({});
    const [countryList, setCountryList] = useState([]);
    const [stateList, setStateList] = useState([]);
    const [isDone, setIsDone] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }));
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }));
    }

    const assignContactName = () => {
        if (!isDone) {
            setFormData((state) => ({
                ...state,
                contact_name: formData.fname || ''
            }));
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const error = await Validate();
            if (error === false) {
                return false;
            }
            const dataToSend = {
                ...formData,
                company_id: company_id,
                admin_id: user_id
            }
            if (formData.is_credit_days === false) {
                delete dataToSend.credit_period
                delete dataToSend.credit_limit
            }
            try {
                dispatch(triggerLoader());
                if (!isEdit) {
                    const result = await configServ.addSupplier(dataToSend);
                    setIsChanged(!isChanged)
                    clear();
                    handleOpenDialog(result.message)
                } 
                else {
                    const result = await configServ.editSupplier(dataToSend);
                    setIsChanged(!isChanged)
                    clear()
                    handleOpenDialog(result.message);
                }
            }
            catch (err) {
                console.log(err);
            }
            finally{
                dispatch(triggerLoader());
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const Validate = async () => {
        try {
            let errors = {};
            if (!formData.fname) {
                errors.fname = "required";
            }
            if (!formData.contact_name) {
                errors.contact_name = "required";
            }
            if (!formData.country) {
                errors.country = "required";
            }
            if (!formData.state) {
                errors.state = "required";
            }
            if (!formData.phone) {
                errors.phone = "required";
            }
            if (!formData.address) {
                errors.address = "required";
            }

            setValidationErrors(errors);
            return Object.values(errors).every((error) => error === "");
        }
        catch (e) {
            console.log(e);
        }
    }

    const fetchCounties = async () => {
        const res = await axios.get('https://countriesnow.space/api/v0.1/countries/capital')
        if (!res.error) {
            setCountryList(res.data.data);
        }
    }

    const fetchStates = async () => {
        const res = await axios.post('https://countriesnow.space/api/v0.1/countries/states', { "country": `${formData.country}` })
        if (!res.error) {
            setStateList(res.data.data.states);
        }
    }

    useEffect(() => {
        fetchCounties()
    }, []);

    useEffect(() => {
        if (formData.country !== undefined) {
            fetchStates()
        }
    }, [formData]);

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData(editData);
            setIsEdit(true);
        }
    }, [editData]);

    const clear = () => {
        setFormData(initialFormData);
        setValidationErrors({});
        setEditData({});
        setIsDone(false);
        setIsEdit(false);
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Grid container>
                <Grid container alignItems={'center'} spacing={1} bgcolor={'#e3f3fc'} my={1} px={2}>
                    <Grid item xs={12} md={12} justifyContent={'flex-start'} pt={1}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Supplier Detail</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label={<span>Supplier Name<span style={{color:'red'}}>*</span></span>}
                            name='fname'
                            onChange={handleChange}
                            value={formData.fname || ''}
                            error={!!validationErrors.fname}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label={<span>Contact Name<span style={{color:'red'}}>*</span></span>}
                            name='contact_name'
                            onChange={handleChange}
                            onClick={() => { assignContactName(); setIsDone(true) }}
                            value={formData.contact_name || ''}
                            error={!!validationErrors.contact_name}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth size='small'>
                            <InputLabel error={!!validationErrors.country}>Country<span style={{color:'red'}}>*</span></InputLabel>
                            <Select required
                                name='country'
                                label='select'
                                value={formData.country || ''}
                                onChange={handleChange}
                                error={!!validationErrors.country}
                                sx={{ backgroundColor: 'white' }}
                            >
                                {countryList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <FormControl fullWidth size='small'>
                            <InputLabel error={!!validationErrors.state}>State<span style={{color:'red'}}>*</span></InputLabel>
                            <Select required
                                name='state'
                                label='select'
                                value={formData.state || ''}
                                onChange={handleChange}
                                error={!!validationErrors.state}
                                sx={{ backgroundColor: 'white' }}
                            >
                                {stateList.map((item, index) => {
                                    return (
                                        <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='Email'
                            name='email'
                            onChange={handleChange}
                            value={formData.email || ''} b
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label={<span>Mobile Number<span style={{color:'red'}}>*</span></span>}
                            name='phone'
                            type='number'
                            onChange={handleChange}
                            value={formData.phone || ''}
                            error={!!validationErrors.phone}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label={<span>Mailing Address<span style={{color:'red'}}>*</span></span>}
                            name='address'
                            onChange={handleChange}
                            value={formData.address || ''}
                            error={!!validationErrors.address}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            type='number'
                            size='small'
                            label='Opening Amount'
                            name='openingamount'
                            onChange={handleChange}
                            value={formData.openingamount || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} justifyContent={'flex-start'} mt={1}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Tax Detail</Typography>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='PAN/NO'
                            name='pan_no'
                            onChange={handleChange}
                            value={formData.pan_no || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <TextField
                            fullWidth
                            size='small'
                            label='GSTIN/UIN'
                            name='gst_uin'
                            onChange={handleChange}
                            value={formData.gst_uin || ''}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>
                    <Grid container xs={12} md={8} border={'solid lightgrey 1px'} spacing={1} m={1} bgcolor={'#f4e7b7'}>
                        <Grid item xs={12} md={12}>
                            <FormControlLabel control={<Checkbox />} name='is_credit_days' checked={Boolean(formData.is_credit_days)} onChange={handleChangeCheckbox} label='Check credit days' />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label='Credit Period'
                                size='small'
                                name='credit_period'
                                InputProps={{
                                    endAdornment: 'd'
                                }}
                                disabled={formData.is_credit_days === true ? false : true}
                                onChange={handleChange}
                                value={formData.credit_period || ''}
                                sx={{ backgroundColor: 'white', mb: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                label='Credit Limmit'
                                size='small'
                                name='credit_limit'
                                disabled={formData.is_credit_days === true ? false : true}
                                onChange={handleChange}
                                value={formData.credit_limit || ''}
                                sx={{ backgroundColor: 'white', mb: 1 }}
                            />
                        </Grid>
                    </Grid>
                    <Grid container item xs={12} md={2} justifyContent={'flex-end'}>
                        <FormControlLabel control={<Checkbox />} name='status' checked={Boolean(formData.status)} onChange={handleChangeCheckbox} label='Status' />
                    </Grid>
                    <Grid container item justifyContent={'flex-end'} bgcolor={'#e3f3fc'} spacing={1} mb={2}>
                        <Button onClick={clear} variant='contained' color='secondary' startIcon={<RotateLeftIcon />} sx={{ mx: 1 }}>
                            Reset
                        </Button>
                        <Button variant='contained' onClick={handleSubmit} startIcon={<SaveIcon />}>
                            {isEdit ? 'Update' : 'Save'}
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}

export default SupplierCard;