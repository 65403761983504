import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Box,
    Typography,
    Container,
    TextField,
    FormControlLabel,
    Grid,
    Checkbox,
    Button,
    CircularProgress,
    Stack,
    SvgIcon
} from "@mui/material";
import OuterHeader from "../../Components/header/Header2/OuterHeader";
import SiteLogo from '../../Components/Assets/images/DovetailLogo.svg'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import OuterHeader1 from "../../Components/header/Header2/OuterHeader1";
import LoginIcon from '@mui/icons-material/Login';
import { useSelector } from "react-redux";
import bk from '../../Components/Assets/images/wbk.png';
import Cookies from "universal-cookie/cjs/Cookies";
// import Inquiry from "../../Components/Inquiry/Inquiry";

const Welcome = () => {
    const lightMode = useSelector(state=>state.GlobalVariables.lightMode);
    const inquiryRef = useRef(null);
    const navigate = useNavigate();
    const cookie = new Cookies();

    // const checkedLoggedIn = ()=>{
    //     if(cookie.get('dcrm_token') !== undefined ){
    //         if(cookie.get('userType') === 'customer' ){
    //             navigate('/customer-dashboard')
    //         }else{
    //             navigate('/Dashboard')
    //         }
    //     }
    // }

    // useEffect(()=>{
    //     checkedLoggedIn()
    // },[])

    // function scrollToSection(sectionRef) {
    //     if (sectionRef.current) {
    //       sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    //     }
    // }
    
    const lines = [
        "Dovetail-crm is a technology-driven manufacturing company delivering Billing, Payment Delivery Solutions for Small Medium Businesses.",
        "This software is the web version of Accounting Software and modified version of Tally application.",
        "It extends the feature of tally application and provide flexibility to the user to use either web application for accounting or both tally and web application but data will be synced and will provide flexibility to the user.",
      ];

    return (
        <>
            <Box
                sx={{
                    // position: 'absolute',
                    // top: 0,
                    width: '100vw',
                    height: '100vh',
                    backgroundImage: `url(${bk})`,
                    backgroundColor: lightMode ? '#ffffff' : '#090e34',
                    opacity: 1,
                    zIndex: -1
                }}
            >
                <OuterHeader1 />
                <Container
                    component="main"
                    maxWidth="md"
                    sx={{
                        // border: '1px solid red',
                        // padding: 3,
                        mt: {
                            xs: 20,
                            sm: 10
                        }

                    }}
                >
                    <Stack
                        justifyContent={'center'}
                        alignItems={'center'}
                        spacing={3}
                        // border={1}
                    >
                        <Box
                            component={'img'}
                            src={SiteLogo}
                            mb={{
                                xs: 5,
                                sm: 5
                            }}
                            sx={{
                                height:'auto',
                                width:'50%',
                                filter: lightMode ?'invert(0)':'invert(1)'
                            }}
                        />
                        
                    </Stack>
                    <Stack width={'100%'}>
                        <div style={{ position: 'relative', textAlign: 'center' }}>
                            {lines.map((line, index) => (
                                <Typography
                                key={index}
                                variant="p"
                                sx={{
                                    fontSize: '20px',
                                    fontWeight: 600,
                                    lineHeight: '35px',
                                    margin: `-${index * 20}px 0`, // Adjust the spacing between lines
                                    color: lightMode ? '#959cb1' : '#ffffff',
                                }}
                                >
                                {line}
                                </Typography>
                            ))}
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                            <Button
                                variant="contained"
                                size="large"
                                sx={{
                                maxWidth: '10rem',
                                margin:'20px'
                                }}
                                onClick={() => { navigate('/login') }}
                            >
                                <LoginIcon sx={{margin:'10px'}}/>Login
                            </Button>
                        </div>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};
export default Welcome;