import React, { useState, useEffect } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar2";
// import AccountMasterCard from "../../Components/Card/AccountMasterCards/AccountMasterCard";
// import AccountMasterTable from "../../Components/Card/AccountMasterCards/AccountMasterTable";
import InvoiceCard from "../../Components/Card/InvoiceCards/InvoiceCard";
import configServ from "../../services/config";
import AllInvoices from "./AllInvoice";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

import { Box, Stack, Paper, Divider, NativeSelect, Button, TextField, Select, InputAdornment, InputLabel, FormControl, Typography, Tooltip } from "@mui/material";
import { useSelector, useDispatch } from 'react-redux'
import { Container } from "react-bootstrap";
import { motion } from 'framer-motion'
import InvoiceTable from "../../Components/Card/InvoiceCards/InvoiceTable";
import EPopup from "./EOptions/EPopup";
import SearchIcon from '@mui/icons-material/Search';

const Invoice = () => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const dispatch = useDispatch();
    const open = useSelector((state) => state.sideExpand.open)
    const [tableData, setTableData] = useState([])
    const [listAll, setListAll] = useState(false)
    const [addInvoice, setAddInvoice] = useState(false);
    const [editData, setEditData] = useState({})
    const [isChanged, setIsChanged] = useState(false)
    const [openDialog, setOpenDialog] = useState(false);
    const [edata, setEdata] = useState({})
    const [searchBy, setSearchBy] = useState('contact_name');

    const handleDialogOpen = () => {
        setListAll(true);
    };

    const handleDialogClose = () => {
        setListAll(false);
    };

    const rowsPerPageOptions = [5, 10, 25];

    // const fetchRecentInvoiceList = async () => {
    //     try {
    //         const result = await configServ.recentInvoiceList(company_id);
    //         setTableData(result)
    //     } catch (err) {
    //         console.log(err);
    //     }
    // }

    // useEffect(() => {
    //     fetchRecentInvoiceList()
    // }, [])

    const handlePopup = () => {
        setAddInvoice(!addInvoice)
    }

    const handleEditData = (data) => {
        setEditData(data[0])
        setAddInvoice(true);
    }

    const clear = () => {
        setEditData({})
    }

    const handleClickOpen = (data) => {
        setOpenDialog(true);
        setEdata(data)
    };

    const handleClose = () => {
        setOpenDialog(false);
    };
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                // marginLeft: {
                //     xs: 7,
                //     sm: 8,
                // },
                ...(open && { marginLeft: 23 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>
                <EPopup handleClose={handleClose} open={openDialog} edata={edata} />
                {listAll && (<AllInvoices handleClickOpen={handleDialogOpen} handleClose={handleDialogClose} open={listAll} setOpen={setListAll} />)}
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            marginLeft: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingLeft: 3,
                            pb: 5
                        }}
                        elevation={0}
                    >
                        {/* {!addInvoice &&
                            <motion.h4
                                style={{
                                    textAlign: 'center',
                                    alignContent: 'center',
                                    justifyContent: 'center',
                                    fontWeight: 'bolder', // Add the fontWeight property to make the text bolder
                                    fontSize: '24px', // Add the fontSize property to increase the text size
                                }}
                                initial={{ opacity: 0, y: -20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.1 }}
                            >
                                Add Invoices
                            </motion.h4>
                        }
                        <Stack direction='row' justifyContent='space-between' width='100%'>
                            {!addInvoice && (<Button
                                variant="contained"
                                size="large"
                                sx={{
                                    justifySelf: 'flex-start'
                                }}
                                onClick={handlePopup}
                            >
                                Add Invoice
                            </Button>)}
                        </Stack> */}
                        <Container>
                            <InvoiceCard editData={editData} clear={clear} setIsChanged={setIsChanged} isChanged={isChanged} addInvoice={addInvoice} />
                        </Container>
                        <Stack direction='column' justifyContent='flex-start' width='100%' my={0} px='5%'>
                            <Stack direction='row' alignItems='center' justifyContent='space-between'>
                            </Stack>
                        </Stack>
                        <InvoiceTable handleEditData={handleEditData} isChanged={isChanged} handleClickOpen={handleClickOpen} handleDialogOpen={handleDialogOpen} />

                    </Paper>
                </Stack>
            </Box>
            <Footer />
        </>
    )
}
export default Invoice;