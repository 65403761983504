import React, { useState } from 'react';
import {
    Box, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, NativeSelect, TextField, Typography, Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip
} from '@mui/material';
import configServ from '../../services/config';
import ActionButtons from '../../Components/Buttons/ActionButtons ';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../Components/DataGridTable/DataGridTable';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';

//START FUNCTION
const SuperCategoryTable = ({ handleEditData, isChanged, pagination = true, handleDeleteData }) => {
    const [superCategoryDataList, setSuperCategoryDataList] = useState([]);
    const [superCategoryList, setSuperCategoryList] = useState([]);
    const [loading, setLoading] = useState(true);
    const rowsPerPageOptions = [5, 10, 25];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [count, setCount] = useState(0);
    const [searchBy, setSearchBy] = useState('name');
    const [isDelete, setIsDelete] = useState(false);

    useEffect(() => {
        fetchSuperCategoryList();
    }, [isChanged]);

    const headers = ['NAME', 'CREATED BY', 'STATUS', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, superCategoryList.length - page * rowsPerPage);

    const paginatedData = pagination
        ? superCategoryList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : superCategoryList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: 'black',
        color: 'white',
    };
    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            let dataList = [];
            if (search === '') {
                dataList = superCategoryDataList;
            }
            else if (searchBy === 'name') {
                dataList = superCategoryDataList.filter(x => x.name.toLowerCase().includes(search.toLowerCase()))
            }

            setSuperCategoryList(dataList);
            setCount(dataList.length);
        }
        catch (error) {
            console.log(error);
        }
    }

    const fetchSuperCategoryList = async () => {
        try {
            const res = await configServ.getSuperCategory();
            const result = res.map((item, index) => ({
                sn: index + 1,
                id: item.id,
                name: item.name,
                status: item.status,
                service_name: item.service_name,
                created_by: item.admin.name,
            }));
            setSuperCategoryDataList(result || []);
            setSuperCategoryList(result || []);
            setCount(result.length);
            setLoading(false);
        }
        catch (error) {
            setSuperCategoryDataList([]);
            setSuperCategoryList([]);
            setLoading(false);
        }
    }

    const handlePrint = (params) => {
        const data = superCategoryDataList.filter(x => x.id === params);
    }

    const handleEdit = (params) => {
        scrollToTop()
        const data = superCategoryDataList.filter(x => x.id === params.id);
        handleEditData(data)
    }

    const handleDelete = async (params) => {
        try {
            // Display a confirmation dialog
            const confirmed = window.confirm('Are you sure you want to delete it?');
            if (confirmed) {
                handleDeleteData(params);
            }
        }
        catch (error) {
            console.log(error);
        }
    }


    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
                {/* search starts here */}
                <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0, paddingBottom: 0, marginTop: 0, marginBottom: 0 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Super Categories:{count}</Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'name'}>Name</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                {/* search ends here */}

                <TableContainer sx={{ px: 5, marginTop: 0, }} elevation={0}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {/* <TableCell style={headerCellStyle}>
                            <IconButton disabled>
                                <VisibilityIcon />
                            </IconButton>
                            </TableCell> */}
                                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}
                                >
                                    {/* <TableCell style={iconCellStyle}>
                                <Tooltip title='View details' arrow>
                                    <IconButton onClick={()=>{handleNestedTable(row.id)}}>
                                        <VisibilityIcon color='primary'  />
                                    </IconButton>
                                </Tooltip>
                            </TableCell> */}
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>{row.name}</TableCell>
                                    <TableCell>{row.created_by}</TableCell>
                                    <TableCell>{row.status ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5 }}>
                                            <Tooltip title='EDIT' arrow>
                                                <EditIcon color='primary' sx={{ ":hover": { backgroundColor: '#99999955' } }} onClick={() => { handleEdit(row); scrollToTop() }} />
                                            </Tooltip>
                                            <Tooltip title='DELETE' arrow>
                                                <DeleteTwoToneIcon color='error' sx={{ ":hover": { backgroundColor: '#99999955' } }} onClick={() => { handleDelete(row); scrollToTop() }} />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>
            </>
        );
    }
};

export default SuperCategoryTable;
