import React, { useEffect, useState } from "react";
import {
  RadioGroup,
  Box,
  Button,
  Radio,
  IconButton,
  Tooltip,
  Fade,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Typography,
  Select,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import configServ from "../../../services/config";
import TextField from "@mui/material/TextField";
import AddInvoiceItems from "./AddInvoiceItems";
import Calculation from "../../../utility/Calculations";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import JoyModal from "../../Dialog/JoyModal";
import CardHeadMenu from "../CardHeadMenu/CardHeadMenu";
import OrderToInvoice from "../../../pages/Invoice/OrderToInvoice";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import WantToPrint from "./WantToPrint";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import CloseIcon from "@mui/icons-material/Close";
import { triggerLoader } from "../../../redux/reducers/LoaderTrigger";
import { useDispatch, useSelector } from "react-redux";
import AlertDialog from "../../AlertDialog/AlertDialog";

//FUNCTION
function InvoiceCard({ editData, clear, setIsChanged, isChanged, addInvoice }) {
  //#region code
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
  const [invoiceData, setInvoiceData] = useState({});
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [oti, setOti] = useState(false);
  const [notes, setNotes] = useState([]);
  const [items, setItems] = useState([]);
  //const [firms, setFirms] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [autoGenerate, setAutoGenerate] = useState(true);
  const [dialog, setDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogMessage, setDialogMessage] = useState("");
  const [joyOpen, setJoyOpen] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalTaxAmount, setTotalTaxAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const shipToAddressesInitial = [
    {
      id: "",
      address: "",
    },
  ];
  const [shipToAddresses, setShipToAddresses] = useState(
    shipToAddressesInitial
  );
  const invoiceInitial = {
    user_id: user_id,
    company_id: company_id,
    is_update: "",
    _token: "",
    contact_name: "",
    printID: "",
    buyeraddress: "",
    ShipToAddress: "",
    invhd1: "",
    invid: "",
    auto_gen: "on",
    invoice_number: "",
    date: "",
    due_date: "",
    sales_order: "",
    salesperson_name: "",
    pay_mode: "",
    tcs: "",
    delivery_note: "",
    CgstVal: "",
    totalamtWithoutTcsTax: "",
    totaltaxwithoutTCS: "",
    customer_id: "",
    item: [
      {
        itemname: "",
        item_id: "",
        itemcode: "",
        packagingunit: "",
        hsn_code: "",
        tax_rate: "",
        qty: "",
        rate: "",
        amountWithoutTax: "",
        discount: "",
        tax_amount: "",
        amount: "",
      },
    ],
  };
  const [invoice, setInvoice] = useState(invoiceInitial);
  const invoiceErrorInitial = {
    contact_name: "",
    buyeraddress: "",
    ShipToAddress: "",
    date: "",
    due_date: "",
  };
  const [invoiceError, setInvoiceError] = useState(invoiceErrorInitial);
  const itemListInitial = [
    {
      id: 1,
      item_id: "",
      itemname: "",
      itemcode: "",
      packagingunit: "",
      hsn_code: "",
      tax_rate: "",
      qty: "",
      rate: "",
      amountWithoutTax: "",
      discount: 0,
      tax_amount: "",
      amount: "",
    },
  ];
  const [itemList, setItemList] = useState(itemListInitial);
  const itemListErrorInitial = [
    {
      itemname: "",
      qty: "",
    },
  ];
  const [itemListError, setItemListError] = useState([]);
  const [isCreate, setIsCreate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };
  // const [itemSize, setItemSize] = useState([])

  // const fetchItemSize = async (id) => {
  //     try {
  //         const result = await configServ.getItemSize({ "item_id": id })
  //         const filtered = result.filter((item) => {
  //             return item.size !== 'Primary'
  //         })
  //         // console.log(filtered)
  //         setItemSize(filtered)
  //     } catch (err) {
  //         console.log(err)
  //     }
  // }

  useEffect(() => {
    fetchCustomerList();
    fetchItemList();
  }, []);

  useEffect(() => {
    if (addInvoice === true) {
      setIsCreate(true);
    } else {
      setIsCreate(false);
    }
  }, [addInvoice]);

  useEffect(() => {
    calculateTotalQuantity();
    calculateTotalTaxAmount();
    calculateTotalAmount();
  }, [itemList]);

  // const assignInvoiceEdit = (data) => {
  //     setInvoice(
  //         {
  //             user_id: user_id,
  //             company_id: company_id,
  //             is_update: '',
  //             _token: '',
  //             contact_name: data.customer.contact_name,
  //             printID: '',
  //             buyeraddress: data.buyeraddress,
  //             ShipToAddress: data.ShipToAddress,
  //             invhd1: '',
  //             invid: '',
  //             auto_gen: '',
  //             invoice_number: '',
  //             date: data.date,
  //             due_date: data.due_date,
  //             sales_order: data.sales_order,
  //             salesperson_name: data.saleperson,
  //             pay_mode: data.pay_mode,
  //             tcs: data.tcs_rate,
  //             delivery_note: data.delivery_note,
  //             CgstVal: data.tax_type,
  //             totalamtWithoutTcsTax: '',
  //             totaltaxwithoutTCS: '',
  //             customer_id: '',
  //             item: [
  //                 {
  //                     itemname: '',
  //                     item_id: '',
  //                     itemcode: '',
  //                     packagingunit: '',
  //                     hsn_code: '',
  //                     tax_rate: '',
  //                     qty: '',
  //                     rate: '',
  //                     discount: '',
  //                     tax_amount: '',
  //                     amount: ''
  //                 }
  //             ],
  //         }
  //     )
  // }

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setInvoice(editData);
      // assignInvoiceEdit(editData)
      setItemList(editData.items);
      setIsEdit(true);
      fetchShipToAddress(editData.customer_id);
    }
  }, [editData]);

  const fetchCustomerList = async () => {
    try {
      const result = await configServ.get_customer({
        company_id: company_id,
      });
      setCustomers(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchItemList = async () => {
    try {
      const result = await configServ.getItemList({
        company_id: company_id,
      });
      setItems(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchCustomers = async (cust_id) => {
    try {
      const result = await configServ.getShipToAddress({ cust_id: cust_id });
      setCustomers(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchShipToAddress = async (cust_id) => {
    try {
      if (cust_id !== undefined && cust_id !== null) {
        let result = [];
        try {
          result = await configServ.getShipToAddress({ cust_id: cust_id });
        } catch (ex) {
          console.log(ex);
        }

        if (result.length > 0) {
          setShipToAddresses(result);
          if (result[0].address !== undefined && result[0].address !== null) {
            setInvoice((prev) => ({
              ...prev,
              ShipToAddress: result[0].address,
            }));
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (invoice.contact_name) {
      const selectedCustomer = customers.find(
        (item) =>
          item.fname.toLowerCase() === invoice.contact_name.toLowerCase()
      );
      if (selectedCustomer !== undefined) {
        fetchShipToAddress(selectedCustomer.id);
      }
    }
  }, [invoice.contact_name]);

  // Function to add a new item to the list
  const handleAddItem = () => {
    setItemList([...itemList, { id: itemList.length + 1, name: "", qty: "" }]);
  };

  // Function to delete an item from the list based on its ID
  const handleDeleteItem = (id) => {
    const updatedItemList = itemList.filter((item) => item.id !== id);
    setItemList(updatedItemList);
  };

  const handleInputChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        if (value.length > 0) {
          if (name === "itemname") {
            // Find the selected item from the options
            const selectedItem = items.find(
              (item) =>
                `${item.item_name.toLowerCase()}(${item.item_code.toLowerCase()})` ===
                value.toLowerCase()
            );
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                item_id: selectedItem.id,
                itemname: selectedItem.item_name,
                itemcode: selectedItem.item_code,
                packagingunit: selectedItem.packagingunit,
                hsn_code: selectedItem.hsncode,
                discount: selectedItem.discount,
                qty: "",
                rate: selectedItem.rate_unit,
                tax_rate: selectedItem.tax_rate,
                issizeAdded: Boolean(selectedItem.issizeAdded),
                // size_id:selectedItem.size_id || null,
                tax_amount: "0.00",
                amount: "0.00",
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                item_id: "",
                itemname: "",
                itemcode: "",
                packagingunit: "",
                hsn_code: "",
                discount: "",
                rate: "",
                tax_rate: "",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleQuantityChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        // Find the selected item from the options
        const selectedItem = itemList.find((item) => item.id === id);
        if (value.toString().length > 0) {
          if (name === "qty") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const taxAmount = Calculation.GetDiscountTaxAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                value,
                selectedItem.discount
              );
              const amountWithoutTax = Calculation.GetAmount(
                selectedItem.rate,
                value
              );
              const totalAmount = Calculation.GetDiscountTotalAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                value,
                selectedItem.discount
              );
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                qty: value,
                amountWithoutTax: amountWithoutTax.toString(),
                tax_amount: taxAmount.toString(),
                amount: totalAmount.toString(),
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                qty: "",
                amountWithoutTax: "0.00",
                tax_amount: "0.00",
                amount: "0.00",
              };
            }
          } else if (name === "rate") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const taxAmount = Calculation.GetDiscountTaxAmount(
                selectedItem.tax_rate,
                value,
                selectedItem.qty,
                selectedItem.discount
              );
              const amountWithoutTax = Calculation.GetAmount(
                value,
                selectedItem.qty
              );
              const totalAmount = Calculation.GetDiscountTotalAmount(
                selectedItem.tax_rate,
                value,
                selectedItem.qty,
                selectedItem.discount
              );
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                rate: value,
                amountWithoutTax: amountWithoutTax.toString(),
                tax_amount: taxAmount.toString(),
                amount: totalAmount.toString(),
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                rate: "",
                amountWithoutTax: "0.00",
                tax_amount: "0.00",
                amount: "0.00",
              };
            }
          } else {
            // For other fields, just update the value
            // console.log(name,' ',value)
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        } else {
          if (name === "qty") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              qty: "",
              tax_amount: "0.00",
              amount: "0.00",
            };
            const updatedItemList = itemList.map((item) =>
              item.id === id ? updatedItem : item
            );
            setItemList(updatedItemList);
          } else if (name === "rate") {
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              rate: "",
              tax_amount: "0.00",
              amount: "0.00",
            };
            const updatedItemList = itemList.map((item) =>
              item.id === id ? updatedItem : item
            );
            setItemList(updatedItemList);
          }
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDiscountChange = (event, id) => {
    try {
      const { name, value } = event.target;
      let updatedItem;
      if (value !== null && value !== undefined) {
        // Find the selected item from the options
        const selectedItem = itemList.find((item) => item.id === id);
        if (value.length > 0) {
          if (name === "discount") {
            // If a valid item is selected, update the item properties accordingly
            if (selectedItem) {
              const taxAmount = Calculation.GetDiscountTaxAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                selectedItem.qty,
                value
              );
              const totalAmount = Calculation.GetDiscountTotalAmount(
                selectedItem.tax_rate,
                selectedItem.rate,
                selectedItem.qty,
                value
              );
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                discount: value,
                tax_amount: taxAmount.toString(),
                amount: totalAmount.toString(),
              };
            } else {
              // If the selected item is not found, reset the properties
              updatedItem = {
                ...itemList.find((item) => item.id === id),
                discount: "",
              };
            }
          } else {
            // For other fields, just update the value
            updatedItem = {
              ...itemList.find((item) => item.id === id),
              [name]: value,
            };
          }
          // Update the itemList state with the updated item
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        } else {
          updatedItem = {
            ...itemList.find((item) => item.id === id),
            [name]: value,
            tax_amount: Calculation.GetDiscountTaxAmount(
              selectedItem.tax_rate,
              selectedItem.rate,
              selectedItem.qty,
              value
            ),
            amount: Calculation.GetDiscountTotalAmount(
              selectedItem.tax_rate,
              selectedItem.rate,
              selectedItem.qty,
              value
            ),
          };
          const updatedItemList = itemList.map((item) =>
            item.id === id ? updatedItem : item
          );
          setItemList(updatedItemList);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handleFirmInputChange = (event) => {
  //     try {
  //         const firmName = event.target.value;
  //         const selectedFirm = firms.find((item) => item.fname.toLowerCase() === firmName.toLowerCase());

  //         if (selectedFirm !== null && selectedFirm !== undefined) {
  //             setInvoice((prev) => ({
  //                 ...prev, // Copy existing fields
  //                 fname: selectedFirm.fname,
  //                 customer_id: selectedFirm.id, // Update the specific field with the new value
  //                 buyeraddress: selectedFirm.address,
  //                 ShipToAddress: '',
  //                 date: '',
  //                 due_date: '',
  //                 salesperson: selectedFirm.salesperson,
  //             }));
  //             fetchCustomers(selectedFirm.id);
  //         }
  //     }
  //     catch (error) {
  //         console.log(error);
  //     }
  // }

  const handleCustomerInputChange = (event) => {
    try {
      const customerName = event.target.value;
      const selectedCustomer = customers.find(
        (item) => item.fname.toLowerCase() === customerName.toLowerCase()
      );
      if (selectedCustomer !== null && selectedCustomer !== undefined) {
        setInvoice((prev) => ({
          ...prev, // Copy existing fields
          customer_id: selectedCustomer.id, // Update the specific field with the new value
          contact_name: selectedCustomer.fname,
          buyeraddress: selectedCustomer.address,
          date: Calculation.GetCurrentDate(),
          due_date: Calculation.GetCurrentDate(),
          salesperson: selectedCustomer.salesperson,
        }));
        fetchShipToAddress(selectedCustomer.id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoiceCustomer = (event) => {
    try {
      const { name, value } = event.target;
      setInvoice((prev) => ({
        ...prev, //copy existing field values
        [name]: value, //update field value
      }));
      if (name === "ShipToAddress") {
        compareStateCodes(value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const stateCodeByName = async (stateName) => {
    try {
      const result = await configServ.getStateCodeByname({ State: stateName });
      // setShipToAddresses(result)
      return result;
    } catch (error) {
      console.log(error);
    }
  };

  const compareStateCodes = async () => {
    const dataToSend = {
      cust_id: invoice.customer_id,
      address: invoice.ShipToAddress,
    };
    const state = await configServ.getStateByCidAddress(dataToSend);
    //const stateCode = await stateCodeByName(state.State)
    const company = await configServ.company_details({
      company_id: company_id,
    });
    //const companyStateCode = await stateCodeByName(company.State)
    // console.log('selected', stateCode)
    // console.log('in-company', companyStateCode)
    // console.log(stateCode === companyStateCode)
    if (Boolean(state.State.toLowerCase() === company.State.toLowerCase())) {
      setInvoice((state) => ({
        ...state,
        tax_type: "CGST+SGST",
      }));
    } else {
      setInvoice((state) => ({
        ...state,
        tax_type: "IGST",
      }));
    }
  };

  // Handle the Autocomplete's onChange event
  const handleAutocompleteChange = (event, id) => {
    const value = event.target.innerText;
    if (value !== null && value !== undefined) {
      if (value.length > 0) {
        handleInputChange({ target: { name: "itemname", value } }, id);
      }
    }
  };

  // const handleFirmAutocompleteChange = (event) => {
  //     try {
  //         const value = event.target.innerText;
  //         if (value !== null && value !== undefined) {
  //             if (value.length > 0) {
  //                 handleFirmInputChange({ target: { name: 'fname', value } });
  //             }
  //         }
  //     }
  //     catch (error) {
  //         console.log(error);
  //     }
  // };

  const handleCustomerAutocompleteChange = (event) => {
    try {
      const value = event.target.innerText;
      if (value !== null && value !== undefined) {
        if (value.length > 0) {
          handleCustomerInputChange({
            target: { name: "contact_name", value },
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to validate the invoice fields
  const validateInvoice = () => {
    const errors = {};

    // Check if the required fields are empty
    if (!invoice.contact_name) {
      errors.contact_name = "Customer Name is required.";
    }
    if (!invoice.buyeraddress) {
      errors.buyeraddress = "Bill To Address is required.";
    }
    if (!invoice.ShipToAddress || invoice.ShipToAddress === "") {
      errors.ShipToAddress = "ShipTo Address is required.";
    }
    if (!invoice.date) {
      errors.date = "Invoice Date is required.";
    }
    if (!invoice.due_date) {
      errors.due_date = "Invoice Due Date is required.";
    }

    setInvoiceError(errors);
    return errors;
  };

  // Validate ItemList
  const validateItemList = () => {
    const errors = itemList.map((item) => {
      if (!item.issizeAdded) {
        return {
          itemname: !item.itemname ? "Item Name is required." : "",
          qty: !item.qty ? "Quantity is required." : "",
          // Add additional validations for other item fields as needed...
        };
      } else {
        return {
          itemname: !item.itemname ? "Item Name is required." : "",
          qty: !item.qty ? "Quantity is required." : "",
          size_id: !item.size_id ? "Size is required." : "",
          // Add additional validations for other item fields as needed...
        };
      }
    });

    setItemListError(errors);
    const hasErrors = errors.some((error) => error.itemname || error.qty);
    return hasErrors ? errors : {};
  };

  const handleSave = async () => {
    try {
      const invoiceErrors = validateInvoice();
      const itemListErrors = validateItemList();
      if (
        Object.keys(invoiceErrors).length > 0 ||
        Object.keys(itemListErrors).length > 0
      ) {
        // Handle the errors as needed (e.g., show error messages, prevent saving, etc.)
        return; // Return early if there are errors
      }

      invoice.item = await handleInvoiceItemList();
      if (!isEdit) {
        console.log("invoice", invoice);
        const res = await configServ.createInvoice(invoice);
        if (res.success === true) {
          setDialogTitle("Message");
          setDialogMessage("Invoice has been created successfully.");

          const userResponse = window.confirm("Do you want to dispatch?");
          if (userResponse) {
            navigate("/dispatch-details", {
              state: { data: res.data, itemData: res.itemData },
            });
          } else {
            console.log("Do no dispatch yet");
          }
          setJoyOpen(true);
          handleReset();
          setIsChanged(!isChanged);
          setInvoiceData(res.data);
          setOpen(true);
        } else if (res.success === false) {
          setDialogTitle("Message");
          setDialogMessage("Invoice creation failed. Please try again.");
          setJoyOpen(true);
        } else {
          setDialogTitle("Message");
          setDialogMessage("Invoice creation failed. Please try again.");
          setJoyOpen(true);
        }
      } else {
        const dataToSend = {
          ...invoice,
          user_id: user_id,
          company_id: company_id,
        };
        console.log("invoice", dataToSend);
        const res = await configServ.editInvoice(dataToSend);
        if (res.success === true) {
          setDialogTitle("Message");
          setDialogMessage("Invoice has been updated successfully.");
          handleOpenDialog("Invoice has been updated successfully.");

          setJoyOpen(true);
          handleReset();
          setIsChanged(!isChanged);
        } else if (res.success === false) {
          setDialogTitle("Message");
          setDialogMessage("Invoice creation failed. Please try again.");
          setJoyOpen(true);
        } else {
          setDialogTitle("Message");
          setDialogMessage("Invoice creation failed. Please try again.");
          setJoyOpen(true);
        }
        // alert('Update')
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInvoiceItemList = async () => {
    try {
      const invoiceItems = itemList.map((item) => ({
        itemname: item.itemname,
        item_id: item.item_id,
        itemcode: item.itemcode,
        packagingunit: item.packagingunit,
        hsn_code: item.hsn_code,
        tax_rate: item.tax_rate,
        qty: item.qty,
        rate: item.rate,
        discount: item.discount,
        tax_amount: item.tax_amount,
        amount: item.amount,
        size_id: item.size_id,
        issizeAdded: item.issizeAdded,
      }));
      return invoiceItems;
    } catch (error) {
      console.log(error);
    }
  };

  const handleAutoGenerateChange = (event) => {
    const autoGenerate = event.target.checked;
    if (autoGenerate === true) {
      setInvoice((prev) => ({
        ...prev,
        invoice_number: "",
        auto_gen: "on",
      }));
    } else {
      setInvoice((prev) => ({
        ...prev,
        invoice_number: "INV",
        auto_gen: "",
      }));
    }
    setAutoGenerate(autoGenerate);
  };

  const calculateTotalQuantity = () => {
    let totalQty = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const quantity = parseInt(item.qty);
      // Check if the amount is a valid number
      if (!isNaN(quantity)) {
        // Add the amount to the total
        totalQty += quantity;
      }
    });
    setTotalQuantity(totalQty);
  };

  const calculateTotalTaxAmount = () => {
    let totalTaxAmount = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const taxAmount = parseFloat(item.tax_amount);
      // Check if the amount is a valid number
      if (!isNaN(taxAmount)) {
        // Add the amount to the total
        totalTaxAmount += taxAmount;
      }
    });
    setTotalTaxAmount(totalTaxAmount.toFixed(2));
  };

  const calculateTotalAmount = () => {
    let totalAmount = 0;
    itemList.forEach((item) => {
      // Parse the amount string to a float value
      const amount = parseFloat(item.amount);
      // Check if the amount is a valid number
      if (!isNaN(amount)) {
        // Add the amount to the total
        totalAmount += amount;
      }
    });
    setTotalAmount(totalAmount.toFixed(2));
  };

  // Function to reset all form fields and state
  const handleReset = () => {
    setAutoGenerate(true);
    setDialog(false);
    setDialogTitle("");
    setDialogMessage("");
    setJoyOpen(false);
    setTotalTaxAmount(0);
    setTotalAmount(0);
    setInvoice(invoiceInitial);
    setInvoiceError(invoiceErrorInitial);
    setItemList(itemListInitial);
    setIsEdit(false);
    clear();
  };

  useEffect(() => {
    if (invoice.ShipToAddress) {
      compareStateCodes();
    }
  }, [invoice.ShipToAddress]);

  const handleOTI = () => {
    setOti(!oti);
  };

  const handlePopup = () => {
    try {
      setIsCreate(!isCreate);
    } catch (error) {
      console.log(error);
    }
  };

  const handleCreateClose = () => {
    handleReset();
    setIsCreate(false);
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />

      <WantToPrint open={open} setOpen={setOpen} data={invoiceData} />
      <CardHeadMenu
        prefix={"Inv"}
        suffix={"Order"}
        title={"INVOICE"}
        handlePopup={handlePopup}
        isEdit={isEdit}
        handleOTI={handleOTI}
        create={isCreate}
      />
      <OrderToInvoice
        handleClose={handleOTI}
        open={oti}
        sendData={setInvoice}
        sendItems={setItemList}
        invoiceInitial={invoiceInitial}
        itemListInitial={itemListInitial}
      />

      <JoyModal
        title={dialogTitle}
        message={dialogMessage}
        joyOpen={joyOpen}
        handleJoyClose={() => setJoyOpen(false)}
      />
      {isCreate === true && (
        <>
          <motion.div
            style={{
              width: "100%",
              backgroundColor: "#e3f3fc",
              padding: 20,
              borderRadius: "10px",
              border: "none",
              margin: "10px",
            }}
            initial={{ opacity: 0, y: 15 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.1, delay: 0.1 }}
          >
            <Grid
              container
              alignItems="flex-end"
              justifyContent="flex-start"
              style={{ padding: 10 }}
              spacing={1}
            >
              <Grid item xs={12} md={3}>
                <Link to="/customers">
                  <Tooltip title={"Add customer"}>
                    <PersonAddIcon
                      sx={{
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "grey",
                        margin: "2px",
                      }}
                      justifyContent="flex-end"
                    />
                  </Tooltip>
                </Link>
                <Autocomplete
                  id="contact-name"
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  value={invoice.contact_name}
                  options={customers.map((option) => option.fname)}
                  onChange={handleCustomerAutocompleteChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      value={invoice.contact_name}
                      name="contact_name"
                      label="Firm Name"
                      fullWidth
                      onChange={handleCustomerInputChange}
                      error={!!invoiceError.contact_name}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  name="buyeraddress"
                  value={invoice.buyeraddress}
                  onChange={handleInvoiceCustomer}
                  label="Bill To Address"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.buyeraddress}
                  InputLabelProps={{
                    shrink: invoice.buyeraddress ? true : false,
                  }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <FormControl fullWidth size="small">
                  <InputLabel id="demo-simple-select-label">
                    Ship To Address
                  </InputLabel>
                  <Select
                    sx={{ backgroundColor: "white" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Ship To Address"
                    name="ShipToAddress"
                    value={invoice.ShipToAddress}
                    onChange={handleInvoiceCustomer}
                  >
                    <MenuItem value={""}>Select</MenuItem>
                    {shipToAddresses.map((item) => (
                      <MenuItem key={item.id} value={item.address}>
                        {item.address}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              {/* <Grid item xs={12} md={3}>
                            <FormControlLabel control={<Checkbox />} label="Auto Generate" checked={autoGenerate}
                                onChange={handleAutoGenerateChange}
                                disabled={isEdit ? true : false} />
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                label="Invoice Number"
                                placeholder='INV0001 (Sample)'
                                sx={{ backgroundColor: 'white' }}
                                disabled={autoGenerate} name='invoice_number' value={invoice.invoice_number}
                                onChange={(e) => setInvoice((prev) => ({
                                    ...prev,
                                    invoice_number: e.target.value
                                }))}
                                InputLabelProps={{ shrink: true }} />
                        </Grid> */}
              <Grid item xs={12} md={3}>
                <TextField
                  type="date"
                  label="Invoice Date"
                  variant="outlined"
                  fullWidth
                  size="small"
                  name="date"
                  value={invoice.date}
                  onChange={handleInvoiceCustomer}
                  InputLabelProps={{ shrink: true }}
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.date}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  type="date"
                  label="Invoice Due Date"
                  name="due_date"
                  value={invoice.due_date}
                  onChange={handleInvoiceCustomer}
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                  error={!!invoiceError.due_date}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Sales Order No"
                  variant="outlined"
                  name="sales_order"
                  value={invoice.sales_order}
                  onChange={handleInvoiceCustomer}
                  type="text"
                  fullWidth
                  size="small"
                  disabled
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Salesperson/Ref. Name"
                  variant="outlined"
                  name="salesperson"
                  value={invoice.salesperson}
                  onChange={handleInvoiceCustomer}
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  label="Payment Mode"
                  name="pay_mode"
                  value={invoice.pay_mode}
                  onChange={handleInvoiceCustomer}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  label="TCS(%)"
                  variant="outlined"
                  name="tcs_rate"
                  value={invoice.tcs_rate}
                  onChange={handleInvoiceCustomer}
                  type="text"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <TextField
                  label="Delivery Note"
                  name="delivery_note"
                  value={invoice.delivery_note}
                  onChange={handleInvoiceCustomer}
                  variant="outlined"
                  fullWidth
                  size="small"
                  sx={{ backgroundColor: "white" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  sx={{
                    borderRadius: "5px",
                    backgroundColor: "white",
                    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  <FormControl component="fieldset">
                    <FormLabel
                      component="legend"
                      sx={{ fontWeight: "bolder", marginLeft: "20px" }}
                    >
                      Tax in
                    </FormLabel>
                    <RadioGroup
                      name="use-radio-group"
                      row
                      sx={{ marginLeft: "20px" }}
                    >
                      <FormControlLabel
                        checked={invoice.tax_type === "CGST+SGST"}
                        value="CGST_SGST"
                        label="CGST + SGST"
                        control={
                          <Radio
                            size="small"
                            onClick={() =>
                              setInvoice((prev) => ({
                                ...prev,
                                tax_type: "CGST+SGST",
                              }))
                            }
                          />
                        }
                      />
                      <FormControlLabel
                        checked={invoice.tax_type === "IGST"}
                        value="IGST"
                        label="IGST"
                        control={
                          <Radio
                            size="small"
                            onClick={() =>
                              setInvoice((prev) => ({
                                ...prev,
                                tax_type: "IGST",
                              }))
                            }
                          />
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{ marginTop: "10px", color: "green" }}
                >
                  Quantity: {totalQuantity}{" "}
                  <span style={{ marginLeft: "50px", marginRight: "50px" }}>
                    Tax Amount: ₹{totalTaxAmount}
                  </span>{" "}
                  Gross Amount: ₹{totalAmount}
                </Typography>
              </Grid>
            </Grid>
          </motion.div>
          <AddInvoiceItems
            itemList={itemList}
            handleAutocompleteChange={handleAutocompleteChange}
            handleInputChange={handleInputChange}
            handleAddItem={handleAddItem}
            handleDeleteItem={handleDeleteItem}
            items={items}
            handleQuantityChange={handleQuantityChange}
            handleDiscountChange={handleDiscountChange}
            errors={itemListError}
          />
          <Stack direction="row" justifyContent="flex-end" spacing={3} px={3}>
            <Button
              color={isEdit ? "error" : "secondary"}
              onClick={isEdit ? handleCreateClose : handleReset}
              autoFocus
              variant="contained"
              sx={{ minWidth: "15%" }}
              startIcon={isEdit ? <CloseIcon /> : <RotateLeftIcon />}
              title={isEdit ? "Close" : "Reset"}
            >
              {isEdit ? "Close" : "Reset"}
            </Button>
            <Button
              onClick={() => {
                handleSave();
              }}
              autoFocus
              variant="contained"
              sx={{ minWidth: "15%" }}
              startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
            >
              {isEdit ? "Update" : "Save"}
            </Button>
          </Stack>
        </>
      )}
    </>
  );
}

export default InvoiceCard;
