import React, { useState } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import SupplierTable from "./SupplierTable";
import { Box, Stack, Paper } from "@mui/material";
import { useSelector } from 'react-redux'
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import SupplierCard from "./SupplierCard";

//FUNCTION
const Supplier = () => {
    //#region code
    const open = useSelector((state) => state.sideExpand.open);

    const [isCreate, setIsCreate] = React.useState(false);
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [isEdit, setIsEdit] = useState(false);

    const handleClickOpen = () => {
        setIsCreate(true);
    };

    const handleEdit = (data) => {
        setEditData(data);
        setIsEdit(true);
        handleClickOpen();
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '100%',
                            marginLeft: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb: 0,
                            pt: 0,
                        }}
                        elevation={0}
                    >
                        <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'SUPPLIER'} handlePopup={handlePopup} isEdit={isEdit} create={isCreate} />
                        
                        {isCreate &&
                            <SupplierCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
                        }
                        
                    </Paper>
                    <SupplierTable isChanged={isChanged} handleEdit={handleEdit} />
                </Stack>
            </Box>
        </>
    )
}
export default Supplier