import React, { useState, useEffect } from 'react';
import Header2 from '../../Components/header/Header2/Header2'
import Footer from '../../Components/Footer/Footer'
import MiniDrawer from '../../Components/sidebar/SideBar2'

import {
  Box,
  Paper,
  Typography,
  Card,
  Stack,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Tab,
  Tabs,
} from '@mui/material';
import { useSelector } from 'react-redux';
import http from '../../services/http';
import StateList from '../../Components/List/StateList';
import ProfilePicture from '../../Components/Assets/images/profilePicture.jpg';
import configServ from '../../services/config';
import { SessionVerificationAdmin } from '../../Components/SessionManagement/SessionManagementAdmin';
import ChangePassword from '../ChangePassword/ChangePassword';
import MyAccount from '../MyAccount/MyAccount';


function Profile() {
  //#region code
  const { user_id } = useSelector((state) => state.GlobalVariables);
  const open = useSelector((state) => state.sideExpand.open);
  const [formData, setFormData] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const submitHandler = (e) => {
    console.log(formData);
  };

  const fetchUser = async () => {
    const id = {
      user_id: user_id,
    };
    try {
      const result = await configServ.getAdminDetails(id);
      setFormData(result);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchUser();
  }, []);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <SessionVerificationAdmin />
        <Header2/>
        <MiniDrawer/>
      <Box
        sx={{
          marginLeft: {
            xs: 7,
            sm: 8,
          },
          ...(open && { marginLeft: 30 }),
          transition: '200ms',
          overflowX: 'hidden',
          minHeight: '77vh',
        }}
      >
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          textColor="primary"
          indicatorColor="primary"
          centered
          sx={{ marginBottom: 2 }}
        >
          <Tab label="My Account" />
          <Tab label="Change Password" />
        </Tabs>

        <TabPanel value={tabValue} index={0}>
          <Stack
            direction={{
              md: 'column',
              sm: 'column-reverse',
              xs: 'column-reverse',
            }}
            // justifyContent="center"
            // alignItems='center'
            // // alignItems={{ md: 'flex-start', sm: 'center', xs: 'center' }}
            // sx={{
            //   padding: 2,
            // }}
            // // spacing={2}
          >
           <MyAccount/>
          </Stack>
        </TabPanel>

        <TabPanel value={tabValue} index={1}>
          <Stack
            direction={{
              md: 'column',
              sm: 'column-reverse',
              xs: 'column-reverse',
            }}
            // justifyContent="center"
            // alignItems='center'
            // // alignItems={{ md: 'flex-start', sm: 'center', xs: 'center' }}
            // sx={{
            //   padding: 2,
            // }}
            // // spacing={2}
          >
            <ChangePassword/>
            {/* Change Password content */}
          </Stack>
        </TabPanel>
      </Box>
    </>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default Profile;
