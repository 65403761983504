import React, { useState, useEffect } from "react";
import MasterEnteryCard from '../Card/MaterEntryCards/MasterEntryCard';
import GroupTable from "../Card/MaterEntryCards/GroupTable";
import GroupCard from "../Card/MaterEntryCards/GroupCard";

import { Stack, Paper, Divider } from "@mui/material";
import configServ from "../../services/config";
import { useSelector } from "react-redux";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";

const Group = () => {
    //#region State Management
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [groupList, setGroupList] = useState([]);
    const [filterGroupList, setFilterGroupList] = useState([]);
    const [count, setCount] = useState(0);
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    //#endregion

    //#region Fetch Group Data
    const fetchGroupList = async () => {
        try {
            const res = await configServ.group({ company_id });
            if (res?.status === 200) {
                const groupData = res.result || [];
                setGroupList(groupData);
                setFilterGroupList(groupData);
                setCount(groupData.length);
            } else {
                setGroupList([]);
                setFilterGroupList([]);
                setCount(0);
            }
        } catch (err) {
            console.error("Error fetching group list:", err);
        }
    };

    useEffect(() => {
        fetchGroupList();
    }, [isChanged]); // Re-fetch when `isChanged` toggles
    //#endregion

    //#region Handlers
    const handleEdit = (data) => {
        setEditData(data);
        setIsCreate(true);
        setIsEdit(true);
    };

    const handlePopup = () => {
        setIsCreate((prev) => !prev);
        if (!isCreate) setIsEdit(false); // Reset edit mode when closing the form
    };
    //#endregion

    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{ width: '100%' }}
        >
            <Paper
                elevation={0}
                sx={{
                    width: '100%',
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    p: 3,
                }}
            >
                {/* Card Header Menu */}
                <CardHeadMenu
                    prefix="Na"
                    suffix="Na"
                    title="GROUP"
                    handlePopup={handlePopup}
                    isEdit={isEdit}
                    create={isCreate}
                />

                {/* Group Form */}
                {isCreate && (
                    <GroupCard
                        isChanged={isChanged}
                        setIsChanged={setIsChanged}
                        editData={editData}
                        isEdit={isEdit}
                    />
                )}

                <Divider sx={{ my: 2 }} />

                {/* Group Table */}
                <GroupTable
                    groupList={groupList}
                    filterGroupList={filterGroupList}
                    setFilterGroupList={setFilterGroupList}
                    count={count}
                    setCount={setCount}
                    handleEdit={handleEdit}
                />
            </Paper>
        </Stack>
    );
};

export default Group;
