import { Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useSelector } from 'react-redux';

// import Register from './pages/Register/Register';
import Home from './pages/homePage/Home';
import AdminLogin from './pages/loginPage/AdminLogin';
import MasterEntery from './pages/masterEntery/MasterEntry';
//nested
import Batch from './Components/masterEntry/Batch';
import Category from './Components/masterEntry/Category';
import Group from './Components/masterEntry/Group';
import Units from './Components/masterEntry/Units';
import Brand from './Components/masterEntry/Brand';
import HSN from './Components/masterEntry/HSN';
import Item from './Components/masterEntry/Item';
import Dashboard2 from './pages/dashboardPage/Dashboard2';
import Loader from './Components/Loader/Loader';
import Profile from './pages/Profile/Profile';
// import Profile from './pages/MyAccount/MyAccount';
import ChangePassword from './pages/ChangePassword/ChangePassword';
import Configuration from './pages/Configuration/Configuration';
import Customers from './pages/Customers/Customers';
import Supplier from './pages/Supplier/Supplier';
import UpdateProduct from './pages/UpdateProduct/UpdateProduct';
import UOP from './pages/UOP/UOP';
import DispatchDetails from './pages/DispatchDetails/DispatchDetails';
import AccountMaster from './pages/AccountMaster/AccountMaster';
import Transaction from './pages/Transaction/Transaction';
//nested
import DebitNote from './pages/Transaction/DebitNote/DebitNote';
import CreditNote from './pages/Transaction/CreditNote/CreditNote';
import PurchaseBill from './pages/Transaction/PurchaseBill/PurchaseBill';
import PurchaseOrder from './pages/Transaction/PurchaseOrder/PurchaseOrder';
import Payments from './pages/Transaction/Payments/Payments';
import CreateReceipts from './pages/Transaction/CreateReceipts/CreateReceipts';
import Report from './pages/Report/Report';
//nested
import SRS from './pages/Report/SRS/SRS';
import SRI from './pages/Report/SRI/SRI';
import CustomerReport from './pages/Report/CustomerReport/CustomerReport';
import OrderReport from './pages/Report/OrderReport/OrderReport'
import PurchaseReport from './pages/Report/PurchaseReport/PurchaseReport'
import Invoice from './pages/Invoice/Invoice';
import SalesOrder from './pages/SalesOrder/SalesOrder';
import ItemAdvance from './Components/masterEntry/ItemEditOptions/ItemAdvance';
import Location from './pages/Location/Location';
import Subscription from './pages/Subscription/Subscription';
import Catalog from './pages/Catalog/Catalog';
import LocationAdvance from './pages/Location/LocationAdvance/LocationAdvance';
import Welcome from './pages/Welcome/Welcome';
import SuperCategory from './pages/SuperCategory/SuperCategory';
import AdminPay from './pages/Transaction/Payments/AdminPay';

//Customer Rotes
import CustDashboard from './Customer/Pages/CustDashboard/CustDashboard';
import CustInvoice from './Customer/Pages/CustInvoice/CustInvoice';
import CustProfile from './Customer/Pages/CustProfile/CustProfile';
import CustChangePassword from './Customer/Pages/CustChangePassword/CustChangePassword';
import CustSalesSubscription from './Customer/Pages/CustSalesSubscription/CustSalesSubscription';
import CustPayment from './Customer/Pages/CustPayment/CustPayment';
import ForgetPassword from './pages/ForgetPassword/ForgetPassword';
import Notify from './Customer/Pages/Notify/Notify';
import Product from './Customer/Pages/Product/Product';
import CustCart from './Customer/Pages/CustCart/CustCart';
import ProductDetails from './Customer/Pages/Product/ProductDetails';
import CustPurchaseOrder from './Customer/Pages/CustPurchaseOrder/CustPurchaseOrder';
import Pay from './Customer/Pages/CustPayment/Pay';
import CityMaster from './pages/CityMaster/CityMaster';
import Registration from './pages/Registration/Registration';
import AccessMaster from './pages/AccessMaster/AccessMaster';
import ProductInfo from './pages/ProductInfo/ProductInfo';

//FUNCTION
function App() {
    const isLoaderActive = useSelector((state) => state.loader.isLoaderActive)

    return (
        <>
            {isLoaderActive && <Loader />}
            <Routes>
                <Route path='/admin-login' element={<AdminLogin />} />
                {/* <Route path='/register' element={<Register />} /> */}
                <Route path='/forgetPassword' element={<ForgetPassword />} />
                <Route path='/Dashboard' element={<Dashboard2 />} />
                <Route path='/customer-dashboard' element={<CustDashboard />} />
                <Route path='/master-entry' element={<MasterEntery />}>
                    <Route path='batch' element={<Batch />} />
                    <Route path='category' element={<Category />} />
                    <Route path='group' element={<Group />} />
                    <Route path='units' element={<Units />} />
                    <Route path='brand' element={<Brand />} />
                    <Route path='hsn' element={<HSN />} />
                    <Route path='item' element={<Item />} />
                </Route>
                <Route path='/profile' element={<Profile />} />
                <Route path='/change-password' element={<ChangePassword />} />
                <Route path='/configuration' element={<Configuration />} />
                <Route path='/registration' element={<Registration />} />
                <Route path='/customers' element={<Customers />} />
                <Route path='/accessMaster' element={<AccessMaster/>} />
                <Route path='/supplier' element={<Supplier />} />
                <Route path='/updateProduct' element={<UpdateProduct />} />
                <Route path='/unregister-online-payment' element={<UOP />} />
                <Route path='/dispatch-details' element={<DispatchDetails />} />
                <Route path='/account-master' element={<AccountMaster />} />
                <Route path='/transaction' element={<Transaction />}>
                    <Route path='debit-note' element={<DebitNote />} />
                    <Route path='credit-note' element={<CreditNote />} />
                    <Route path='purchase-bill' element={<PurchaseBill />} />
                    <Route path='purchase-order' element={<PurchaseOrder />} />
                    <Route path='payments' element={<Payments />} />
                    <Route path='create-receipts' element={<CreateReceipts />} />
                </Route>
                <Route path='/report' element={<Report />}>
                    <Route path='srs' element={<SRS />} />
                    <Route path='sri' element={<SRI />} />
                    <Route path='customer-report' element={<CustomerReport />} />
                    <Route path='order-report' element={<OrderReport />} />
                    <Route path='purchase-report' element={<PurchaseReport />} />
                </Route>
                <Route path='/invoice' element={<Invoice />} />
                <Route path='/sales-order' element={<SalesOrder />} />
                <Route path='/master-entry/item/advance' element={<ItemAdvance />} />
                <Route path='/location' element={<Location />} />
                <Route path='/location/advance' element={<LocationAdvance />} />
                <Route path='/subscription' element={<Subscription />} />
                <Route path='/catalog' element={<Catalog />} />
                <Route path='/super-category' element={<SuperCategory />} />
                <Route path='/transaction/payments/pay' element={<AdminPay />} />
                <Route path='/city' element={<CityMaster />} />


                {/* Customer Routes */}
                <Route path='/' element={<Welcome />} />
                <Route path='/login' element={<Home />} />
                <Route path='/customer-invoice' element={<CustInvoice />} />
                <Route path='/customer-payment' element={<CustPayment />} />
                <Route path='/customer-payment/pay' element={<Pay />} />
                <Route path='/customer-sales-subscription' element={<CustSalesSubscription />} />
                <Route path='/customer-profile' element={<CustProfile />} />
                <Route path='/customer-change-password' element={<CustChangePassword />} />
                <Route path='/customer-Notifications' element={<Notify />} />
                <Route path='/product' element={<Product />} />
                <Route path='/product-details' element={<ProductDetails />} />
                <Route path='/Purchase-Order' element={<CustPurchaseOrder />} />
                <Route path='/cart' element={<CustCart />} />

                {/* QR Routes */}
                <Route path='/product-info' element={<ProductInfo/>}/>
            </Routes>


        </>
    );
}

export default App;
