import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import configServ from '../../services/config';
import CustomerContact from '../../Components/Customers/CustomerContact';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  // DialogContentText,
  DialogActions,
  TextField,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormControlLabel,
  Checkbox,
  IconButton,
  Typography,
  Stack,
  Tooltip,
  Box,
  Paper,
  Grid,
} from '@mui/material'
import { motion } from 'framer-motion'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import ButtonMUI from '@mui/material/Button'
// import http from '../../services/http';
import axios from 'axios';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import { useSelector } from 'react-redux';

const CustomerCard = ({
  handleClose,
  open,
  isChanged,
  setIsChanged,
  editData,
  setEditData,
}) => {
  //#region code
  // const app = useSelector((state) => state.GlobalVariables.noRoomService);
  // const { company_id } = useSelector((state) => state.GlobalVariables);
  const { user_id, company_id } = useSelector((state) => state.GlobalVariables);

  const initialFormData = {
    openingamount: 0,
    status: true,
  };

  const [formData, setFormData] = useState(initialFormData);
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isDone, setIsDone] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openCustContact, setOpenCustContact] = useState(false);
  const [isShipToAddressSame, setIsShipToAddressSame] = useState(true);
  const [validationErrors, setValidationErrors] = useState({});
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState("");

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent("");
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const assignContactName = () => {
    if (!isDone) {
      setFormData((state) => ({
        ...state,
        contact_name: formData.fname || "",
      }));
    }
  };

  const handleSubmit = async () => {
    //e.preventDefault();
    const dataToSend = {
      ...formData,
      company_id: company_id,
      user_id: user_id,
    };
    if (formData.is_credit_days === false) {
      delete dataToSend.credit_period;
      delete dataToSend.credit_limit;
    }

    try {
      const error = handleFormValidation();
      if (error === false) {
        return false;
      }
      setValidationErrors({});
      if (!isEdit) {
        const result = await configServ.addCustomer(dataToSend);
        if (result.success === true) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog(result.message);
        }
      } 
      else {
        const result = await configServ.editCustomer(dataToSend);
        if (result.success === true) {
          setIsChanged(!isChanged);
          clear();
          handleOpenDialog(result.message);
        }
      }
    } 
    catch (err) {
      console.log(err);
    }
  };

  const fetchCounties = async () => {
    const res = await axios.get(
      "https://countriesnow.space/api/v0.1/countries/capital"
    );
    if (!res.error) {
      setCountryList(res.data.data);
      // console.log(res.data.data)
    }
  };

  const fetchStates = async () => {
    const res = await axios.post(
      "https://countriesnow.space/api/v0.1/countries/states",
      { country: `${formData.country}` }
    );
    if (!res.error) {
      setStateList(res.data.data.states);
      // console.log(res.data.data.states)
    }
  };

  useEffect(() => {
    fetchCounties();
  }, []);

  useEffect(() => {
    if (formData.country !== undefined) {
      fetchStates();
    }
  }, [formData]);

  useEffect(() => {
    if (Object.keys(editData).length !== 0) {
      setFormData(editData);
      setIsEdit(true);
      // console.log(Object.keys(editData))
    }
  }, [editData]);

  const clear = () => {
    setFormData(initialFormData);
    setEditData({});
    setIsDone(false);
    setIsEdit(false);
    setValidationErrors({});
  };

  const handleCustContactOpen = () => {
    setOpenCustContact(true);
  };
  const handleCustContactClose = () => {
    setOpenCustContact(false);
  };

  const handleFormValidation = () => {
    const errors = {};
    if (!formData.fname) {
      errors.fname = "required";
    }
    if (!formData.contact_name) {
      errors.contact_name = "required";
    }
    if (!formData.mobile) {
      errors.mobile = "required";
    }
    if (!formData.email) {
      errors.email = "required";
    }
    if (!formData.address) {
      errors.address = "required";
    }
    if (!formData.country) {
      errors.country = "required";
    }
    if (!formData.State) {
      errors.State = "required";
    }
    if (!formData.Pin) {
      errors.Pin = "required";
    }
    if (!formData.type) {
      errors.type = "required";
    }
    if (!formData.openingamount) {
      errors.openingamount = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <CustomerContact
        handleClose={handleCustContactClose}
        open={openCustContact}
        customer_id={formData.id}
        customer_code={formData.customer_code}
      />

      {/* <motion.h4 style={{ textAlign: 'center' }}
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1 }}
            >
                Add Category
            </motion.h4>
            <br /> */}

      {open && (
        <motion.div
          style={{
            width: "100%",
            backgroundColor: "#e3f3fc",
            marginTop: 20,
            padding: 20,
            borderRadius: "10px",
            border: "none",
          }}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.1, delay: 0.1 }}
        >
          <Grid
            container
            alignItems="flex-end"
            justifyContent="flex-start"
            spacing={1}
          >
            <Grid item xs={12} md={12} justifyContent={"flex-start"}>
              <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                Add Customer
              </Typography>
            </Grid>

            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Firm Name<span style={{color:'red'}}>*</span></span>}
                name="fname"
                onChange={handleChange}
                value={formData.fname || ""}
                error={!!validationErrors.fname}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Contact Name<span style={{color:'red'}}>*</span></span>}
                name="contact_name"
                onChange={handleChange}
                onClick={() => {
                  assignContactName();
                  setIsDone(true);
                }}
                value={formData.contact_name || ""}
                error={!!validationErrors.contact_name}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Mobile Number<span style={{color:'red'}}>*</span></span>}
                name="mobile"
                type="number"
                onChange={handleChange}
                value={formData.mobile || ""}
                error={!!validationErrors.mobile}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Email<span style={{color:'red'}}>*</span></span>}
                name="email"
                onChange={handleChange}
                value={formData.email || ""}
                error={!!validationErrors.email}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label={<span>Bill To Address<span style={{color:'red'}}>*</span></span>}
                name="address"
                onChange={handleChange}
                value={formData.address || ""}
                error={!!validationErrors.address}
                sx={{ backgroundColor: "white" }}

              />
              {/* <TextField
                                fullWidth
                                size='small'
                                label='Alternative Email'
                                name='alternate_email'
                                onChange={handleChange}
                                value={formData.alternate_email || ''}
                            /> */}
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel>Country<span style={{color:'red'}}>*</span></InputLabel>
                <Select
                  required
                  name="country"
                  label="select"
                  value={formData.country || ""}
                  onChange={handleChange}
                  error={!!validationErrors.country}
                  sx={{ backgroundColor: "white" }}

                >
                  {countryList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel>State<span style={{color:'red'}}>*</span></InputLabel>
                <Select
                  required
                  name="State"
                  label="select"
                  value={formData.State || ""}
                  onChange={handleChange}
                  error={!!validationErrors.State}
                  sx={{ backgroundColor: "white" }}

                >
                  {stateList.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.name}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={<span>Pin<span style={{color:'red'}}>*</span></span>}
                name="Pin"
                onChange={handleChange}
                value={formData.Pin || ""}
                error={!!validationErrors.Pin}
                sx={{ backgroundColor: "white" }}
              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControl fullWidth size="small">
                <InputLabel id="customer-type">Customer Type<span style={{color:'red'}}>*</span></InputLabel>
                <Select
                  labelId="customer-type"
                  label="Customer Type"
                  name="type"
                  value={formData.type || ""}
                  onChange={handleChange}
                  error={!!validationErrors.type}
                  sx={{ backgroundColor: "white" }}

                >
                  <MenuItem value={"Standard"}>Standard </MenuItem>
                  <MenuItem value={"Previleged"}>Previleged</MenuItem>
                  <MenuItem value={"Staff"}>Staff</MenuItem>
                  <MenuItem value={"Discount"}>Discount </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                type="number"
                size="small"
                label={<span>Opening Amount<span style={{color:'red'}}>*</span></span>}
                name="openingamount"
                onChange={handleChange}
                value={formData.openingamount || ""}
                defaultValue={0}
                error={!!validationErrors.openingamount}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label="PAN/NO"
                name="pan_no"
                onChange={handleChange}
                value={formData.pan_no || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                size="small"
                label="GSTIN/UIN"
                name="gstin"
                onChange={handleChange}
                value={formData.gstin || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <FormControlLabel
                control={<Checkbox />}
                name="is_credit_days"
                checked={Boolean(formData.is_credit_days)}
                onChange={handleChangeCheckbox}
                label="Check credit days"

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                label="Credit Period"
                size="small"
                name="credit_period"
                InputProps={{
                  endAdornment: "d",
                }}
                disabled={formData.is_credit_days === true ? false : true}
                onChange={handleChange}
                value={formData.credit_period || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>
              <TextField
                fullWidth
                label="Credit Limmit"
                size="small"
                name="credit_limit"
                disabled={formData.is_credit_days === true ? false : true}
                onChange={handleChange}
                value={formData.credit_limit || ""}
                sx={{ backgroundColor: "white" }}

              />
            </Grid>
            {/* <Grid item xs={12} sm={12} md={12} columnSpacing={0}>
                            <FormControlLabel 
                                control={<Checkbox />} 
                                name='isShipToAddressSame' 
                                checked={Boolean(isShipToAddressSame)} 
                                onChange={()=>setIsShipToAddressSame(!isShipToAddressSame)} 
                                label='Ship To Address same as Bill To Address'   
                                style={{fontWeight:'bolder'}}/>
                        </Grid>*/}

            <Grid item xs={4} sm={4} md={4} columnSpacing={0}>

              <FormControlLabel
                control={<Checkbox />}
                name="status"
                checked={Boolean(formData.status)}
                onChange={handleChangeCheckbox}
                label="Status"
                sx={{ fontWeight: "bold" }}
              />
            </Grid>


            <Grid container spacing={2} justifyContent="space-between" px={2}>
              <Grid item>
                <Button
                  variant="contained"
                  color="secondary"
                  size="small"
                  hidden={isEdit ? false : true}
                  onClick={handleCustContactOpen}
                >
                  Add Contact Details
                </Button>
              </Grid>
              <Grid
                item
                container
                justifyContent="flex-end"
                alignItems="center"
                spacing={2}
              >
                <Grid item>
                  <Button
                    onClick={clear}
                    variant="contained"
                    color='secondary'
                    startIcon={<RotateLeftIcon />}
                  >
                    Reset
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => {
                      handleSubmit();
                    }}
                    autoFocus
                    variant="contained"
                    startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                  >
                    {isEdit ? "Update" : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </motion.div>
      )}
    </>
  );
};

export default CustomerCard;
