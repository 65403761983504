
import { Stack, Typography, Grid, TextField } from '@mui/material';
import React from 'react';

function NotificationCard() {
  return (
    <>
    
    <Stack sx={{
        backgroundColor:'white',
        padding:2,
        mt: 2,
        borderRadius:1,
        gap:2
        }}
    >
        <Grid container spacing={2}>
            <Grid item xs={12} md={3}>
                <Typography>
                Email(cc)
                </Typography>
                <TextField name='Company_cc' fullWidth size='small' label="Enter address" variant="outlined" />
            </Grid>
            <Grid item xs={12} md={4}>
                <Typography>
                Message
                </Typography>
                <TextField
                fullWidth
                size='small'
                multiline
                label="Leave a comment here"
                variant="outlined"
                rows={3}
                />
            </Grid>
            <Grid item xs={12} md={12}>
                <Typography component={'p'} sx={{ color: 'blue' }}>
                    Note:<br/>
                1. If you don't want to send Company Creation/updation mail, uncheck the Send Notification checkbox.<br/>
                2. Email(cc) separate email IDs with a comma (,)
                </Typography>
            </Grid>
        </Grid>
    </Stack>
    
    </>
  )
}

export default NotificationCard