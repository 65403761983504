import React,{useState} from 'react'

import { BsPlusSquareFill, BsFillCalendar3EventFill, BsFillFileFill, BsXCircle } from "react-icons/bs";

import { Form, Container, Row, Col } from 'react-bootstrap';
import { RadioGroup, Radio, IconButton, Tooltip, Fade } from "@mui/material";
import MyFormControlLabel from '@mui/material/FormControlLabel';
import NoteCard from '../../NoteCards/NoteCard';
import { notInitialized } from 'react-redux/es/utils/useSyncExternalStore';

import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

function PurchaseBillCard({title}) {

    const [notes, setNotes] = useState([])

    const addNote = ()=>{
        setNotes((state)=>([
            ...state,
            {}
        ]))
        console.log(notes)
    }
    const deleteNote = (id)=>{
        let data = [...notes]
        data.splice(id,1)
        setNotes(data)
        console.log(notes)
    }


  return (
    <>
    
        <Row style={{ padding: 10 }}>

        <div className="d-flex justify-content-end" >
        <Col sm={1} style={{ marginRight: 10 }}>
                <Tooltip title='Reset' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton>
                        <RestartAltIcon fontSize='large' color='primary'/>
                    </IconButton>
                </Tooltip>
            </Col>
            <Col sm={1}>
                <Tooltip title='Save' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton>
                        <SaveIcon fontSize='large' color='success'/>
                    </IconButton>
                </Tooltip>
            </Col>
            <Col sm={1}>
                <Tooltip title='Close' placement='top' arrow TransitionComponent={Fade} TransitionProps={{ timeout: 300 }}>
                    <IconButton>
                        <CancelIcon fontSize='large' color='error'/>
                    </IconButton>
                </Tooltip>
            </Col>
        </div>
        </Row>

        <div style={{ width: "100%", backgroundColor: "#DBDFEA99", padding: 20, borderRadius:'10px', border:'none' }}>
        <Row >
                        <Col sm={3}>
                            <Form.Label>Supplier Name* </Form.Label>
                            <Form.Control type="" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Bill To Address*</Form.Label>
                            <Form.Control type="" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Ship To Address</Form.Label>
                            <Form.Control type="" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Invoice-Number(max 13 char)</Form.Label>
                            <Form.Control type="" />
                        </Col>

                    </Row>
                    <br/>
                    <Row>
                        <Col sm={3}>
                            <Form.Label>Bill Date*</Form.Label>
                            <Form.Control type="date" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Bill Due Date</Form.Label>
                            <Form.Control type="date" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Purchase Order No</Form.Label>
                            <Form.Control type="" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Supplier/ Ref. Name</Form.Label>
                            <Form.Control type="" />
                        </Col>

                    </Row>
                     <br/>
                    <Row>
                        <Col sm={3}>
                            <Form.Label>Payment Mode</Form.Label>
                            <Form.Control type="" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Delivery Note</Form.Label>
                            <Form.Control type="" />
                        </Col>
                        <Col sm={3}>
                            <Form.Label>Tax in </Form.Label>

                            <RadioGroup name="use-radio-group" defaultValue="first" row>
                                <MyFormControlLabel value="first" label="CGST + SGST" control={<Radio size='small' />} />
                                <MyFormControlLabel value="second" label="IGST" control={<Radio size='small' />} />
                            </RadioGroup>

                        </Col>
                        <Col sm={3}>
                            <Form.Label>Total Amounts</Form.Label><br />
                            <Form.Label>TotalNetAmount(₹ NaN)</Form.Label>
                        </Col>

                    </Row>
                </div><br />
                
                    <NoteCard noDel={true} addNote={addNote} deleteNote={deleteNote}/>
                    {
                        notes.map((item, index)=>{
                            return(
                                <NoteCard key={index} id={index} addNote={addNote} deleteNote={deleteNote} notes={notes}/>
                            )
                        })
                    }
    
    </>
  )
}

export default PurchaseBillCard