import React, { useEffect, useState } from "react";
import {
    Typography,
    Stack,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Box
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import configServ from "../../../../services/config";
// import AddItemImage from "../../../../Components/masterEntry/ItemEditOptions/AddItemImage";
import LocationImage from "./LocationImage";

const LocationImageList = ({ itemData, pagination = true, isEdited, setIsEdited }) => {

    const rowsPerPageOptions = [5, 10, 50];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [locationImageList, setLocationImageList] = useState([]);
    const [editData, setEditData] = useState({});
    const [openAddImage, setOpenAddImage] = useState(false)

    const handleCloseImage = () => {
        setOpenAddImage(false);
    };

    const handleOpenImage = () => {
        setOpenAddImage(true);
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };


    const fetchItemImage = async () => {
        try {
            const result = await configServ.getLocationImg({ "location_id": itemData.id })
            console.log(result.data)
            setLocationImageList(result.data)
        } catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchItemImage()
        console.log('change in itemlist',isEdited)
    }, [isEdited])


    const headers = ['IMAGE', 'ALT TEXT', 'STATUS', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, locationImageList.length - page * rowsPerPage);

    const paginatedData = pagination
        ? locationImageList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : locationImageList;

    const headerCellStyle = {
        fontSize: 13, // Adjust the font size as needed
        fontWeight: 'bold',
    };

    const handleEdit = (data)=>{
        setEditData(data)
        setOpenAddImage(true)
        // console.log(data)
    }


    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
            >
                {/* <AddItemImage itemData={itemData} handleClose={handleCloseImage} open={openAddImage} editData={editData} isEdited={isEdited} setIsEdited={setIsEdited} /> */}
                <LocationImage itemData={itemData} handleClose={handleCloseImage} open={openAddImage} editData={editData} isEdited={isEdited} setIsEdited={setIsEdited} />


                <TableContainer component={Paper} sx={{ px: 5, minWidth: '70vw' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}>
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>
                                        <img
                                            alt={row.alt}
                                            src={`data:image/png;base64,${row.image}`}
                                            width={100}
                                        />
                                    </TableCell>
                                    <TableCell>{row.alt}</TableCell>
                                    <TableCell>{row.status === 1 ? 'Active' : 'Inactive'}</TableCell>
                                    <TableCell>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                                            onClick={() => { {handleOpenImage(); handleEdit(row) } }}
                                        >
                                            <Tooltip title='Edit' arrow>
                                                <EditIcon />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={locationImageList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>

            </Stack>

        </>
    )
}
export default LocationImageList