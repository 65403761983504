import React from "react";
import Header2 from "../../Components/header/Header2/Header2";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import UOPTableCard from "../../Components/Card/UOPCards/UopTableCard";

import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from 'react-redux'
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

const UOP = () => {

    const open = useSelector((state) => state.sideExpand.open)


    return (
        <>
            <SessionVerificationAdmin/>
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb:5
                        }}
                    >
                        <Stack direction='column'justifyContent='flex-start' width='100%' my={2}>
                            <Typography fontSize={15} variant="body" fontWeight='bold'>Unregister Payment</Typography>
                            <Divider/>
                        </Stack>
                        <UOPTableCard/>
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}
export default UOP