import React, { useState } from 'react';
import { Button, ButtonGroup, IconButton, Grid, Box, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import AddIcon from '@mui/icons-material/Add';
import { motion } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import { changeNoRoomService } from '../../../redux/reducers/GlobalVariables';

//FUNCTION
const CardHeadMenu = ({ prefix, suffix, title, handleReset, handleSave, handlePopup, isEdit, handleOTI, create }) => {
    const dispatch = useDispatch();
    const app = useSelector((state) => state.GlobalVariables.noRoomService);

    const handleApplication = (params) => {
        dispatch(changeNoRoomService(params));
    }

    return (
        <Grid container alignItems="center" style={{ paddingTop: 10 }} spacing={2}>
            <Grid item xs={12} md={4}>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                    <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={app}
                        label="Application"
                        onChange={(e) => handleApplication(e.target.value)}
                    >
                        <MenuItem value="">
                            <em>None</em>
                        </MenuItem>
                        <MenuItem value={'dcrm'}>DCRM</MenuItem>
                        <MenuItem value={'noroom'}>NoRoom</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} md={4}>
                <motion.h4
                    style={{
                        textAlign: 'center',
                        fontWeight: 'bolder', // Add the fontWeight property to make the text bolder
                        fontSize: '24px', // Add the fontSize property to increase the text size
                        color: '#1e61b4',
                    }}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1 }}
                >
                    {title}
                </motion.h4>
            </Grid>
            <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color={!create ? "primary" : "error"} size='large' onClick={handlePopup} startIcon={!create ? <AddIcon /> : <CancelIcon />}>
                    {!create ? 'Add' : 'Close'}
                </Button>
            </Grid>
        </Grid>

    );
};

export default CardHeadMenu;
