import React, { useEffect, useState } from "react";
import {
    Typography,
    Stack,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Tooltip,
    Box
} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import configServ from "../../../services/config";
import AddItemImage from "./AddItemImage";

const ItemImageList = ({ itemData, pagination = true, isEdited, setIsEdited }) => {
    //#region code
    const rowsPerPageOptions = [5, 10, 50];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [sizeList, setSizeList] = useState([]);
    const [editData, setEditData] = useState({});
    const [openAddImage, setOpenAddImage] = useState(false)

    const handleCloseImage = () => {
        setOpenAddImage(false);
    };

    const handleOpenImage = () => {
        setOpenAddImage(true);
    };

    const fetchItemImage = async () => {
        try {
            const result = await configServ.get_item_img({ "product_id": itemData.id });
            setSizeList(result.data);
        }
        catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        fetchItemImage();
    }, [isEdited]);

    const headers = ['IMAGE', 'ALT TEXT', 'STATUS', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, sizeList.length - page * rowsPerPage);

    const paginatedData = pagination
        ? sizeList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : sizeList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: 'black', // Set the header background color to blue
        color: 'white',
    };

    const evenRowStyle = {
        background: 'white',
    };

    const oddRowStyle = {
        background: '#f5f5f5',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const handleEdit = (data) => {
        setEditData(data);
        setOpenAddImage(true);
    }
    //#endregion

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
                width='100%'
            >
                <AddItemImage itemData={itemData} handleClose={handleCloseImage} open={openAddImage} editData={editData} isEdited={isEdited} setIsEdited={setIsEdited} />

                <TableContainer component={Paper} sx={{ minWidth: '90vw' }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}
                                >
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>
                                        <img
                                            alt={row.alt}
                                            src={`data:image/png;base64,${row.image}`}
                                            width={100}
                                        />
                                    </TableCell>
                                    <TableCell>{row.alt}</TableCell>
                                    <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                                            onClick={() => { { handleOpenImage(); handleEdit(row) } }}
                                        >
                                            <Tooltip title='Edit' arrow>
                                                <EditIcon />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={sizeList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>

            </Stack>

        </>
    )
}
export default ItemImageList