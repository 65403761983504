import React, { useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Grid,
    Typography,
    Button,
    NativeSelect,
    InputLabel,
    TextField,
    InputAdornment,
    FormControl,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import configServ from '../../../services/config';
import EditIcon from '@mui/icons-material/Edit';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { useEffect } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { useSelector } from 'react-redux';
import { Stack } from 'react-bootstrap';
import AddLineItem from '../../masterEntry/ItemEditOptions/AddLineItem';

const ItemTableCard = ({ handleEdit, setIsChanged, isChanged, pagination = true }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [5, 10, 25];
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [itemList, setItemList] = useState([]);
    const [filterItemList, setFilterItemList] = useState([]);
    const [searchBy, setSearchBy] = useState('item_name');
    const [count, setCount] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openGenerateLineItems, setOpenGenerateLineItems] = useState(false);
    const [lineItem, setLineItem] = useState({});

    useEffect(() => {
        fetchItemList();
    }, [isChanged]);

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };

    const fetchItemList = async () => {
        try {
            const result = await configServ.getItemList({ "company_id": company_id });
            if (result.length > 0) {
                setItemList(result);
                setFilterItemList(result);
                setCount(result.length);
            }
            else {
                setCount(0);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const headers = ['ITEM NAME', 'ITEM CODE', 'UNIT', 'ACTUAL QTY', 'COST PRICE', 'STATUS', 'ACTION'];

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const paginatedData = pagination
        ? filterItemList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filterItemList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: 'black', // Set the header background color to blue
        color: 'white',
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };

    const FetchLocationName = ({ id }) => {
        const [loc, setLoc] = useState()

        useEffect(() => {
            if (id !== 0) {
                locName()
            }
        }, [])

        const locName = async () => {
            try {
                const result = await configServ.getLocationById({ "id": id })
                setLoc(result.location_name)
            } catch (err) {
                console.log(err)
            }
        }

        return (
            <TableCell>{loc || 'N/A'}</TableCell>
        )
    }

    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }


    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            console.log(itemList);
            let data = [];
            if (search === '') {
                data = itemList;
            }
            else if (searchBy === 'item_name') {
                data = itemList.filter(x => x.item_name.toLowerCase().includes(search.toLowerCase()))
            }
            else if (searchBy === 'item_code') {
                data = itemList.filter(x => x.item_code.toLowerCase().includes(search.toLowerCase()))
            }
            else if (searchBy === 'location_name') {
                data = itemList.filter(x => x.location_name.toLowerCase().includes(search.toLowerCase()))
            }
            setFilterItemList(data);
            setCount(data.length);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleOpenLineItems = () => {
        setOpenGenerateLineItems(true);
    };

    const handleCloseLineItems = () => {
        setOpenGenerateLineItems(false);
    };

    const handleLineItems = (params) => {
      try{
        setLineItem(params);
        handleOpenLineItems();
      }
      catch(e){
        console.log(e);
      }
    }
    
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <>
            <AddLineItem itemData={lineItem} handleClose={handleCloseLineItems} open={openGenerateLineItems} />
                <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Item List:{count}</Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={'contact_name'}
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'item_name'}>Item Name</option>
                                    <option value={'item_code'}>Item Code</option>
                                    <option value={'location_name'}>Location</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
                <TableContainer component={Paper} sx={{ px: 5 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>Sr. No.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}>
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>{row.item_name}</TableCell>
                                    <TableCell>{row.item_code}</TableCell>
                                    {/* <FetchLocationName id={row.location_id} /> */}
                                    <TableCell>{row.unit}</TableCell>
                                    <TableCell>{row.actualquantity}</TableCell>
                                    <TableCell>{row.costprice}</TableCell>
                                    <TableCell>{row.status === 1 ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}</TableCell>
                                    <TableCell>
                                        <div>
                                            <Tooltip title='Edit' placement='top' arrow>
                                                <IconButton onClick={() => { handleEdit(row); scrollToTop() }} sx={{'&hover':{backgroundColor:'#99999955'}}}>
                                                <EditIcon color='primary' />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title='Generate Line Item' placement='top' arrow>
                                                <IconButton onClick={()=>handleLineItems(row)} sx={{'&hover':{backgroundColor:'#99999955'}}}>
                                                <Inventory2Icon color='secondary'/>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                        
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    )}
                </TableContainer>
            </>
        );
    }
};

export default ItemTableCard;
