import React, { useEffect, useState } from "react";
import { Stack, Paper, Divider, Typography, RadioGroup, Button, Radio } from "@mui/material";
import PaymentTable from "../../../Components/Card/TransactionCards/Payments/PaymentTable";
import { useNavigate } from "react-router-dom";

const Payments = () => {

    const navigate = useNavigate()

    const goToPay = () => {
        navigate('pay')
      };

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
            >
                <Paper
                    sx={{
                        width: '90%',
                        margin: 5,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        pb: 5
                    }}
                >
                    <h4 style={{ textAlign: 'center' }} >Payments</h4>
                    <Stack
                        width='100%'
                        direction='row'
                        justifyContent='flex-start'
                    >
                        <Button
                            variant="contained"
                            onClick={goToPay}
                        >
                            Pay
                        </Button>
                    </Stack>
                    <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                        <Divider />
                    </Stack>

                    {/* <Divider /> */}
                    <Stack direction='column' justifyContent='flex-start' width='100%' >
                        <Typography fontSize={15} variant="body" fontWeight='bold'>Payment List</Typography>
                        <Divider />
                    </Stack>
                    <PaymentTable />
                </Paper>
            </Stack>

        </>
    )
}
export default Payments