import React,{useState, useEffect} from 'react'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import { Paper, Stack } from '@mui/material'
import axios from 'axios'

const AccountMasterCard = () => {

    const [formData, setFormData] = useState({})
    const [countryList, setCountryList] = useState([])
    const [stateList, setStateList] = useState([])

    const handleChange = (e)=>{
        const {name, value} = e.target
        setFormData((state)=>({
            ...state,
            [name]:value
        }))
    }

    const handleChangeCheckbox = (e)=>{
        const {name, checked} = e.target
        setFormData((state)=>({
            ...state,
            [name]:checked
        }))
    }

    const handleSubmit = (e)=>{
        e.preventDefault()
        console.log(formData)
    }


    const fetchCounties = async ()=>{
        const res = await axios.get('https://countriesnow.space/api/v0.1/countries/capital')
        if(!res.error){
            setCountryList(res.data.data)
            // console.log(res.data.data)
        }
    }

    const fetchStates = async ()=>{
        const res = await axios.post('https://countriesnow.space/api/v0.1/countries/states',{"country":`${formData.country}`})
        if(!res.error){
            setStateList(res.data.data.states)
            // console.log(res.data.data.states)
        }
    }

    useEffect(()=>{
        fetchCounties()
    },[])

    useEffect(()=>{ 
        if(formData.country !== undefined){
            fetchStates()
        }
    },[formData])

    return (
        <>

                <div style={{ width: "100%", backgroundColor: "#DBDFEA99",padding:20, borderRadius:'10px', border:'none' }}>
                        <Row>
                            <Col sm={3}>
                                <Form.Label>Account Name*</Form.Label>
                                <Form.Control type="" placeholder='SBI' />
                            </Col>
                            <Col sm={3}>
                                <Form.Label>Account Classified*</Form.Label>
                                <Form.Select >
                                    <option>select</option>
                                    <option>Bank A/C</option>
                                    <option>Cash in hand</option>
                                    <option>Income</option>
                                    <option>Direct Expense</option>
                                    <option>Indirect Expense</option>
                                    <option>Loan</option>
                                    <option>Supplier</option>
                                    <option>Duties & Taxes</option>
                                </Form.Select>
                            </Col>

                            <Col sm={3}>
                                <Form.Label>Opening Blance*</Form.Label>
                                <Form.Control type="" placeholder='Item Code' />
                            </Col>
                            <Col sm={3}>
                                <Form.Label>Contact Name*</Form.Label>
                                <Form.Control type="" placeholder='Contact Name' />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={4}>
                                <Form.Label>Contact Address*</Form.Label>
                                <Form.Control type="" placeholder='Contact Address*' />
                            </Col>
                            <Col sm={3}>
                                <Form.Label>Country*</Form.Label>
                                <Form.Select name='country' value={formData.country} onChange={handleChange} >
                                <option>Select Country</option>
                                {countryList.map((item,index)=>{
                                    return(
                                        <option key={index} value={item.name}>{item.name}</option>
                                    )
                                })}
                                </Form.Select>
                            </Col>
                            <Col sm={3}>
                                <Form.Label>State*</Form.Label>
                                <Form.Select name='state' value={formData.state} onChange={handleChange}>
                                    <option>Select State</option>
                                    {stateList.map((item,index)=>{
                                        return(
                                            <option key={index} value={item.name}>{item.name}</option>
                                        )
                                    })}
                                </Form.Select>
                            </Col>
                            <Col sm={2}>
                                <Form.Label>Contact Number</Form.Label>
                                <Form.Control type="" placeholder='Contact Number' />
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col sm={3}>
                                <Form.Label>Email Id</Form.Label>
                                <Form.Control type="" placeholder='Email Id' />
                            </Col>
                            <Col sm={5}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control type="" placeholder=' Enter description' />
                            </Col>
                            <Col sm={2}>
                            
                                <Form.Label style={{marginTop:40}}> <Form.Check inline aria-label="option 1" size="100%" />Status</Form.Label><br />
                            </Col>
                            <Col sm={1}>
                                <br />
                                <Button variant="primary" style={{marginTop:10}}>Submit</Button>
                            </Col>
                        </Row>
                </div>

        </>
    )
}

export default AccountMasterCard