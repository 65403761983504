import React, { useCallback, useState } from "react";
import Category from "../../Components/masterEntry/Category";
import Batch from "../../Components/masterEntry/Group";
import Units from "../../Components/masterEntry/Units";
import ConfigurationCard from '../../Components/Card/ConfigurationCards/ConfigurationCard'
import Header2 from "../../Components/header/Header2/Header2";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import { Box, Stack, Paper, Divider, Typography } from "@mui/material";
import { useSelector } from 'react-redux'
import ConfigurationTable from "../../Components/Table/configurations/ConfigurationTable";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";

const Configuration = () => {
    const [isChanged, setIsChanged] = useState(false);
    const [editData, setEditData] = useState({});
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const open = useSelector((state) => state.sideExpand.open);

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    const handleEdit = (data) => {
        setEditData(data)
        setIsCreate(true);
        setIsEdit(true);
    };

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Box
                        sx={{
                            width: '100%',
                            marginLeft: 5,
                            marginRight: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            border:'none'
                        }}
                    >
                        <CardHeadMenu
                            prefix={"Na"}
                            suffix={"Na"}
                            title={"CONFIGURATION"}
                            handlePopup={handlePopup}
                            isEdit={isEdit}
                            create={isCreate}
                        />

                        {
                            isCreate &&
                            <ConfigurationCard isEdit={isEdit} setIsEdit={setIsEdit} isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
                        }

                    </Box>
                    <ConfigurationTable isChanged={isChanged} handleEdit={handleEdit} />
                </Stack>

            </Box>
        </>
    )
}
export default Configuration