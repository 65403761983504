import React, { useState, useEffect } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import Footer from "../../Components/Footer/Footer";
import { useSelector } from 'react-redux';
import { motion } from 'framer-motion'
import configServ from "../../services/config";
import SubscriptionTable from "./SubscriptionTable";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

const Subscription = () => {
    //#region code
    const { user_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [tableData, setTableData] = useState([]);

    const fetchTableData = async () => {
        try {
            const result = await configServ.getsubs({ "user_id": user_id })
            console.log(result)
            setTableData(result)
        }
        catch (err) {
            console.log(err)
        }
    }

    const rows = [
        {
            id: 1,
            category_name: 'Primary',
            description: '',
            derive_unit: '',
            conversion_factor: '',
            status: true
        }
    ]
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh',
                backgroundColor: '#EEEEEE77'
            }}>
                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb: 5
                        }}
                        elevation={0}
                    >
                        <motion.h4 style={{ textAlign: 'center' }}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.1 }}
                        >
                            Subscription List
                        </motion.h4>
                        <Stack direction='column' justifyContent='flex-start' width='100%' my={1} mb={2}>
                            <Divider />
                        </Stack>
                        {/* {rows !== undefined && (<CustSalesSubsTable rows={tableData}/>)} */}
                        <SubscriptionTable />
                    </Paper>
                </Stack>
            </Box>
            <Footer />
        </>
    )
};
export default Subscription;