import React,{useEffect, useState} from "react";
// import MasterEnteryCard from '../../Components/Card/MaterEntryCards/MasterEntryCard'
// import TableCard from "../../Components/Card/MaterEntryCards/TableCard";
import HsnTableCard from "../../../Components/Card/MaterEntryCards/HsnTableCard";
import TableCard from "../../../Components/Card/NoteCards/TableCard";
import { BsPlusSquareFill, BsFillCalendar3EventFill, BsFillFileFill, BsXCircle } from "react-icons/bs";

import { Form, Container, Row, Col } from 'react-bootstrap';

import { Stack, Paper, Divider, Typography, RadioGroup, Button, Radio } from "@mui/material";
import MyFormControlLabel from '@mui/material/FormControlLabel';

import NoteCard from "../../../Components/Card/NoteCards/NoteCard";
import NoteCardMain from "../../../Components/Card/NoteCards/NoteCardMain";

 const CreditNote = () =>{


    // useEffect(()=>{
    //     forLoop()
    // })

    return(
        <>


                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb:5
                        }}
                    >
                            <h4 style={{ textAlign: 'center' }} >Add Credit Note</h4>
                            <br/>
                        
                <Container>
                    <NoteCardMain title={'Credit'}/>
                </Container>


                        <Divider />
                        <br />
                        <Stack direction='column'justifyContent='flex-start' width='100%' my={2}>
                            {/* <Typography fontSize={15} variant="body" fontWeight='bold'>HSN List</Typography> */}
                            <Divider/>
                        </Stack>
                        <TableCard title={'Credit'}/>
                    </Paper>
                </Stack>

        </>
    )
 }
 export default CreditNote