import React, { useState, useEffect } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import CustomersTableCard from "../../Components/Card/Customers/CustomersTableCard";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import { Box, Stack, Paper, Divider, Typography, Button, Grid } from "@mui/material";
import { useSelector } from 'react-redux';
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import CustomerCard from "./CustomerCard";

const Customers = () => {
    //#region code
    const open = useSelector((state) => state.sideExpand.open)
    const [openDialog, setOpenDialog] = React.useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})

    const handleClickOpen = () => {
        setOpenDialog(!openDialog);
    };
    const handleClose = () => {
        setOpenDialog(false);
    };

    const handleEdit = (data) => {
        setEditData(data);
        setOpenDialog(true);
    }
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            //marginLeft: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            //paddingLeft: 3,
                            pb: 5
                        }}
                        elevation={0}
                    >
                        {/* <Grid container mr={{xs:0,md:16}}> */}
                        <CardHeadMenu prefix={'Na'} suffix={'Na'} title={'CUSTOMERS'} handlePopup={handleClickOpen} create={openDialog} />
                        {/* </Grid> */}

                        <CustomerCard handleClose={handleClose} open={openDialog} isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
                        <CustomersTableCard handleEdit={handleEdit} isChanged={isChanged} />
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}
export default Customers