import React, { useEffect, useState } from 'react'

import DashboardCard from '../../../Components/Card/DashboardCard'
// import MiniDrawer from '../../Components/sidebar/SideBar2'
// import Header2 from '../../../Components/header/Header2/Header2'
import Footer from '../../../Components/Footer/Footer'
import http from '../../../services/http'
import CustomerSideBar from '../../../Components/sidebar/CustomerSideBar'

import { useSelector } from 'react-redux'

import { Box, Button, Stack, Typography } from '@mui/material'
import { motion, AnimatePresence } from 'framer-motion'
import { useJwt } from 'react-jwt'
import { SessionVerification } from '../../../Components/SessionManagement/SessionManagement'
import CustHeader from '../../../Components/header/Header2/CustHeader'
import RecentlyAddedItem from '../../Component/RecentlyAdded/RecentlyAddedItem'
import LatestTransaction from '../../Component/RecentlyAdded/LatestTransaction'
import configServ from '../../../services/config'

// icon 
import DescriptionIcon from '@mui/icons-material/Description';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import MoneyOffIcon from '@mui/icons-material/MoneyOff';

const CustDashboard = () => {
    //#region code
    const { id, user_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    // const [userType, setUserType] = useState('customer')
    const [summary, setSummary] = useState({
        invoiceCount: "",
        paidCount: "",
        unpaidCount: "",
        PendingAmount: ""
    })

    useEffect(() => {
        const getUser = async () => {
            const res = await configServ.profiledetail({ "user_id": user_id })
            if (res.success) {
                // console.log(res.success)
            } else {
                console.error('Error while fetchiing user data')
            }
        }
        const getDashboardSummary = async () => {
            try {
                const res = await configServ.dashboardsummary({ "user_id": id });
                setSummary({
                    invoiceCount: res.InvoiceCount,
                    paidCount: res.PaidCount,
                    unpaidCount: res.UnpaidCount,
                    PendingAmount: res.PendingAmount
                })
            } catch (err) {
                console.log(err)
            }
        }
        getUser()
        getDashboardSummary()
    }, []);

    const cardData = [
        {
            title: 'INVOICES',
            number: summary.invoiceCount || 0,
            icon: <DescriptionIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'PAID',
            number: summary.paidCount || 0,
            icon: <AttachMoneyIcon sx={{ color: '#fb6340' }} />
        },
        {
            title: 'UNPAID',
            number: summary.unpaidCount,
            amount: parseFloat(summary.PendingAmount || 0).toFixed(2),
            icon: <MoneyOffIcon sx={{ color: '#fb6340' }} />
        },
    ]
    //#endregion

    return (
        <>
            <SessionVerification />
            <CustHeader />
            <CustomerSideBar />
            <Box sx={{
                // marginTop:{
                //     xs:7,
                //     sm:8
                // },
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh',
                backgroundColor: '#EEEEEE77'
            }}
                component={motion.div}
                initial={{
                    opacity: 0
                }}
                animate={{
                    opacity: 1
                }}
                transition={{
                    duration: 0.2
                }}
            >

                <Box sx={{
                    backgroundColor: '#6270e4dd',
                    width: "100%",
                    // height:'200vh'
                }}>
                    <Stack
                        direction='row'
                        justifyContent='center'
                        alignItems='center'
                        spacing={{
                            xs: 0,
                            sm: 0,
                            md: 4
                        }}
                        gap={{
                            xs: 2,
                            sm: 2,
                            md: 5
                        }}
                        py={5}
                        sx={{
                            flexDirection: {
                                xs: 'column',
                                sm: 'column',
                                md: 'row'
                            },
                        }}
                    >
                        {cardData.map((item, index) => {
                            return (
                                <DashboardCard key={index} data={item} />
                            )
                        })}
                    </Stack>
                </Box>
                <Stack direction={{ sx: 'column', md: 'row' }}>
                    <RecentlyAddedItem />
                    <LatestTransaction />
                </Stack>
            </Box>
            <Footer />
        </>
    )
}

export default CustDashboard