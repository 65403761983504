function checkUndefinedReturnNull(value){
    return value !== undefined? value : null;
}

function checkTextUndefined(value){
    return value !== undefined? value : '';
}

function checkTextNull(value){
    return value !== null? value : '';
}

function checkTextNullAndUndefined(value){
    return value !== undefined && value !== null? value : '';
}

const transformToDate = (value) => {
    if(value){
        const date = new Date(value);
        return date;
    }else{
        return ''
    }
}

//when value = "2023-11-30"
const transformToDate2 = (value) => {
    if (value) {
      const date = new Date(value);
      const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
      return date.toLocaleDateString(undefined, options);
    } else {
      return '';
    }
  };

const CheckAndReturn = {
    checkUndefinedReturnNull,
    checkTextUndefined,
    checkTextNull,
    checkTextNullAndUndefined,
    transformToDate,
    transformToDate2,
}

export default CheckAndReturn;