import React, { useEffect, useState } from "react";
import ItemTableCard from "../../Components/Card/MaterEntryCards/ItemTableCard";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Form } from "react-bootstrap";
import ButtonB from "react-bootstrap/Button";
import {
  Box,
  RadioGroup,
  Select,
  MenuItem,
  TextField,
  Tooltip,
  FormControl,
  InputLabel,
  Grid,
  Autocomplete,
} from "@mui/material";
import MyFormControlLabel from "@mui/material/FormControlLabel";
import { Radio } from "@mui/material";
import configServ from "../../services/config";
import { Input, Button } from "@mui/joy";
import AddIcon from "@mui/icons-material/Add";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Stack, Paper, Divider, Typography } from "@mui/material";
import { motion } from "framer-motion";
import AddItemImage from "./ItemEditOptions/AddItemImage";
import AddItemSize from "./ItemEditOptions/AddItemSize";
import { useNavigate } from "react-router-dom";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import SaveIcon from "@mui/icons-material/Save";
import UpdateIcon from "@mui/icons-material/Update";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CancelIcon from "@mui/icons-material/Cancel";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import Footer from "../Footer/Footer";
import AlertDialog from "../AlertDialog/AlertDialog";

const Items = () => {
  //#region code
  const { user_id, company_id, noRoomService } = useSelector((state) => state.GlobalVariables);
  const dispatch = useDispatch();
  const formDataInitial = {
    item_type: "single",
    SubscriptionStatus: "Monthly",
    openingquantity: "0",
    discount: "0",
    tax_rate: "0",
    status: true,
  };
  const [formData, setFormData] = useState(formDataInitial);
  const [categoryList, setCategoryList] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [superCategoryList, setSuperCategoryList] = useState([]);
  const [groupList, setGroupList] = useState([]);
  const [unitList, setUnitList] = useState([]);
  const [hsnList, setHsnList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [companyType, setCompanyType] = useState("product");
  const [hsn, setHsn] = useState("");
  const { company_type } = useSelector((state) => state.admin);

  const [tableData, setTableData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const [openAddImage, setOpenAddImage] = useState(false);
  const [openAddSize, setOpenAddSize] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [isItemUpdated, setIsItemUpdated] = useState(false);
  const navigate = useNavigate();
  const [associatedItem, setAssociatedItem] = useState([
    {
      id: 1,
      item_id: "",
      qty: "",
    },
  ]);
  const [isCreate, setIsCreate] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  const handlePopup = () => {
    try {
      setValidationErrors({})
      setIsCreate(!isCreate);
    } catch (error) {
      console.log(error);
    }
  };

  const addAssociatedItem = () => {
    setAssociatedItem((state) => [
      ...state,
      {
        id: associatedItem.length + 1,
        item_id: "",
        qty: "",
      },
    ]);
  };
  const delAssociatedItem = (id) => {
    const updatedItemList = associatedItem.filter((item) => item.id !== id);
    setAssociatedItem(updatedItemList);
  };
  const handleChangeAssociatedItem = (id, event) => {
    const { name, value } = event.target;
    setAssociatedItem((state) =>
      state.map((item) => (item.id === id ? { ...item, [name]: value } : item))
    );
    if (name === "item_id") {
      const selectedItem = tableData.filter((item) => value === item.id);
      setAssociatedItem((state) =>
        state.map((item) =>
          item.id === id
            ? { ...item, item_name: selectedItem[0].item_name }
            : item
        )
      );
    }
  };

  const fetchItemList = async () => {
    try {
      const result = await configServ.getItemList({
        company_id: company_id,
      });
      setTableData(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchSuperCategory = async () => {
    try {
      const result = await configServ.getSuperCategory();
      setSuperCategoryList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const checkHandler = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const fetchCategory = async () => {
    try {
      //const result = await configServ.getcategoryByBrand({ "brand_id": formData.brand_id });
      const result = await configServ.getcategory({
        company_id: company_id,
      });
      const categoryResponse = result.filter(x => x.status === 1);
      categoryResponse.map((item) => {
        if (item.isPrimary === 1) {
          setFormData((prev) => ({
            ...prev,
            category_id: item.categoryid.toString()
          }));
        }
      });
      setCategoryList(categoryResponse);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchBrand = async () => {
    try {
      const result = await configServ.brand({
        company_id: company_id,
      });
      const brandResponse = result.filter(x => x.status === 1);
      brandResponse.map((item) => {
        if (item.isPrimary === 1) {
          setFormData((prev) => ({
            ...prev,
            brand_id: item.id.toString()
          }));
        }
      });
      setBrandList(brandResponse);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchGroup = async () => {
    try {
      const res = await configServ.groupByCategory({
        category_id: formData.category_id,
      });

      if (res.status === 200) {

        const groupResponse = res.result.filter(x => x.status === 1);
        groupResponse.map((item) => {
          if (item.isPrimary === 1) {
            setFormData((prev) => ({
              ...prev,
              group_id: item.id
            }));
          }
        });
        setGroupList(groupResponse);
      }
      else if (res.status === 404) {
        const res = await configServ.group({
          company_id: company_id,
        });
        if (res.status === 200) {
          const groupResponse = res.result.filter(x => x.status === 1);
          groupResponse.map((item) => {
            if (item.isPrimary === 1) {
              setFormData((prev) => ({
                ...prev,
                group_id: item.id
              }));
            }
          });
          setGroupList(groupResponse);
        }
        else {
          setGroupList([]);
        }
      }
      else {
        setGroupList([]);
      }
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchLocation = async () => {
    try {
      const result = await configServ.getLocation({
        company_id: company_id,
      });
      setLocationList(result);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchUnit = async () => {
    try {
      const result = await configServ.unit({
        company_id: company_id
      });
      const unitResponse = result.filter(x => x.status === 1);
      unitResponse.map((item) => {
        if (item.isPrimary === 1) {
          setFormData((prev) => ({
            ...prev,
            unit: item.units_name
          }));
        }
      });
      setUnitList(unitResponse);
    }
    catch (error) {
      console.log(error);
    }
  };

  const fetchGstDetails = async () => {
    try {
      const result = await configServ.getGstDetails();
      setHsnList(result);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchHsnCode = (data) => {
    data.map((item) => {
      setHsnList((state) => [
        ...state,
        {
          label: item.hsncode,
          id: item.pk,
        },
      ]);
    });
  };

  useEffect(() => {
    fetchSuperCategory();
    fetchCategory();
    fetchBrand();
    //fetchGroup()
    fetchUnit();
    fetchGstDetails();
    fetchLocation();
    setValidationErrors({});
  }, []);

  // useEffect(()=>{
  //     if(formData.brand_id){
  //         fetchCategory();
  //     }
  // },[formData.brand_id]);

  useEffect(() => {
    if (formData.category_id) {
      fetchGroup();
    }
  }, [formData.category_id]);

  useEffect(() => {
    if (formData.item_type === "composite") {
      fetchItemList();
    }
  }, [formData.item_type]);

  // const itemUpdated = () => {
  //     setIsItemUpdated(!isItemUpdated)
  // }

  const handleEdit = async (data) => {
    setIsCreate(true);
    setValidationErrors({});
    setCompanyType(data.type);
    setHsn(data.hsncode);

    const updatedFormData = { ...data };

    setFormData(updatedFormData);

    setIsEdit(true);

    if (data.isItemAssociated === 1) {
      fetchAssociatedItem(data.id);
    }
  };

  const fetchAssociatedItem = async (id) => {
    try {
      const result = await configServ.getAssociateItem({
        associatedWith_id: id,
      });
      setAssociatedItem(result);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    const itemData = {
      ...formData,
      items: associatedItem,
      hsncode: hsn,
      type: companyType,
      company_id: company_id,
      user_id: user_id,
    };
    const error = handleFormValidation();
    if (error === false) {
      return false;
    }
    setValidationErrors({});
    if (!isEdit) {
      try {
        const result = await configServ.createItem(itemData);
        if (result.success) {
          handleOpenDialog("Item added successfully");
          clear();
          //itemUpdated();
          setIsChanged(!isChanged);
        }
      } 
      catch (error) {
        console.log(error);
      }
    } 
    else {
      try {
        const result = await configServ.updateItem(itemData);
        if (result.success) {
          handleOpenDialog("Item Updated successfully");

          clear();
          //itemUpdated();
          setIsChanged(!isChanged);
        }
      } 
      catch (error) {
        console.log(error);
      }
    }
  };

  const clear = () => {
    setFormData(formDataInitial);
    setIsEdit(false);
    setHsn("");
    setCompanyType("");
    setValidationErrors({});
    setAssociatedItem([
      {
        id: 1,
        item_id: "",
        qty: "",
      },
    ]);
  };

  const handleCloseImage = () => {
    setOpenAddImage(false);
  };

  const handleOpenImage = () => {
    setOpenAddImage(true);
  };

  const handleCloseSize = () => {
    setOpenAddSize(false);
  };

  const handleOpenSize = () => {
    setOpenAddSize(true);
  };

  const formArrayZeroToN = (number) => {
    if (
      typeof number !== "number" ||
      !Number.isInteger(number) ||
      number <= 0
    ) {
      throw new Error("Input must be a positive integer");
    }

    return Array.from({ length: number }, (_, index) => index + 1);
  };

  const requiredLabel = {
    content: "*",
    color: "red" /* Change the color as needed */,
  };

  const Label = {
    color: "black" /* Change the color as needed */,
  };

  const handleHSN = (selectedhsn) => {
    if (selectedhsn === null) {
      setHsn("");
      setFormData((state) => ({
        ...state,
        hsncode: "",
        tax_rate: "",
      }));
    }
    else {
      const hsn = hsnList.filter(
        (x) => x.hsncode === selectedhsn.hsncode.toString()
      )[0];
      setHsn(hsn.hsncode);
      setFormData((state) => ({
        ...state,
        hsncode: hsn.hsncode,
        tax_rate: hsn.GSTRate,
      }));
    }
  };

  const handleFormValidation = () => {
    const errors = {};
    if (!formData.brand_id) {
      errors.brand_id = "required";
    }
    if (!formData.category_id) {
      errors.category_id = "required";
    }
    if (!formData.group_id) {
      errors.group_id = "required";
    }
    if (!formData.location_id) {
      errors.location_id = "required";
    }
    if (!formData.item_name) {
      errors.item_name = "required";
    }
    if (!formData.item_code) {
      errors.item_code = "required";
    }
    if (!formData.hsncode) {
      errors.hsncode = "required";
    }
    if (!formData.unit) {
      errors.unit = "required";
    }
    if (!formData.openingquantity) {
      errors.openingquantity = "required";
    }
    if (!formData.discount.toString()) {
      errors.discount = "required";
    }
    if (!formData.tax_rate) {
      errors.tax_rate = "required";
    }
    if (!formData.rate_unit) {
      errors.rate_unit = "required";
    }
    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handleClose = (params) => {
    clear();
    setIsCreate(false);
  };
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="IMPORTANT NOTE"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            margin: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 3,
          }}
        >
          <CardHeadMenu
            prefix={"Na"}
            suffix={"Na"}
            title={"ITEMS"}
            handlePopup={handlePopup}
            isEdit={isEdit}
            create={isCreate}
          />

          {isCreate === true && (
            <Container>
              <Grid
                container
                alignItems="flex-end"
                justifyContent="flex-start"
                spacing={1}
              >
                <Grid item xs={5} md={2}>
                  <h4>Business Type</h4>
                </Grid>
                <Grid item xs={6} md={3}>
                  <RadioGroup
                    name="use-radio-group"
                    defaultValue="first"
                    row
                    onChange={(e) => {
                      setCompanyType(e.target.value);
                    }}
                  >
                    <MyFormControlLabel
                      value="sales"
                      label="Sales"
                      control={<Radio size="small" />}
                      checked={
                        company_type === "product"
                          ? true
                          : false
                      }
                      disabled={
                        company_type === "service"
                      }
                    />
                    <MyFormControlLabel
                      value="service"
                      label="Service"
                      control={<Radio size="small" />}
                      checked={
                        company_type === "service"
                          ? true
                          : false
                      }
                      disabled={
                        company_type === "product"
                      }
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={6} md={2}>
                  {/* <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <InputLabel id="demo-simple-select-standard-label">Application</InputLabel>
                    <Select
                      labelId="demo-simple-select-standard-label"
                      id="demo-simple-select-standard"
                      //value={age}
                      //onChange={handleChange}
                      label="Application"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={'dcrm'}>DCRM</MenuItem>
                      <MenuItem value={'noroom'}>NoRoom</MenuItem>
                    </Select>
                  </FormControl> */}
                </Grid>
              </Grid>
              <motion.div
                style={{
                  width: "100%",
                  backgroundColor: "#e3f3fc",
                  padding: 20,
                  borderRadius: "10px",
                  border: "none",
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
              >
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                      Item Detail
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      id="brand"
                      options={brandList}
                      getOptionLabel={(option) => option.brand_name}
                      value={
                        brandList.find(
                          (item) => item.id.toString() === formData.brand_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "brand_id",
                            value: newValue?.id.toString() || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="brand_id"
                          label="Brand*"
                          variant="outlined"
                          fullWidth
                          error={!!validationErrors.brand_id}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="category"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={categoryList}
                      getOptionLabel={(option) => option.categoryname}
                      value={
                        categoryList.find(
                          (item) =>
                            item.categoryid.toString() === formData.category_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "category_id",
                            value: newValue?.categoryid.toString() || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="category_id"
                          label="Category*"
                          variant="outlined"
                          fullWidth
                          error={!!validationErrors.category_id}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="group"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={groupList}
                      getOptionLabel={(option) => option.group_name}
                      value={
                        groupList.find(
                          (item) => item.id === formData.group_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "group_id",
                            value: newValue?.id || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="group_id"
                          label="Group*"
                          variant="outlined"
                          fullWidth
                          error={!!validationErrors.group_id}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="location"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={locationList}
                      getOptionLabel={(option) => option.location_name}
                      value={
                        locationList.find(
                          (item) => item.id === formData.location_id
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "location_id",
                            value: newValue?.id || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="location_id"
                          label="Location*"
                          variant="outlined"
                          fullWidth
                          error={!!validationErrors.location_id}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="item_name"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="item_name"
                      label="Item Name*"
                      variant="outlined"
                      fullWidth
                      value={formData.item_name || ""}
                      onChange={changeHandler}
                      error={!!validationErrors.item_name}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="item_code"
                      name="item_code"
                      label="Item Code*"
                      variant="outlined"
                      fullWidth
                      size="small"
                      value={formData.item_code || ""}
                      onChange={changeHandler}
                      error={!!validationErrors.item_code}
                      sx={{ backgroundColor: "white" }}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel htmlFor="itemType">Item Type</InputLabel>
                      <Select
                        id="itemType"
                        size="small"
                        sx={{ backgroundColor: "white" }}
                        name="item_type"
                        value={formData.item_type || ""}
                        onChange={changeHandler}
                        label="Item Type*"
                      >
                        <MenuItem value="single">Single</MenuItem>
                        <MenuItem value="composite">Composite</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="superCategory"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={superCategoryList}
                      getOptionLabel={(option) => option.name}
                      value={
                        superCategoryList.find(
                          (item) => item.id === formData.superCategory
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "superCategory",
                            value: newValue?.id || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="superCategory"
                          label="Super Category"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="itemDescription"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="description"
                      label="Item Description"
                      variant="outlined"
                      fullWidth
                      multiline
                      rows={2}
                      value={formData.description || ""}
                      onChange={changeHandler}
                    />
                  </Grid>
                  <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                      Price Detail
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="hsncode"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={hsnList}
                      getOptionLabel={(option) => option.hsncode}
                      value={
                        hsnList.find(
                          (item) => item.hsncode === formData.hsncode
                        )
                      }
                      onChange={(event, newValue) => {
                          handleHSN(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="hsncode"
                          label="HSN/SAC*"
                          variant="outlined"
                          fullWidth
                          error={!!validationErrors.hsncode}
                        />
                      )}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                                            <TextField
                                                id="sku"
                                                name="sku"
                                                label="SKU"
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={formData.sku || ''}
                                                onChange={changeHandler}
                                                sx={{ backgroundColor: 'white' }}
                                            />
                                        </Grid> */}
                  <Grid item xs={12} md={3}>
                    <Autocomplete
                      id="unit"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      options={unitList}
                      getOptionLabel={(option) => option.units_name}
                      value={
                        unitList.find(
                          (item) => item.units_name === formData.unit
                        ) || null
                      }
                      onChange={(event, newValue) => {
                        changeHandler({
                          target: {
                            name: "unit",
                            value: newValue?.units_name || "",
                          },
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          name="unit"
                          label="Unit*"
                          variant="outlined"
                          fullWidth
                          error={!!validationErrors.unit}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="unitPrice"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="rate_unit"
                      label="Unit Price*"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={formData.rate_unit || ""}
                      onChange={changeHandler}
                      error={!!validationErrors.rate_unit}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="openingQuantity"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="openingquantity"
                      label="Opening Quantity*"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={formData.openingquantity || ""}
                      onChange={changeHandler}
                      error={!!validationErrors.openingquantity}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="discount"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="discount"
                      label="Discount(%)*"
                      type="number"
                      variant="outlined"
                      fullWidth
                      value={formData.discount.toString() || ""}
                      onChange={changeHandler}
                      error={!!validationErrors.discount}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="taxRate"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="tax_rate"
                      label="Tax Rate*"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={formData.tax_rate || ""}
                      defaultValue={0}
                      onChange={changeHandler}
                      error={!!validationErrors.tax_rate}
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="costPrice"
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      name="costprice"
                      label="Cost Price"
                      variant="outlined"
                      type="number"
                      fullWidth
                      value={formData.costprice || ""}
                      onChange={changeHandler}
                    />
                  </Grid>
                  {/* <Grid item xs={12} md={3}>
                          <Autocomplete
                              id="superCategory"
                              size="small"
                              sx={{backgroundColor:'white'}}
                              options={superCategoryList}
                              getOptionLabel={(option) => option.name}
                              value={superCategoryList.find((item) => item.id === formData.superCategory) || null}
                              onChange={(event, newValue) => {
                                  changeHandler({ target: { name: 'superCategory', value: newValue?.id || '' } });
                              }}
                              renderInput={(params) => (
                                  <TextField
                                  {...params}
                                  name="superCategory"
                                  label="Super Category"
                                  variant="outlined"
                                  fullWidth
                                  />
                              )}
                          />
                      </Grid> */}
                  {/* <Grid item xs={12} md={3}>
                          <Autocomplete
                              id="packagingUnit"
                              size="small"
                              sx={{backgroundColor:'white'}}
                              options={packagingUnitList}
                              getOptionLabel={(option) => option} 
                              value={formData.packagingunit || null}
                              onChange={(event, newValue) => {
                                  changeHandler({ target: { name: 'packagingunit', value: newValue || '' } });
                              }}
                              renderInput={(params) => (
                                  <TextField
                                  {...params}
                                  name="packagingunit"
                                  label="Packaging Unit"
                                  variant="outlined"
                                  fullWidth
                                  />
                              )}
                          />
                          <Form.Label>Packaging Unit</Form.Label>
                          <Form.Select
                              name='packagingunit'
                              value={formData.packagingunit || ''}
                              onChange={changeHandler}
                          >
                              <option value=''>Select</option>
                          </Form.Select>
                      </Grid> */}
                  {/* <Grid item xs={12} md={3}>
                          <TextField
                              id="conversionFactor"
                              size="small"
                              sx={{backgroundColor:'white'}}
                              name="conversionfactor"
                              label="Conversion Factor"
                              variant="outlined"
                              fullWidth
                              value={formData.conversionfactor || ''}
                              onChange={changeHandler}
                              disabled
                          />
                      </Grid> */}
                  {/* <Grid item xs={12} md={3}>
                          <TextField
                              id="actualQuantity"
                              size="small"
                              sx={{backgroundColor:'white'}}
                              name="conversionfactor"
                              label="Actual Quantity"
                              variant="outlined"
                              fullWidth
                              value={formData.conversionfactor || ''}
                              onChange={changeHandler}
                              disabled
                          />
                      </Grid> */}
                  {company_type === "service" && (
                    <Grid item xs={12} md={3}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel htmlFor="subscriptionStatus">
                          Subscription Status
                        </InputLabel>
                        <Select
                          id="subscriptionStatus"
                          size="small"
                          sx={{ backgroundColor: "white" }}
                          name="SubscriptionStatus"
                          value={formData.SubscriptionStatus || ""}
                          onChange={changeHandler}
                          label="Subscription Status"
                        >
                          <MenuItem value="Monthly">Monthly</MenuItem>
                          <MenuItem value="Yearly">Yearly</MenuItem>
                          <MenuItem value="Trial">Trial</MenuItem>
                        </Select>
                      </FormControl>
                      {/* <Form.Label>Subscription Status</Form.Label>
                        <Form.Select
                            name='SubscriptionStatus'
                            value={formData.SubscriptionStatus || ''}
                            onChange={changeHandler}
                        >
                            <option value="Monthly">Monthly</option>
                            <option value="Yearly">Yearly</option>
                            <option value="Trial">Trial</option>
                        </Form.Select> */}
                    </Grid>
                  )}

                  {formData.item_type === "composite" && (
                    <Grid
                      container
                      alignItems="flex-end"
                      justifyContent="flex-end"
                      mt={2}
                      spacing={1}
                      sx={{
                        borderRadius: 2,
                        border: "1px solid #33333322",
                        marginTop: 2,
                        marginLeft: 2,
                      }}
                    >
                      {associatedItem.map((item, index) => (
                        <Stack
                          key={item.id}
                          direction="row"
                          width="100%"
                          justifyContent="space-between"
                          spacing={2}
                          padding={2}
                        >
                          <FormControl sx={{ width: "100%" }} size="small">
                            <InputLabel>Item</InputLabel>
                            <Select
                              placeholder="Item Name"
                              label="Item Name"
                              sx={{
                                width: "100%",
                                backgroundColor: "white",
                              }}
                              name="item_id"
                              onChange={(e) => {
                                handleChangeAssociatedItem(item.id, e);
                              }}
                              value={item.item_id}
                            >
                              {tableData.map((item, index) => (
                                <MenuItem key={index} value={item.id}>
                                  {item.item_name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            label="Quantity"
                            size="small"
                            type="number"
                            sx={{
                              width: "100%",
                              backgroundColor: "white",
                            }}
                            name="qty"
                            onChange={(e) => {
                              handleChangeAssociatedItem(item.id, e);
                            }}
                            value={item.qty}
                          />
                          <Stack direction="row" spacing={2}>
                            {associatedItem.length > 1 && (
                              <Tooltip title="Remove" arrow>
                                <Button
                                  color="danger"
                                  size="sm"
                                  variant="outlined"
                                  onClick={() => {
                                    delAssociatedItem(item.id);
                                  }}
                                >
                                  <RemoveCircleOutlineIcon />
                                </Button>
                              </Tooltip>
                            )}
                            <Tooltip title="Add" arrow>
                              <Button
                                color="info"
                                onClick={addAssociatedItem}
                                size="sm"
                                variant="solid"
                              >
                                <AddIcon />
                              </Button>
                            </Tooltip>
                          </Stack>
                        </Stack>
                      ))}
                    </Grid>
                  )}

                  <Grid
                    container
                    alignItems="flex-end"
                    justifyContent="flex-end"
                    mt={2}
                    spacing={1}
                  >
                    <Grid item xs={12} md={3}>
                      <Form.Label>
                        Status{" "}
                        <Form.Check
                          defaultChecked
                          Checked={formData.status}
                          name="status"
                          onChange={checkHandler}
                          inline
                          aria-label="option 1"
                          size="100%"
                        />
                      </Form.Label>
                    </Grid>
                    <Grid item xs={0} md={3}></Grid>
                    <Grid item xs={12} md={6}>
                      <Stack
                        direction="row"
                        justifyContent="flex-end"
                        spacing={3}
                        px={3}
                      >
                        <Button
                          //color={isEdit ? 'danger' : 'neutral'}
                          style={
                            isEdit
                              ? { backgroundColor: "#ae0000" }
                              : { backgroundColor: "#9c27b0" }
                          }
                          onClick={isEdit ? handleClose : clear}
                          variant="solid"
                          sx={{ minWidth: "15%" }}
                          title={isEdit ? "Close" : "Reset"}
                        >
                          {isEdit ? "Close" : "Reset"}
                        </Button>
                        <Button
                          onClick={handleSubmit}
                          autoFocus
                          variant="solid"
                          sx={{ minWidth: "15%" }}
                        >
                          {isEdit ? "Update" : "Save"}
                        </Button>
                        {isEdit === true && (
                          <Button
                            color="success"
                            onClick={() => {
                              navigate("advance", { state: formData });
                            }}
                            autoFocus
                            variant="solid"
                            sx={{ minWidth: "15%" }}
                          >
                            Advance Options
                          </Button>
                        )}
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
              </motion.div>
            </Container>
          )}
        </Paper>

        <ItemTableCard
          handleEdit={handleEdit}
          setIsChanged={setIsChanged}
          isChanged={isChanged}
        />
      </Stack>
    </>
  );
};
export default Items;
