import React, { useState, useEffect } from "react";
// import Header2 from "../../../Components/header/Header2/Header2";
import Footer from "../../../Components/Footer/Footer";
import CustomerSideBar from "../../../Components/sidebar/CustomerSideBar";
// import MiniDrawer from "../../../Components/sidebar/SideBar2";
// import AccountMasterCard from "../../Components/Card/AccountMasterCards/AccountMasterCard";
// import AccountMasterTable from "../../Components/Card/AccountMasterCards/AccountMasterTable";
// import InvoiceTable from "../../../pages/Invoice/InvoiceTable";
import CustInvoiceTable from "../../Component/CustInvoice/CustInvoiceTable";
// import InvoiceCard from "../../../Components/Card/InvoiceCards/InvoiceCard";
import CustHeader from '../../../Components/header/Header2/CustHeader'

import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from 'react-redux'
import { motion } from 'framer-motion'
import http from "../../../services/http";
import { SessionVerification } from "../../../Components/SessionManagement/SessionManagement";
import configServ from "../../../services/config";

const CustInvoice = () => {
    //#region code
    const { id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [tableData, setTableData] = useState([])
    const [miniLoader, setMiniLoader] = useState(false)

    const fetchTableData = async () => {
        setMiniLoader(true)
        try {
            const result = await configServ.invoicelist({ "user_id": id });
            // console.log(result)
            setTableData(result)
            setMiniLoader(false)
        } catch (err) {
            console.log(err)
            setMiniLoader(false)
        }
    }

    useEffect(() => {
        fetchTableData()
    }, []);
    //#endregion

    return (
        <>
            <SessionVerification />
            <CustHeader />
            <CustomerSideBar />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh',
                backgroundColor: '#EEEEEE77'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb: 5
                        }}
                    >
                        <motion.h4 style={{ textAlign: 'center' }}
                            initial={{ opacity: 0, y: -20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.1 }}
                        >
                            Invoices List
                        </motion.h4>
                        <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                            <Divider />
                        </Stack>
                        {tableData !== undefined && (<CustInvoiceTable tableData={tableData} miniLoader={miniLoader} />)}

                    </Paper>
                </Stack>
            </Box>
            <Footer />
        </>
    )
}
export default CustInvoice