import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';
import configServ from '../../services/config';
import { useDispatch } from 'react-redux';
import { triggerLoader } from '../../redux/reducers/LoaderTrigger';
import AlertDialog from "../../Components/AlertDialog/AlertDialog";

export default function InvoiceOrientation({ open, setOpen, data }) {

    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');
  
    const handleOpenDialog = (content) => {
      setDialogContent(content);
      setDialogOpen(true);
    };
  
    const handleCloseDialog = () => {
      setDialogOpen(false);
      setDialogContent('');
    };
    const dispatch = useDispatch()

    const printInvoice = async (landscape) => {
        const dataToSend = {
            ...data,
            "landscape": landscape
        }
        dispatch(triggerLoader())
        try {
            const result = await configServ.printInvoice(dataToSend)
            // console.log(result)
            const blobFile = new Blob([result], { type: 'application/pdf' })
            const url = window.URL.createObjectURL(blobFile);
            window.open(url)
            dispatch(triggerLoader())
        } catch (err) {
            handleOpenDialog('Error while printing invoice')
            console.log(err)
            dispatch(triggerLoader())
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
                   <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
                maxWidth='xs'
            >
                <DialogTitle id="alert-dialog-title">
                    <Typography variant='body' fontSize={'large'}>Orientation</Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography >Choose an orientation to print</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => { handleClose(); printInvoice(true) }}>
                        Landscape
                    </Button>
                    <Button variant='outlined' onClick={() => { handleClose(); printInvoice(false) }} >
                        Portrait
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}