import React from "react";
import Header2 from "../../Components/header/Header2/Header2";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import AccountMasterCard from "../../Components/Card/AccountMasterCards/AccountMasterCard";
import AccountMasterTable from "../../Components/Card/AccountMasterCards/AccountMasterTable";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from 'react-redux'

const AccountMaster = () => {

    const open = useSelector((state) => state.sideExpand.open)


    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb: 5
                        }}
                    >
                        <h4 style={{ textAlign: 'center' }} >Add Account </h4>
                        <br />
                        <AccountMasterCard />
                        <Divider />
                        <br />
                        <Stack direction='column' justifyContent='flex-start' width='100%' my={2}>
                            <Typography fontSize={15} variant="body" fontWeight='bold'>Dispatch Detail List</Typography>
                            <Divider />
                        </Stack>
                        <AccountMasterTable />
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}
export default AccountMaster