import React from 'react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField, IconButton, Grid, Autocomplete, Tooltip } from "@mui/material";
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import AddIcon from '@mui/icons-material/Add';

function AddPurchaseOrderItems({ itemList, handleAutocompleteChange, handleInputChange, handleAddItem, handleDeleteItem, items, handleQuantityChange, errors }) {
    return (
        <>
            <div>
                <motion.div
                    style={{
                        width: '100%',
                        backgroundColor: '#f2f0fc',
                        //backgroundImage: 'linear-gradient(315deg, #f1dfd1 0%, #f6f0ea 74%)',
                        padding: 20,
                        borderRadius: '10px',
                        border: 'none',
                        margin: '10px',
                        display: 'flex',
                        justifyContent: 'center'
                    }}
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.1, delay: 0.1 }}
                >
                    <div>
                        <Grid container>
                            <Link to='/master-entry/item'>
                                <Tooltip title={'Add item'}>
                                    <AddIcon sx={{ cursor: 'pointer', color: 'white', backgroundColor: 'grey', margin: '5px' }} justifyContent="flex-end" />
                                </Tooltip>
                            </Link>
                        </Grid>
                        {itemList.map((item, index) => (
                            <div key={item.id}>
                                <Grid container alignItems={'center'} spacing={{ xs: 1, md: 1, lg: 1 }} columns={{ xs: 4, sm: 8, md: 12 }} key={item.id} sx={{ padding: '10px' }}>
                                    <Grid item xs={12} sm={3} md={3} columnSpacing={0}>
                                        <Autocomplete
                                            disablePortal
                                            id="combo-box-demo"
                                            value={item.itemFullname}
                                            options={items.map((option) => option.item_name)}
                                            onChange={(e) => handleAutocompleteChange(e, item.id)}
                                            renderInput={(params) =>
                                                <TextField
                                                    {...params}
                                                    sx={{ backgroundColor: 'white' }} size="small" label="Item Name" variant="outlined"
                                                    name="itemFullname" value={item.itemFullname} onChange={(e) => handleInputChange(e, item.id)}
                                                    InputLabelProps={{ ...params.InputProps }}
                                                    error={errors[`${index}`] !== undefined ? !!errors[`${index}`].itemFullname : false}
                                                />}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={2} md={2} >
                            <TextField 
                                sx={{backgroundColor:'white'}} 
                                size="small" 
                                label="HSN" 
                                variant="outlined" 
                                type="text"
                                fullWidth 
                                name="hsn_code" value={item.hsn_code} 
                                InputLabelProps={{ shrink: item.hsn_code?true:false }}
                                InputProps={{ readOnly: true }}
                            />
                        </Grid> */}
                                    <Grid item xs={12} sm={3} md={3} >
                                        <TextField
                                            sx={{ backgroundColor: 'white' }}
                                            size="small"
                                            label="Quantity"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            name="quantity"
                                            value={item.quantity}
                                            onChange={(e) => handleQuantityChange(e, item.id)}
                                            error={errors[`${index}`] !== undefined ? !!errors[`${index}`].quantity : false}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} >
                                        <TextField
                                            sx={{ backgroundColor: 'white' }}
                                            size="small"
                                            label="Rate"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            name="rate"
                                            value={item.rate}
                                            onChange={(e) => handleInputChange(e, item.id)}
                                            error={errors[`${index}`] !== undefined ? !!errors[`${index}`].rate : false}
                                            defaultValue={0}
                                        />
                                    </Grid>
                                    {/* <Grid item xs={12} sm={3} md={1} >
                                        <TextField
                                            sx={{ backgroundColor: 'white' }}
                                            size="small"
                                            label="Tax Amt"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            name="tax_amount"
                                            value={item.tax_amount}
                                            InputLabelProps={{ shrink: item.tax_amount ? true : false }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid> */}
                                    <Grid item xs={12} sm={2} md={2} >
                                        <TextField
                                            sx={{ backgroundColor: 'white' }}
                                            size="small"
                                            label="Amount"
                                            variant="outlined"
                                            type="number"
                                            fullWidth
                                            name="amount"
                                            value={item.amount}
                                            InputLabelProps={{ shrink: item.amount ? true : false }}
                                            InputProps={{ readOnly: true }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={2} md={2} >
                                        <Tooltip title={'Add item'}>
                                            <IconButton onClick={handleAddItem}>
                                                <AddBoxIcon fontSize='medium' sx={{ color: 'green' }} />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title={'Delete item'}>
                                            <IconButton
                                                hidden={itemList.length > 1 ? false : true}
                                                onClick={() => handleDeleteItem(item.id)}
                                            >
                                                <DeleteIcon fontSize='medium' sx={{ color: '#d43529' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Grid>
                                </Grid>
                            </div>

                        ))}
                    </div>
                </motion.div>
            </div>
        </>
    )
}

export default AddPurchaseOrderItems;
