import React, { useEffect, useState } from "react";
import Header2 from "../../Components/header/Header2/Header2";
import MiniDrawer from "../../Components/sidebar/SideBar2";
import SalesOrderCard from "../../Components/Card/SalesOrderCards/SalesOrderCard";
import { Box, Stack, Paper, Divider, Typography, Button } from "@mui/material";
import { useSelector } from 'react-redux';
import { Container } from "react-bootstrap";
import configServ from "../../services/config";
import SalesOrderTable2 from "../../Components/Card/SalesOrderCards/SaleOrderTable2";
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";
import CardHeadMenu from "../../Components/Card/CardHeadMenu/CardHeadMenu";
import SalesOrderTable from "../../Components/Card/SalesOrderCards/SalesOrderTable";

const SalesOrder = () => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const open = useSelector((state) => state.sideExpand.open)
    const [tableData, setTableData] = useState([])
    const [isCreate, setIsCreate] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})

    // const fetchSalesOrderList = async () => {
    //     const data = {
    //         'company_id': company_id
    //     }
    //     try {
    //         const result = await configServ.salesOrderList(data); 
    //     }
    //     catch (err) {
    //         console.log(err)
    //         // setMiniLoader(false)
    //     }
    // }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
        });
    };

    useEffect(() => {
        //     fetchSalesOrderList();
        scrollToTop();
    }, []);

    const handlePopup = () => {
        setIsCreate(!isCreate)
    }

    const handleEditData = (data) => {
        setIsEdit(true);
        setIsCreate(true);
        setEditData(data);
    }

    const clear = () => {
        setEditData({})
    }
    //#endregion

    return (
        <>
            <SessionVerificationAdmin />
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                ...(open && { marginLeft: 23 }),
                transition: '200ms',
                minHeight: '77vh',
            }}>

                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            mx: 2,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            paddingRight: 3,
                            paddingLeft: 4,
                            pb: 5
                        }}
                        elevation={0}
                    >
                        <CardHeadMenu
                            prefix={"Na"}
                            suffix={"Na"}
                            title={"Sales Order"}
                            handlePopup={handlePopup}
                            isEdit={isEdit}
                            create={isCreate}
                        />
                        <Container>
                            {
                                isCreate
                                &&
                                <SalesOrderCard isEdit={isEdit} setIsEdit={setIsEdit} editData={editData} clear={clear} setIsChanged={setIsChanged} isChanged={isChanged} />
                            }
                        </Container>
                        <SalesOrderTable handleEditData={handleEditData} isChanged={isChanged} />
                    </Paper>
                </Stack>
            </Box>
        </>
    )
}
export default SalesOrder