import React, { useState, useEffect } from "react";
// import { Form, Row, Col, FormCheck, Button} from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";
import './Login.css'
import http from "../../services/http";
import { useDispatch, useSelector } from "react-redux";
import { triggerLoader } from "../../redux/reducers/LoaderTrigger";
import Cookies from "universal-cookie/cjs/Cookies";
import { decodeToken } from 'react-jwt'
import AlertDialog from "../../Components/AlertDialog/AlertDialog";
import LoginIcon from '@mui/icons-material/Login';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import { Box, Typography, Container, TextField, FormControlLabel, Grid, Checkbox, Button, CircularProgress, InputAdornment, IconButton } from "@mui/material";
import configServ from "../../services/config";
import { assignType } from "../../redux/reducers/Admin";
import { rSetAddress, rSetCompanyId, rSetCompanyName, rSetCurrency, rSetEmail, rSetId, rSetName, rSetToken, rSetUserId, rSetUserName } from "../../redux/reducers/GlobalVariables";

const Login = () => {
    //#region code
    const lightMode = useSelector(state => state.GlobalVariables.lightMode);
    const [formData, setFormData] = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [dialogContent, setDialogContent] = useState('Invalid credentials.')
    const { isUserAdmin } = useSelector((state) => state.user)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cookie = new Cookies();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    // to fetch data from text box
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    // to handle login
    const handleLogin = async (e) => {
        e.preventDefault()
        dispatch(triggerLoader())
        try {
            const result = await configServ.login(formData);
            if (result.success) {
                localStorage.setItem("token", result.success.token);
                dispatch(rSetEmail(result.success.email));
                dispatch(rSetUserId(result.success.customerId));
                dispatch(assignType(result.success.company_type));
                dispatch(rSetCurrency(result.success.currency));
                dispatch(rSetCompanyId(result.success.company_id));
                const data = {
                    id: result.success.company_id,
                }
                const res = await configServ.getCompanyById(data);
                if (res.status === 200) {
                    dispatch(rSetCompanyName(res.data.CompanyName));
                }
                dispatch(rSetId(result.success.id));
                dispatch(rSetUserName(result.success.name));
                dispatch(rSetName(result.success.name));
                dispatch(rSetAddress(result.success.address));

                const decodedToken = decodeToken(result.success.token)
                cookie.set('dcrm_token', result.success.token, {
                    expires: new Date(decodedToken.exp * 1000)
                })
                cookie.set('userType', 'customer', {
                    expires: new Date(decodedToken.exp * 1000)
                })
                //dispatch(triggerLoader());
                navigate('/customer-dashboard', { replace: true })
            }
            else{
                setDialogContent(result.error);
                setLoginError(true);
                //dispatch(triggerLoader());
            }
        }
        catch (err) {
            console.log(err)
            setLoginError(true);
            //dispatch(triggerLoader());
        }
        finally{
            dispatch(triggerLoader());
        }
    }

    // const checkedLoggedIn = () => {
    //     if (cookie.get('dcrm_token') !== undefined) {
    //         if (cookie.get('userType') === 'customer') {
    //             navigate('/customer-dashboard')
    //         }
    //         else {
    //             navigate('/Dashboard')
    //         }
    //     }
    // }

    // useEffect(() => {
    //     checkedLoggedIn()
    // }, []);
    //#endregion

    return (
        <>
            <AlertDialog open={loginError} setOpen={setLoginError} title={'Login Error'} message={dialogContent} />
            <Box
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    px: 4,
                    py: 6,
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    backgroundColor: '#ffffff'
                }}
            >
                <Typography component="h1" variant="h5">
                    <AccountCircleTwoToneIcon />Login
                </Typography>
                <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        value={formData.email}
                        autoFocus
                        onChange={handleChange}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        value={formData.password}
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        onChange={handleChange}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />


                    {/* <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        <LoginIcon sx={{ margin: '5px 10px' }} />Login
                    </Button>


                    <Grid container spacing={21}>
                        {/* <Grid item>
                                <Link to='/register'>
                                    <Typography variant="body2" color='primary'>Don't have an account? Register</Typography>
                                </Link>
                            </Grid> */}
                        <Grid item>
                            <Link to='/forgetPassword'>
                                <Typography variant="body2" color='primary'>Forgot Password</Typography>
                            </Link>
                        </Grid>
                    </Grid>

                </Box>
            </Box>

        </>
    );
};
export default Login;