import React,{useEffect, useState} from "react";
// import MasterEnteryCard from '../../Components/Card/MaterEntryCards/MasterEntryCard'
// import TableCard from "../../Components/Card/MaterEntryCards/TableCard";


import { Form, Container, Row, Col } from 'react-bootstrap';

import { Stack, Paper, Divider, Typography, RadioGroup, Button, Radio } from "@mui/material";
import PurchaseBillCard from "../../../Components/Card/TransactionCards/PurchaseBill/PucrchaseBillCard";
import PurchaseBillTable from "../../../Components/Card/TransactionCards/PurchaseBill/PurchaseBillTable";

 const PurchaseBill = () =>{


    // useEffect(()=>{
    //     forLoop()
    // })

    return(
        <>


                <Stack
                    direction='column'
                    justifyContent='center'
                    alignItems='center'
                >
                    <Paper
                        sx={{
                            width: '90%',
                            margin: 5,
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            padding: 3,
                            pb:5
                        }}
                    >
                            <h4 style={{ textAlign: 'center' }} >Purchase Bill</h4>
                            <br/>
                        
                <Container>
                    <PurchaseBillCard title={'Credit'}/>
                </Container>


                        <Divider />
                        <br />
                        <Stack direction='column'justifyContent='flex-start' width='100%' my={2}>
                            {/* <Typography fontSize={15} variant="body" fontWeight='bold'>HSN List</Typography> */}
                            <Divider/>
                        </Stack>
                        <PurchaseBillTable/>
                    </Paper>
                </Stack>

        </>
    )
 }
 export default PurchaseBill