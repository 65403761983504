import React, { useState, useEffect } from "react";
import UnitsTableCard from "../Card/MaterEntryCards/UnitsTableCard";

import { Stack, Paper, Button, Divider, Typography, Tooltip, IconButton, Grid, InputLabel, Select, Checkbox, TextField, FormControl, MenuItem } from "@mui/material";
import { motion } from 'framer-motion'
import configServ from "../../services/config";
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "../AlertDialog/AlertDialog";
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";

const Unit = () => {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const formDataInitial = {
        typeofunits: "Simple",
        status: true,
        isPrimary: false
    };
    const [formData, setFormData] = useState(formDataInitial);
    const [validationErrors, setValidationErrors] = useState({});
    const [count, setCount] = useState(0);
    const [unitList, setUnitList] = useState([])
    const [simpleUnitList, setSimpleUnitList] = useState([])
    const [isChanged, setIsChanged] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [isCreate, setIsCreate] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };
    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
        if ((name === 'typeofunits') && (name !== 'Compound')) {
            setFormData((state) => ({
                ...state,
                derive_unit: '',
                conversion_factor: ''
            }))
        }
        if ((name === 'typeofunits') && (value !== 'Simple')) {
            setFormData((state) => ({
                ...state,
                derive_unit: null,
                conversion_factor: null
            }))
        }
    }
    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleFormValidation = () => {
        const errors = {}
        if (!formData.units_name) {
            errors.units_name = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        const error = handleFormValidation();
        if (error === false) {
            return false;
        }
        const dataToSend = {
            ...formData,
            company_id: company_id
        }
        try {
            if (!isEdit) {
                const result = await configServ.addUnit(dataToSend);
                console.log(result);
                setIsChanged(!isChanged);
                clear();
                handleOpenDialog(result.message);
            } else {
                const result = await configServ.editUnit(dataToSend);
                console.log(result);
                setIsChanged(!isChanged);
                clear();
                handleOpenDialog(result.message);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchUnitList = async () => {
        try {
            const result = await configServ.unit({ "company_id": company_id })
            if (result.length > 0) {
                setUnitList(result);
                setCount(result.length);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    const fetchSimpleUnitList = async () => {
        try {
            const result = await configServ.getSimpleUnit({ "company_id": company_id })
            console.log(result)
            setSimpleUnitList(result)
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        fetchUnitList()
        fetchSimpleUnitList()
    }, [isChanged])

    const handleEdit = (data) => {
        setFormData(data);
        setIsEdit(true);
        setIsCreate(true);
        setFormData((prev) => ({
            ...prev,
            status: true
        }));
    }

    const handlePopup = () => {
        setIsCreate(!isCreate);
    }

    const clear = () => {
        setFormData(formDataInitial);
        setValidationErrors({});
        setIsEdit(false)
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="Alert"
                message={dialogContent}
                buttonTitle="Ok"
            />
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
            >
                <Paper
                    elevation={0}
                    sx={{
                        width: '100%',
                        mt: '2',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        // pb: 5
                    }}
                >
                    <CardHeadMenu
                        prefix={"Na"}
                        suffix={"Na"}
                        title={"UNITS"}
                        handlePopup={handlePopup}
                        isEdit={isEdit}
                        create={isCreate}
                    />
                    {isCreate &&
                        <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.1, delay: 0.1 }}
                        >
                            <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                                <Grid item xs={12} md={2}>
                                    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
                                        <InputLabel>Type of Units*</InputLabel>
                                        <Select size="small" name="typeofunits" value={formData.typeofunits || ''} onChange={handleChange}>
                                            <MenuItem value="Simple">Simple</MenuItem>
                                            <MenuItem value="Compound">Compound</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2}>
                                    <TextField
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        label="Units Name*"
                                        name="units_name"
                                        value={formData.units_name || ''}
                                        onChange={handleChange}
                                        error={validationErrors.units_name}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                                    <FormControl fullWidth sx={{ backgroundColor: 'white' }}>
                                        <InputLabel>Derive Unit</InputLabel>
                                        <Select size="small" name="derive_unit" value={formData.derive_unit || ''} onChange={handleChange}>
                                            <MenuItem value=''>Select</MenuItem>
                                            {simpleUnitList.map((item, index) => (
                                                <MenuItem key={index} value={item.units_name}>{item.units_name}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                                    <TextField
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        label="Conversion factor"
                                        name="conversionfactor"
                                        value={formData.conversionfactor || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <TextField
                                        size="small"
                                        sx={{ backgroundColor: 'white' }}
                                        label="Description"
                                        name="description"
                                        value={formData.description || ''}
                                        onChange={handleChange}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <Stack direction='row' spacing={1}>
                                        <Form.Label style={{ cursor: 'pointer', marginTop: 11, marginRight: 10 }}>
                                            <Checkbox
                                                onChange={handleChangeCheckbox}
                                                checked={Boolean(formData.status) || false}
                                                name="status"
                                            />
                                            Status</Form.Label>

                                        <Form.Label style={{ cursor: 'pointer', marginTop: 11, marginRight: 10 }}>
                                            <Checkbox
                                                name='isPrimary'
                                                checked={Boolean(formData.isPrimary) || false}
                                                onChange={handleChangeCheckbox}
                                            />
                                            isPrimary</Form.Label>
                                    </Stack>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2} justifyContent="space-between" px={2}>
                                <Grid
                                    item
                                    container
                                    justifyContent="flex-end"
                                    alignItems="center"
                                    spacing={2}
                                >
                                    <Grid item>
                                        <Button
                                            onClick={clear}
                                            variant="contained"
                                            color='secondary'
                                            startIcon={<RotateLeftIcon />}
                                        >
                                            Reset
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={handleSubmit}
                                            autoFocus
                                            variant="contained"
                                            color={isEdit ? 'success' : 'primary'}
                                            startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                                        >
                                            {isEdit ? "Update" : "Save"}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                            <Grid item xs={12} md={2}>
                                <Form.Label>Type of Units*</Form.Label>
                                <InputLabel>Type of Units*</InputLabel>
                                <Select name="typeofunits" value={formData.typeofunits || ''} onChange={handleChange}>
                                    <option value="Simple">Simple</option>
                                    <option value="Compound">Compound</option>
                                </Select>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Form.Label>Units Name*</Form.Label>
                                <Form.Control name="units_name" value={formData.units_name || ''} onChange={handleChange} type="" placeholder={`Enter unit`} />
                            </Grid>
                            <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                                <Form.Label>Derive Unit</Form.Label>
                                <Form.Select name="derive_unit" value={formData.derive_unit || ''} onChange={handleChange}>
                                    <option value=''>Select</option>
                                    {
                                        simpleUnitList.map((item, index) => (
                                            <option key={index} value={item.units_name}>{item.units_name}</option>
                                        ))
                                    }
                                </Form.Select>
                            </Grid>
                            <Grid item xs={12} md={2} hidden={formData.typeofunits === 'Compound' ? false : true}>
                                <Form.Label>Conversion factor</Form.Label>
                                <Form.Control name="conversionfactor" value={formData.conversionfactor || ''} onChange={handleChange} type="" placeholder={`Ex-12pcs`} />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <Form.Label>Description</Form.Label>
                                <Form.Control name="description" value={formData.description || ''} onChange={handleChange} type="" placeholder='Enter Description' />
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Form.Label style={{ cursor: 'pointer', marginTop: 11 }}> <Form.Check onChange={handleChangeCheckbox} checked={Boolean(formData.status) || false} name="status" inline aria-label="option 1" size={100} />Status</Form.Label>
                                
                                <Form.Label style={{ cursor: 'pointer', marginTop: 11, }}> <Form.Check name='isPrimary' checked={Boolean(formData.isPrimary) || false} onChange={handleChangeCheckbox} inline aria-label="option 2" size={100} />isPrimary</Form.Label>
                            </Grid>
                            <Grid item xs={12} md={2}>
                                <Stack direction='row' spacing={1}>
                                <Button variant="primary" onClick={handleSubmit}>{isEdit?'Update':'Save'}</Button>
                                {
                                isEdit && (<Tooltip title='Reset'>
                                    <IconButton onClick={clear} >
                                        <RotateLeftIcon />
                                    </IconButton>        
                                </Tooltip>)
                                }   
                            </Stack>
                            </Grid>
                        </Grid> */}
                        </motion.div>
                    }
                </Paper>
                <Stack direction='column' justifyContent='flex-start' ml={7} width='100%' >
                    <Typography fontSize={15} variant="body" fontWeight='bold'>Units List : {count} </Typography>
                </Stack>
                <UnitsTableCard data={unitList} handleEdit={handleEdit} />
            </Stack>
        </>
    )
}
export default Unit;