import React, { useState } from 'react';
import TablePagination from '@mui/material/TablePagination';

export default function Pagination({ count, paginating }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5); // Set 5 as the default value
  const rowsPerPageOptions = [5, 10, 20];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    paginating({
      start: newPage * rowsPerPage,
      end: newPage * rowsPerPage + rowsPerPage,
    });
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(newRowsPerPage);
    setPage(0);
    paginating({
      start: 0,
      end: newRowsPerPage,
    });
  };

  return (
    <TablePagination
      count={count}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
    />
  );
}
