import React from "react";
import Header2 from "../../Components/header/Header2/Header2";
import Footer from "../../Components/Footer/Footer";
import MiniDrawer from "../../Components/sidebar/SideBar2";

import { Outlet } from "react-router-dom";

import { Box } from "@mui/material";
import { useSelector } from 'react-redux'

import {motion, AnimatePresence} from 'framer-motion'
import { SessionVerificationAdmin } from "../../Components/SessionManagement/SessionManagementAdmin";

const MasterEntery = () => {

    const open = useSelector((state) => state.sideExpand.open)

    return (
        <>
            <SessionVerificationAdmin/>
            <Header2 />
            <MiniDrawer />
            <Box sx={{
                // marginTop:{
                //     xs:7,
                //     sm:8
                // },
                marginLeft: {
                    xs: 7,
                    sm: 8,
                },
                ...(open && { marginLeft: 33 }),
                transition: '200ms',
                minHeight: '77vh'
            }}
            component={motion.div}
            initial={{
                opacity:0
            }}
            animate={{
                opacity:1
            }}
            transition={{
                duration:0.4
            }}
            >

                <Outlet/>

            </Box>
            <Footer/>
        </>
    )
}
export default MasterEntery