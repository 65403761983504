import { Box, Card, CardContent, CardMedia, Stack, Typography } from '@mui/material'
import React, { useEffect } from 'react'

import { FcStumbleupon } from "react-icons/fc";
import { useSelector } from 'react-redux';

function DashboardCard({ data }) {

    const {currency} = useSelector((state) => state.GlobalVariables);

    return (
        <>

            <Card sx={{
                width: {
                    xs: '70%',
                    sm: '70%',
                    md: '20%'
                },
                height: '5rem',
                ":hover": {
                    scale: '1.03',
                    cursor: 'pointer'
                },
                transition: '150ms'
            }}>
                <Stack direction='row' justifyContent='space-between' alignItems='center'>
                    <CardContent sx={{flex:3}}>
                        <Stack 
                        sx={{
                            width:'100%'
                        }}
                        >
                            <Stack
                                direction='column'
                            // border={1}
                            >
                                <Typography variant='body' fontSize='small' fontWeight='bold' color='text.disabled'>
                                    {data.title}
                                </Typography>
                                <Typography
                                    variant='h6'
                                    fontWeight='bold'
                                    sx={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                    }}
                                >
                                    {data.number}<Box component='span' fontSize='small'>{data.amount !== undefined ? ` (${currency} ${data?.amount})` : ''}</Box>
                                </Typography>
                            </Stack>
                        </Stack>
                    </CardContent>
                    <CardContent >
                        <Box
                            sx={{
                                borderRadius: '50px',
                                padding: 1,
                                border: '1px solid #fb6340'
                            }}
                        >
                            {data.icon}
                        </Box>
                    </CardContent>
                </Stack>
            </Card>

        </>
    )
}

export default DashboardCard