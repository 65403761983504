import React, { useState } from "react";
import { Stack, Paper, Divider } from "@mui/material";
import CategoryTable from "../Card/MaterEntryCards/CategoryTable";
import CategoryCard from "../Card/MaterEntryCards/CategoryCard";

//FUNCTION
const Category = () => {
    //#region code
    const [isChanged, setIsChanged] = useState(false)
    const [editData, setEditData] = useState({})
    
    const handleEdit = (data) => {
        setEditData(data);
    }
    //#endregion

    return (
        <>
            <Stack
                direction='column'
                justifyContent='center'
                alignItems='center'
            >
                <Paper
                    elevation={0}
                    sx={{
                        width: '100%',
                        // mt: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: 3,
                        // pb:5
                    }}
                >
                    <CategoryCard isChanged={isChanged} setIsChanged={setIsChanged} editData={editData} setEditData={setEditData} />
                </Paper>
                <Divider />

                <CategoryTable handleEdit={handleEdit} isChanged={isChanged} />

            </Stack>
        </>
    )
}
export default Category;