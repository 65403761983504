import React, { useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import configServ from '../../services/config';
import ActionButtons from '../../Components/Buttons/ActionButtons ';
import CheckAndReturn from '../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../Components/DataGridTable/DataGridTable';
import { useSelector } from 'react-redux';

const CatalogTable = ({ handleEditData, isChanged }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [catalogDataList, setCatalogDataList] = useState([]);
    const [catalogList, setCatalogList] = useState([]);
    const [loading, setLoading] = useState(true);
    const columns = [
        { field: 'sn', headerName: 'S.NO.', flex: 1 },
        { field: 'catalog_name', headerName: 'Catalog Name', flex: 2 },
        { field: 'currentlyActive', headerName: 'Currently Active', flex: 2 },
        { field: 'created_by', headerName: 'Created By', flex: 2 },
        {
            field: 'action',
            headerName: 'Action',
            width: 130,
            renderCell: (params) => (
                <ActionButtons
                    handlePrint={handlePrint}
                    handleEdit={handleEdit}
                    id={params.id}
                    noPrint
                />
            ),
        },
    ];

    useEffect(() => {
        fetchCatalogList();
    }, [isChanged]);

    const fetchCatalogList = async () => {
        try {
            const { data: res } = await configServ.getCatalog({ 'company_id': company_id });
            console.log(res)
            const result = res.map((item, index) => ({
                sn: index + 1,
                id: item.id,
                catalog_name: item.catalog_name,
                currentlyActive: item.currentlyActive ? 'Active' : 'Inactive',
                created_by: item.admin.name,
            }));
            // console.log(result)
            setCatalogDataList(res || []);
            setCatalogList(result || []);
            setLoading(false);
        }
        catch (error) {
            // console.log(error);
            setCatalogDataList([]);
            setCatalogList([]);
            setLoading(false);
        }
    }

    const handlePrint = (params) => {
        const data = catalogDataList.filter(x => x.id === params);
        // console.log('data', data)
        // alert(JSON.stringify(data));
        // printInvoice(data[0].invoice_number)
    }

    const handleEdit = (params) => {
        // alert(JSON.stringify(params));
        scrollToTop()
        const data = catalogDataList.filter(x => x.id === params);
        handleEditData(data)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            <DataGridTable rows={catalogList} columns={columns} />
        );
    }
};

export default CatalogTable;
