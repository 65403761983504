import React, { useState } from 'react';
import configServ from '../../../services/config';
import ActionButtons from '../../Buttons/ActionButtons ';
import CheckAndReturn from '../../../utility/CheckAndReturn';
import { useEffect } from 'react';
import DataGridTable from '../../DataGridTable/DataGridTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import axios from 'axios';
import { appServiceName } from '../../../services/http';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
    Box,
    Tooltip,
    IconButton,
    Grid,
    Typography,
    Button,
    NativeSelect,
    InputLabel,
    TextField,
    InputAdornment,
    FormControl,
    CircularProgress
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import EditIcon from '@mui/icons-material/Edit';
import { useSelector } from 'react-redux';

const RegistrationTable = ({ handleEditData, isChanged, pagination = true }) => {
    //#region code
    const { user_id, company_id } = useSelector((state) => state.GlobalVariables);
    const rowsPerPageOptions = [5, 10, 25];
    const [companyDataList, setCompanyDataList] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [count, setCount] = useState(0);
    const [companyList, setCompanyList] = useState([]);
    const [filterCompanyList, setFilterCompanyList] = useState([]);
    const [searchBy, setSearchBy] = useState('CompanyName');
    const [loading, setLoading] = useState(true);

    const headers = ['COMPANY NAME', 'COMPANY TYPE', 'PHONE', 'ADDRESS', 'STATUS', 'ACTION'];

    useEffect(() => {
        fetchCompanyList();
    }, [isChanged]);

    const fetchCompanyList = async () => {
        try {
            const res = await configServ.getCompanyList({ 'user_id': user_id });
            if (res.length > 0) {
                setCompanyList(res);
                setFilterCompanyList(res);
                setCount(res.length);
            }
        }
        catch (error) {
            console.log(error);
            setCompanyDataList([]);
            setCompanyList([]);
        }
        finally{
            setLoading(false);
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const emptyRows =
        rowsPerPage - Math.min(rowsPerPage, count - page * rowsPerPage);

    const paginatedData = pagination
        ? filterCompanyList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : filterCompanyList;

    const headerCellStyle = {
        fontSize: 14, // Adjust the font size as needed
        fontWeight: 'bold',
        backgroundColor: 'black', // Set the header background color to blue
        color: 'white',
    };

    const evenRowStyle = {
        background: '#f5f5f5',
    };

    const oddRowStyle = {
        background: 'white',
    };

    const rowStyle = {
        '&:hover': {
            background: '#f1f9fe',
        },
    };
    const handleSearchBy = (event) => {
        try {
            setSearchBy(event.target.value);
        }
        catch (error) {
            console.log(error);
        }
    }

    const handleSearch = (event) => {
        try {
            const search = event.target.value;
            let data = [];
            if (search === '') {
                data = companyList;
            }
            else if (searchBy === 'CompanyName') {
                data = companyList.filter(x => x.CompanyName.toLowerCase().includes(search.toLowerCase()))
            }
            else if (searchBy === 'CompanyType') {
                data = companyList.filter(x => x.CompanyType.toLowerCase().includes(search.toLowerCase()))
            }
            else if (searchBy === 'MobileNo') {
                data = companyList.filter(x => x.MobileNo.toLowerCase().includes(search.toLowerCase()))
            }
            setFilterCompanyList(data);
            setCount(data.length);
        }
        catch (error) {
            console.log(error);
        }
    }

    const printInvoice = async (invoice_no) => {
        const data = {
            "invoice_no": invoice_no,
            "user_id": user_id,
            "company_id": company_id
        }
        console.log(data)

        try {
            const fileName = `invoice${user_id}${invoice_no}`
            const url = '/api/print_invoice/'
            axios.post(`${appServiceName}${url}`, data, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf'
                }
            })
                .then((res) => {
                    const blobFile = new Blob([res.data], { type: 'application/pdf' })
                    const url = window.URL.createObjectURL(blobFile);
                    window.open(url)
                    // console.log(url)
                    // link.href = url;
                    // const link = document.createElement('a');
                    // link.download = fileName;
                    // link.click();
                })
                .catch((err) => {
                    console.log(err)
                })


        } catch (err) {
            console.log(err)
        }
    }


    const handlePrint = (params) => {
        const data = companyList.filter(x => x.id === params.id);
        printInvoice(data[0].invoice_number)
    }

    const handleEdit = (params) => {
        scrollToTop()
        const data = companyList.filter(x => x.id === params.id);
        handleEditData(data)
    }

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    //#endregion

    if (loading) {
        return (
            <Box
                sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <CircularProgress />
            </Box>
        )
    }
    else {
        return (
            // <DataGridTable rows={companyList} columns={columns} />
            <>
                <Grid container alignItems="flex-end" justifyContent="flex-start" style={{ padding: 10, paddingTop: 0 }} spacing={1} direction={'row'}>
                    <Grid item xs={12} md={6} ml={4} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>Company List:{count}</Typography>
                    </Grid>
                    <Grid item xs={4} md={2}>
                        <Box sx={{ minWidth: 120, marginLeft: '10px' }}>
                            <FormControl
                                fullWidth
                            >
                                <InputLabel variant="standard" htmlFor="uncontrolled-native">
                                    Search By
                                </InputLabel>
                                <NativeSelect
                                    defaultValue={'contact_name'}
                                    onChange={handleSearchBy}
                                    inputProps={{
                                        name: 'searchBy',
                                        id: 'uncontrolled-native',
                                    }}
                                >
                                    <option value={'CompanyName'}>Company Name</option>
                                    <option value={'CompanyType'}>Company Type</option>
                                    <option value={'MobileNo'}>Mobile</option>
                                </NativeSelect>
                            </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6} md={3} mr={{ xs: 0, md: 4 }} justifyContent={'flex-end'}>
                        <TextField
                            id="outlined-basic"
                            size="small"
                            fullWidth
                            placeholder="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ fill: 'grey' }} />
                                    </InputAdornment>
                                ),
                            }} />
                    </Grid>
                </Grid><TableContainer component={Paper} sx={{ px: 5 }}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>S.NO.</TableCell>
                                {headers.map((header, index) => (
                                    <TableCell key={index} style={headerCellStyle}>
                                        {header}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {paginatedData.map((row, rowIndex) => (
                                <TableRow key={rowIndex}
                                    sx={{
                                        ...rowStyle,
                                        ...(rowIndex % 2 === 0 ? evenRowStyle : oddRowStyle),
                                    }}>
                                    <TableCell>{page * rowsPerPage + rowIndex + 1}</TableCell>
                                    <TableCell>{row.CompanyName}</TableCell>
                                    <TableCell>{row.CompanyType}</TableCell>
                                    <TableCell>{row.MobileNo}</TableCell>
                                    <TableCell>{row.Address}</TableCell>
                                    <TableCell>{row.is_active === 1 ? <CheckCircleIcon color='success'/> : <CancelIcon color='error'/>}</TableCell>
                                    <TableCell>
                                        <Box sx={{ borderRadius: 10, width: 'fit-content', transition: '100ms', cursor: 'pointer', padding: 0.5, ":hover": { backgroundColor: '#99999955' } }}
                                            onClick={() => { handleEdit(row); scrollToTop(); }}
                                        >
                                            <Tooltip title='Edit' arrow>
                                                <EditIcon />
                                            </Tooltip>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 53 * emptyRows }}>
                                    <TableCell colSpan={headers.length + 2} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && (
                        <TablePagination
                            rowsPerPageOptions={rowsPerPageOptions}
                            component="div"
                            count={count}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage} />
                    )}
                </TableContainer></>
        );
    }
};

export default RegistrationTable;
