import React, { useState } from 'react'
import { Container, Row, Col, Form } from 'react-bootstrap'
import { Paper, Stack, IconButton, Tooltip, Grid, Typography, TextField, Button, FormGroup, FormControlLabel, Checkbox } from '@mui/material'
import { motion } from 'framer-motion'
import configServ from '../../../services/config'
import { useEffect } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import AlertDialog from "../../AlertDialog/AlertDialog";
import { useSelector } from 'react-redux'

const BrandCard = ({ isChanged, setIsChanged, editData }) => {
    //#region code
    const { company_id } = useSelector((state) => state.GlobalVariables);
    const [formData, setFormData] = useState({
        status: true,
        isPrimary: false
    })
    const [isEdit, setIsEdit] = useState(false)
    const [validationErrors, setValidationErrors] = useState({});
    const [dialogOpen, setDialogOpen] = useState(false);
    const [dialogContent, setDialogContent] = useState('');

    const handleOpenDialog = (content) => {
        setDialogContent(content);
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setDialogContent('');
    };

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData((state) => ({
            ...state,
            [name]: value
        }))
    }

    const handleChangeCheckbox = (e) => {
        const { name, checked } = e.target
        setFormData((state) => ({
            ...state,
            [name]: checked
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        const error = handleFormValidation();
        if (error === false) {
            return false;
        }
        setValidationErrors({});

        const dataToSend = {
            ...formData,
            company_id: company_id
        }
        try {
            if (!isEdit) {
                const result = await configServ.addBrand(dataToSend);
                setIsChanged(!isChanged);
                clear();
                handleOpenDialog(result.message);
            } 
            else {
                const result = await configServ.editBrand(dataToSend);
                setIsChanged(!isChanged);
                clear();
                handleOpenDialog(result.message);
            }
        } 
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        if (Object.keys(editData).length !== 0) {
            setFormData(editData)
            setIsEdit(true)
            setFormData((prev) => ({
                ...prev,
                status: true
            }));
        }
    }, [editData]);

    const clear = () => {
        setFormData({
            status: true
        })
        setValidationErrors({})
        setIsEdit(false)
    }

    const handleFormValidation = () => {
        const errors = {}
        if (!formData.brand_name) {
            errors.brand_name = "required";
        }
        setValidationErrors(errors);
        return Object.values(errors).every((error) => error === "");
    }
    //#endregion

    return (
        <>
            <AlertDialog
                open={dialogOpen}
                setOpen={handleCloseDialog}
                title="ALERT"
                message={dialogContent}
                buttonTitle="OK"
            />

            <motion.div style={{ width: "100%", backgroundColor: "#e3f3fc", padding: 20, borderRadius: '10px', border: 'none' }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
            >
                <Grid container alignItems="flex-end" justifyContent="flex-start" spacing={1}>
                    <Grid item xs={12} md={12} justifyContent={'flex-start'}>
                        <Typography sx={{ fontSize: '16px', fontWeight: 'bolder' }}>Brand Detail</Typography>
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            id="brand_name"
                            name="brand_name"
                            label="Brand Name*"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.brand_name || ''}
                            onChange={handleChange}
                            error={validationErrors.brand_name}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>

                    <Grid item xs={12} md={3}>
                        <TextField
                            id="description"
                            name="description"
                            label="Description"
                            variant="outlined"
                            fullWidth
                            size="small"
                            value={formData.description || ''}
                            onChange={handleChange}
                            sx={{ backgroundColor: 'white' }}
                        />
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <FormGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                            <FormControlLabel
                                name='status'
                                control={<Checkbox checked={Boolean(formData.status) || false} />}
                                onChange={handleChangeCheckbox}
                                label="Status"
                                style={{ marginRight: '20px' }}
                            />
                            <FormControlLabel
                                name='isPrimary'
                                control={<Checkbox checked={Boolean(formData.isPrimary) || false} />}
                                onChange={handleChangeCheckbox}
                                label="Is Primary"
                            />
                        </FormGroup>
                    </Grid>

                </Grid>

                <Grid container spacing={2} justifyContent="space-between" px={2}>
                    <Grid
                        item
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        spacing={2}
                    >
                        <Grid item>
                            <Button
                                onClick={clear}
                                variant="contained"
                                color='secondary'
                                startIcon={<RotateLeftIcon />}
                            >
                                Reset
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleSubmit}
                                autoFocus
                                variant="contained"
                                color={isEdit ? 'success' : 'primary'}
                                startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                            >
                                {isEdit ? "Update" : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </motion.div>

        </>
    )
}

export default BrandCard

