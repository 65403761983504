import React, { useState, useEffect } from "react";
import HsnTableCard from "../Card/MaterEntryCards/HsnTableCard";
import CardHeadMenu from "../Card/CardHeadMenu/CardHeadMenu";
import { Form, Container, Row, Col } from "react-bootstrap";
import { Button } from "@mui/material";
import {
  Stack,
  Paper,
  Divider,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  TextField,
} from "@mui/material";
import { motion } from "framer-motion";
import configServ from "../../services/config";
import SaveIcon from '@mui/icons-material/Save';
import UpdateIcon from '@mui/icons-material/Update';
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import AlertDialog from "../AlertDialog/AlertDialog";
import { useSelector } from "react-redux";


const HNS = () => {
  //#region code
  const { company_id } = useSelector((state) => state.GlobalVariables);
  const [formData, setFormData] = useState({
    ActiveFlage: true
  });
  const [hsnList, setHsnList] = useState([]);
  const [filterHsnList, setFilterHsnList] = useState([]);
  const [count, setCount] = useState(0);
  const [isChanged, setIsChanged] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [isCreate, setIsCreate] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');

  const handleOpenDialog = (content) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogContent('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    const { name, checked } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: checked,
    }));
  };

  const fetchHsnList = async () => {
    try {
      const result = await configServ.getGstDetails();
      setHsnList(result);
      setFilterHsnList(result);
      setCount(result.length);
    } 
    catch (err) {
      console.log(err);
    }
  };

  const handleEdit = (data) => {
    setValidationErrors({});
    setFormData(data);
    setIsEdit(true);
    setIsCreate(true);
  };

  useEffect(() => {
    fetchHsnList();
  }, [isChanged]);

  const checkFieldsNotEmpty = () => {
    return (
      formData.countrycode !== "" &&
      formData.hsncode !== "" &&
      formData.HSNdescription !== "" &&
      formData.GSTRate !== "" &&
      formData.IGSTRate !== "" &&
      formData.CGSTRate !== "" &&
      formData.SGSTRate !== "" &&
      formData.EffectiveDate !== ""
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const error = handleFormValidation();
    if (error === false) {
      return false;
    }
    setValidationErrors({});

    const dataToSend = {
      ...formData,
      company_id: company_id,
    };
    try {
      if (!isEdit) {
        const result = await configServ.addHsn(formData);
        console.log(result);
        setIsChanged(!isChanged);
        clear();
        // console.log(dataToSend)
        handleOpenDialog(result.message);
      } else {
        const result = await configServ.editHsn(formData);
        console.log(result);
        setIsChanged(!isChanged);
        clear();
        // console.log(dataToSend)
        handleOpenDialog(result.message);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clear = () => {
    setFormData({
      ActiveFlage: true,
    });
    setValidationErrors({})
    setIsEdit(false);
  };

  const handleFormValidation = () => {
    const errors = {};

    if (!formData.countrycode) {
      errors.countrycode = "required";
    }
    if (!formData.hsncode) {
      errors.hsncode = "required";
    }
    if (!formData.GSTRate) {
      errors.GSTRate = "required";
    }
    if (!formData.IGSTRate) {
      errors.IGSTRate = "required";
    }
    if (!formData.CGSTRate) {
      errors.CGSTRate = "required";
    }
    if (!formData.SGSTRate) {
      errors.SGSTRate = "required";
    }
    if (!formData.EffectiveDate) {
      errors.EffectiveDate = "required";
    }
    if (!formData.HSNdescription) {
      errors.HSNdescription = "required";
    }

    setValidationErrors(errors);
    return Object.values(errors).every((error) => error === "");
  };

  const handlePopup = () => {
    try {
      setValidationErrors({})
      setIsCreate(!isCreate);
    } catch (error) {
      console.log(error);
    }
  };

  const handleClose = (params) => {
    clear();
    setIsCreate(false);
  };
  //#endregion

  return (
    <>
      <AlertDialog
        open={dialogOpen}
        setOpen={handleCloseDialog}
        title="Alert"
        message={dialogContent}
        buttonTitle="Ok"
      />
      <Stack direction="column" justifyContent="center" alignItems="center">
        <Paper
          elevation={0}
          sx={{
            width: "100%",
            margin: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            px: 3,
            // pb: 5
          }}
        >
          <CardHeadMenu
            prefix={"Na"}
            suffix={"Na"}
            title={"HSN"}
            handlePopup={handlePopup}
            isEdit={isEdit}
            create={isCreate}
          />

          {isCreate && (
            <Container>
              <motion.div
                style={{
                  width: "100%",
                  backgroundColor: "#e3f3fc",
                  padding: 20,
                  marginTop: 20,
                  borderRadius: "10px",
                  border: "none",
                }}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.1, delay: 0.1 }}
              >
                <Grid
                  container
                  alignItems="flex-end"
                  justifyContent="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12} md={12} justifyContent={"flex-start"}>
                    <Typography sx={{ fontSize: "16px", fontWeight: "bolder" }}>
                      HSN Detail
                    </Typography>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="countrycode"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="Country Code*"
                      name="countrycode"
                      onChange={handleChange}
                      error={validationErrors.countrycode}
                      value={formData.countrycode || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="hsncode"
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="HSN Code*"
                      name="hsncode"
                      onChange={handleChange}
                      error={validationErrors.hsncode}
                      value={formData.hsncode || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="saccode"
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="SAC Code"
                      name="SACCode"
                      onChange={handleChange}
                      value={formData.SACCode || ""}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <TextField
                      id="GSTRate"
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="GST Rate*"
                      name="GSTRate"
                      onChange={handleChange}
                      error={validationErrors.GSTRate}
                      value={formData.GSTRate || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="IGSTRate"
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="IGST Rate*"
                      name="IGSTRate"
                      onChange={handleChange}
                      error={validationErrors.IGSTRate}
                      value={formData.IGSTRate || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="CGSTRate"
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="CGST Rate*"
                      name="CGSTRate"
                      onChange={handleChange}
                      error={validationErrors.CGSTRate}
                      value={formData.CGSTRate || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="SGSTRate"
                      type="number"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="SGST Rate*"
                      name="SGSTRate"
                      onChange={handleChange}
                      error={validationErrors.SGSTRate}
                      value={formData.SGSTRate || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="EffectiveDate"
                      type="date"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="Effective Date*"
                      name="EffectiveDate"
                      onChange={handleChange}
                      error={validationErrors.EffectiveDate}
                      value={formData.EffectiveDate || ""}
                      variant="outlined"
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="CESS"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="CESS"
                      name="CESS"
                      onChange={handleChange}
                      value={formData.CESS || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={3}>
                    <TextField
                      id="RateRevision"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="Rate Revision"
                      name="RateRevision"
                      onChange={handleChange}
                      value={formData.RateRevision || ""}
                      variant="outlined"
                    />
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <TextField
                      id="HSNdescription"
                      fullWidth
                      size="small"
                      sx={{ backgroundColor: "white" }}
                      label="HSN Description*"
                      name="HSNdescription"
                      onChange={handleChange}
                      error={validationErrors.HSNdescription}
                      value={formData.HSNdescription || ""}
                      variant="outlined"
                      multiline
                      rows={3}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  justifyContent="flex-end"
                  spacing={2}
                  sx={{ p: 2 }}
                >
                </Grid>

                <Grid container spacing={2} justifyContent="space-between" px={2}>
                  <Grid
                    item
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                  >
                    <Form.Label style={{ cursor: 'pointer', marginTop: 11, marginRight: 10 }}> <Form.Check name='ActiveFlage' checked={Boolean(formData.ActiveFlage) || false} onChange={handleChangeCheckbox} inline aria-label="option 1" size={100} />Status</Form.Label>
                    {/* <Form.Label style={{ cursor: 'pointer', marginTop: 11, }}> <Form.Check name='isPrimary' checked={Boolean(formData.isPrimary) || false} onChange={handleChangeCheckbox} inline aria-label="option 2" size={100} />Is Primary</Form.Label> */}
                  </Grid>
                  <Grid
                    item
                    container
                    justifyContent="flex-end"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid item>
                      <Button
                        onClick={clear}
                        variant="contained"
                        color='secondary'
                        startIcon={<RotateLeftIcon />}
                      >
                        Reset
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        onClick={handleSubmit}
                        autoFocus
                        variant="contained"
                        color={isEdit ? 'success' : 'primary'}
                        startIcon={isEdit ? <UpdateIcon /> : <SaveIcon />}
                      >
                        {isEdit ? "Update" : "Save"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </motion.div>
            </Container>
          )}
        </Paper>

        <HsnTableCard
          hsnList={hsnList}
          filterHsnList={filterHsnList}
          setFilterHsnList={setFilterHsnList}
          count={count}
          setCount={setCount}
          handleEdit={handleEdit}
        />
      </Stack>
    </>
  );
};
export default HNS;
